import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './base/header/header.component';
import { FooterComponent } from './base/footer/footer.component';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { LoginComponent, AcuerdoInstructorDialog } from './paginas/auth/login/login.component';
import { InstructoresComponent } from './paginas/instructores/instructores.component';
import { CertificacionesComponent, PopupCertificacionesDialog } from './paginas/certificaciones/certificaciones.component';
import { PipesModule } from './pipes/pipes.module';
import { ChequearCorreoComponent } from './paginas/certificaciones/chequear-correo/chequear-correo.component';
import { ComprarComponent, TyCCertificacionesDialog, TyCRegistroDialog } from './paginas/certificaciones/comprar/comprar.component';
import { Ng9RutModule } from 'ng9-rut';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NuevaClaveComponent } from './paginas/panel/nueva-clave/nueva-clave.component';
import { CertificacionesPanelComponent } from './paginas/panel/certificaciones/certificaciones.component';
import { ContenidoPanelComponent } from './paginas/panel/contenido/contenido.component';
import { RegistroComponent, TyCRegistroPrincipalDialog } from './paginas/auth/registro/registro.component';
import { OlvidoClaveComponent } from './paginas/auth/olvido_clave/olvido_clave.component';
import { DatosPersonalesComponent } from './paginas/panel/datos-personales/datos-personales/datos-personales.component';
import { MenuLeftComponent } from './paginas/panel/datos-personales/menu-left/menu-left.component';
import { MetodosPagoComponent, AgregarTarjetaDialog } from './paginas/panel/datos-personales/metodos-pago/metodos-pago.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { ProgramasPanelComponent } from './paginas/panel/programas/programas.component';
import { LicenciasPanelComponent, LicenciaInstructorDialog } from './paginas/panel/licencias/licencias.component';
import { DataTablesModule } from 'angular-datatables';
import { BuscadorComponent } from './paginas/buscador/buscador.component';
import { PerfilComponent } from './paginas/panel/datos-personales/perfil/perfil.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { PerfilInstructorComponent } from './paginas/buscador/perfil/perfil.component';
import { LicenciasComponent } from './paginas/licencias/licencias.component';
import { BodyattackComponent } from './paginas/programas/bodyattack/bodyattack.component';
import { BodybalanceComponent } from './paginas/programas/bodybalance/bodybalance.component';
import { BodycombatComponent } from './paginas/programas/bodycombat/bodycombat.component';
import { BodyjamComponent } from './paginas/programas/bodyjam/bodyjam.component';
import { BodypumpComponent } from './paginas/programas/bodypump/bodypump.component';
import { BodystepComponent } from './paginas/programas/bodystep/bodystep.component';
import { CxworxComponent } from './paginas/programas/cxworx/cxworx.component';
import { GritComponent } from './paginas/programas/grit/grit.component';
import { PowerjumpComponent } from './paginas/programas/powerjump/powerjump.component';
import { RpmComponent } from './paginas/programas/rpm/rpm.component';
import { ShbamComponent } from './paginas/programas/shbam/shbam.component';
import { SprintComponent } from './paginas/programas/sprint/sprint.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CambioClaveComponent } from './paginas/panel/datos-personales/cambio-clave/cambio-clave.component';
import { HomeComponent } from './paginas/panel/home/home.component';
import { MisComprasComponent } from './paginas/panel/mis-compras/mis-compras.component';
import { GimnasiosComponent } from './paginas/gimnasios/gimnasios.component';
import { MktStudioComponent, VideoMKTStudioDialog } from './paginas/panel/mkt-studio/mkt-studio.component';
import { TestComponent } from './paginas/test/test.component';
import { TrademarkComponent } from './paginas/trademark/trademark.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    InicioComponent,
    LoginComponent,
    InstructoresComponent,
    CertificacionesComponent,
    ChequearCorreoComponent,
    ComprarComponent,
    TyCRegistroDialog,
    TyCCertificacionesDialog,
    LicenciaInstructorDialog,
    AcuerdoInstructorDialog,
    TyCRegistroPrincipalDialog,
    VideoMKTStudioDialog,
    PopupCertificacionesDialog,
    NuevaClaveComponent,
    CertificacionesPanelComponent,
    ContenidoPanelComponent,
    RegistroComponent,
    OlvidoClaveComponent,
    DatosPersonalesComponent,
    MenuLeftComponent,
    MetodosPagoComponent,
    AgregarTarjetaDialog,
    ProgramasPanelComponent,
    LicenciasPanelComponent,
    BuscadorComponent,
    PerfilComponent,
    PerfilInstructorComponent,
    LicenciasComponent,
    BodyattackComponent,
    BodybalanceComponent,
    BodycombatComponent,
    BodyjamComponent,
    BodypumpComponent,
    BodystepComponent,
    CxworxComponent,
    GritComponent,
    PowerjumpComponent,
    RpmComponent,
    ShbamComponent,
    SprintComponent,
    CambioClaveComponent,
    HomeComponent,
    MisComprasComponent,
    GimnasiosComponent,
    MktStudioComponent,
    TestComponent,
    TrademarkComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    PipesModule,
    Ng9RutModule,
    NgxMaskModule.forRoot(maskConfig),
    MatDialogModule,
    CreditCardDirectivesModule,
    DataTablesModule,
    MaterialFileInputModule,
    MatTooltipModule,
    ImageCropperModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
