import { Pipe, PipeTransform } from '@angular/core';
import { Entrenadores } from '../models/certificaciones.model';

@Pipe({
  name: 'entrenadores'
})
export class EntrenadoresPipe implements PipeTransform {

  transform(value: any[]): any {
    let retornar = '';
    let i = 1;
    value.forEach((element: Entrenadores) => {
        retornar += element.name;
        if(i !== value.length){
            retornar += ', ';
        }else{
            retornar += '.';
        }
        i++;
    });
    return retornar;
  }

}
