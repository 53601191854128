<style>
    @media only screen and (min-width: 768px) {
        .margenes {
            margin: 15px !important;
        }
    }
</style>
<div mat-dialog-content class="margenes">

    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
            <source src="/assets/videos/MKTSTUDIO.mp4" type="video/mp4">
        </video>
    </div>
</div>
<div mat-dialog-actions align="center">
    <button mat-button (click)="onNoClick()">Cerrar</button>
</div>