<section class="main wrd-block p-0 main-gallery">
    <div class="container text-dark pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <h1 class="ml-3">Buscador de Instructores Licenciados</h1>
            </div>
            <div class="col-12">
                <div class="row ml-3 mr-3">
                    <div class="col-sm-4">
                        <mat-form-field class="w-100" appearance="outline" color="accent">
                            <mat-label>
                                Nombre del Instructor
                            </mat-label>
                            <input matInput (keyup)="filtrar($event.target.value, 'nombre')" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="w-100" appearance="outline" color="accent">
                            <mat-label>
                                Región
                                <mat-spinner class="progress-spinner-inline" *ngIf="loadingR" class="float-right" diameter="20"></mat-spinner>
                            </mat-label>
                            <mat-select [disabled]="loadingR" (selectionChange)="obtenerComunas($event.value); filtrar($event.value, 'region')">
                                <mat-option value="TODOS">TODAS</mat-option>
                                <mat-option *ngFor="let r of region" [value]="r.id">{{ r.nombre }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="w-100" appearance="outline" color="accent">
                            <mat-label>
                                Comuna
                                <mat-spinner class="progress-spinner-inline" *ngIf="loadingCS" class="float-right" diameter="20"></mat-spinner>
                            </mat-label>
                            <mat-select [disabled]="loadingC" (selectionChange)="filtrar($event.value, 'comuna')">
                                <mat-option value="TODOS">TODAS</mat-option>
                                <mat-option *ngFor="let r of comuna" [value]="r.id">{{ r.nombre }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">

            <!-- <div class="col-12 text-center">
                <h1 class="ml-3">En Construcción</h1>
            </div> -->
            <div *ngIf="!instructores" class="col-12">
                <mat-spinner color="accent" class="mx-auto mt-4"></mat-spinner>
            </div>
            <div *ngIf="instructores" class="col-12">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let i of instructoresFiltros">
                        <div class="row">
                            <div class="col-4 col-md-2 my-auto">
                                <div class="row">
                                    <div class="col-12 text-center" *ngIf="i.imagen">
                                        <img [src]="s3Url+i.imagen" class="img-fluid img-perfil">
                                    </div>
                                    <div class="col-12 text-center" *ngIf="!i.imagen">
                                        <img [src]="s3Url+'imgPerfil-1622745740.png'" class="img-fluid img-perfil">
                                    </div>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="row h-100">
                                    <div class="my-auto">
                                        <h4>{{ i.name | titlecase }} {{ i.apellidos | titlecase}}</h4>
                                        <h5>{{ i.comunaNombre }}, {{ i.regionNombre }}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2  my-auto">
                                <div class="row">
                                    <div class="col">
                                        <button class="btn btn-dark btn-block rounded-0" type="submit" [routerLink]="['/buscador/perfil', i.idBase]">VER PERFIL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>