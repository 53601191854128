<div class="logo-repeat-bg">
    <div id="nueva-clave" class="container main">
        <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div class="card my-2 mx-2">
                    <div class="card-body">
                        <h3 class="card-title text-center">CREA TU CONTRASEÑA</h3>
                        <div class="text-center pb-3 w-75 mx-auto">
                            Hemos recibido tu pago exitosamente, ahora necesitamos configurar tu contraseña de acceso
                        </div>
                        <mat-error class="text-center pb-3 w-75 mx-auto" *ngIf="error">
                            {{error}}
                        </mat-error>
                        <form name="cambioForm" [formGroup]="cambioForm" (ngSubmit)="onSubmit()">

                            <mat-form-field class="w-100" appearance="outline" color="accent">
                                <mat-label>Contraseña</mat-label>
                                <input matInput type="password" formControlName="password">
                                <mat-icon matSuffix>password</mat-icon>
                                <mat-error>
                                    La Contraseña es Obligatoria
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="w-100" appearance="outline" color="accent">
                                <mat-label>Confirma Contraseña</mat-label>
                                <input matInput type="password" formControlName="c_password">
                                <mat-icon matSuffix>password</mat-icon>
                                <mat-error>
                                    La Confirmación de Contraseña es Obligatoria
                                </mat-error>
                            </mat-form-field>

                            <button *ngIf="!cargando" class="btn btn-lg btn-primary btn-block " type="submit">CONTINUAR</button>
                            <button *ngIf="cargando" class="btn btn-lg btn-primary btn-block" type="submit" disabled>
                                CARGANDO 
                                <mat-icon>
                                    <mat-spinner class="mt-1 ml-3" color="primary" diameter="25"></mat-spinner>
                                </mat-icon>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>