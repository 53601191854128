<div id="perfil" class="bg-foto">
    <div class="container main-registro pb-5">
        <div class="bg-light shadow-lg rounded-3 overflow-hidden">
            <div class="row">
                <!-- Sidebar-->
                <aside class="col-lg-4 pe-xl-5">
                    <app-panel-menu-left></app-panel-menu-left>
                </aside>
                <!-- Content-->
                <section class="col-lg-8 pt-lg-4 pb-4">
                    <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
                        <h2 class="h3 py-2 text-center text-sm-start">Modificar Perfil</h2>

                        <div class="row" *ngIf="loadingPage">
                            <div class="col-12">
                                <mat-spinner class="mx-auto mt-5"></mat-spinner>
                            </div>
                        </div>

                        <form *ngIf="!loadingPage" name="datosForm" [formGroup]="datosForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngIf="resultado" class="alert {{tipoAlert}}">
                                        {{msjAlert}}
                                    </div>
                                    <div *ngIf="errorImg" class="alert alert-danger">
                                        Debes cargar una imágen de tipo png, jpeg o jpg.
                                    </div>
                                </div>
                                <div class="col-12 text-center mx-auto">
                                    <div class="circle mx-auto">
                                        <img *ngIf="croppedImage" [src]="croppedImage">
                                        <img *ngIf="perfil['imagen'] && !croppedImage" [src]="s3Url+perfil['imagen']">

                                        <div (click)="imagenInput.click()" class="p-image">
                                            <i class="fa fa-camera upload-button"></i>
                                            <input #imagenInput type="file" class="file-upload" (change)="subirImagen($event.target['files'], $event); " formControlName="imagen">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8 mx-auto m-5 text-center" *ngIf="imageChangedEvent">
                                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" (imageCropped)="imageCropped($event);" [aspectRatio]="1 / 1" format="png" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
                                        cropperMaxWidth="2400" cropperMaxHeight="2400" cropperMinHeight="600" cropperMinWidth="600" roundCropper="true" backgroundColor="transparent"></image-cropper>

                                    <button class="btn btn-lg btn-primary mt-3" (click)="imageChangedEvent = null">FINALIZAR RECORTE</button>
                                </div>
                                <!-- <div class="col-lg-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Cambiar Imagen</mat-label>
                                        <ngx-mat-file-input (change)="subirImagen($event.target['files']); previsualizacion($event);" formControlName="imagen"></ngx-mat-file-input>
                                        <mat-icon matSuffix>image</mat-icon>
                                    </mat-form-field>
                                </div> -->
                                <div class="col-lg-12 mt-4">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Descripción (Max. 130 Carácteres)</mat-label>
                                        <textarea rows="3" maxlength="130" matInput formControlName="descripcion"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Correo de Contacto</mat-label>
                                        <input matInput formControlName="correo">
                                        <mat-icon matSuffix>email</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Nick Name</mat-label>
                                        <input matInput formControlName="nickname">
                                        <mat-icon matSuffix>account_circle</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Usuario de Instagram</mat-label>
                                        <input matInput formControlName="instagram">
                                        <i class="fab fa-instagram icono"></i>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Usuario de Facebook</mat-label>
                                        <input matInput formControlName="facebook">
                                        <i class="fab fa-facebook icono"></i>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Página Web</mat-label>
                                        <input matInput formControlName="web">
                                        <mat-icon matSuffix>language</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-8 my-auto">
                                    <mat-checkbox color="accent" formControlName="mostrarPerfil">
                                        Mostrar mis datos de perfil en www.lesmills.cl
                                    </mat-checkbox>
                                </div>
                                <div class="col-lg-4 my-auto">
                                    <button *ngIf="!cargando" class="btn btn-lg btn-primary mt-3 float-right" type="submit">GUARDAR</button>
                                    <button *ngIf="cargando" class="btn btn-lg btn-primary mt-3 float-right" type="submit" disabled>
                                            CARGANDO 
                                            <mat-icon>
                                                <mat-spinner class="mt-1 ml-3" color="primary" diameter="20"></mat-spinner>
                                            </mat-icon>
                                        </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>