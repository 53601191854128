import { Component, OnInit } from '@angular/core';
import { CertificacionesService } from 'src/app/services/certificaciones.service';
import { LicenciasService } from 'src/app/services/licencias.service';
import { ProgramasService } from 'src/app/services/programas.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-mis-compras',
  templateUrl: './mis-compras.component.html',
  styleUrls: ['./mis-compras.component.scss']
})
export class MisComprasComponent implements OnInit {

  loading = true;
  pagos: any[];
  programasTodos: any[];
  certificaciones: any[];
  opcionesProgramasDesktop: DataTables.Settings = {};
  opcionesProgramasMobile: DataTables.Settings = {};
  opcionesLicenciasDesktop: DataTables.Settings = {};
  opcionesLicenciasMobile: DataTables.Settings = {};
  opcionesCertificacionesDesktop: DataTables.Settings = {};
  opcionesCertificacionesMobile: DataTables.Settings = {};
  constructor(
    private licencia: LicenciasService,
    private programa: ProgramasService,
    private cert: CertificacionesService
  ) { 
    this.opcionesProgramasDesktop = {
      lengthMenu: [[5, 10, 20, 10000], [5, 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      order: [[2, "desc"]],
    };
    this.opcionesProgramasMobile = {
      lengthMenu: [[5, 10, 20, 10000], [5, 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      order: [[2, "desc"]],
      dom: "ftp"
    };
    this.opcionesLicenciasDesktop = {
      lengthMenu: [[5, 10, 20, 10000], [5, 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      order: [[2, "desc"]],
    };
    this.opcionesLicenciasMobile = {
      lengthMenu: [[5, 10, 20, 10000], [5, 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      order: [[2, "desc"]],
      dom: "tp"
    };
    this.opcionesCertificacionesDesktop = {
      lengthMenu: [[5, 10, 20, 10000], [5, 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      order: [[2, "desc"]],
    };
    this.opcionesCertificacionesMobile = {
      lengthMenu: [[5, 10, 20, 10000], [5, 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      order: [[2, "desc"]],
      dom: "ftp"
    };

  }

  ngOnInit(): void {

    this.licencia.getLicenciasByUser().subscribe(
      data => {
        console.log(data);
        this.pagos = data.data['pagos'];
        if(!this.pagos){
          this.pagos = [];
        }
        this.programa.getProgramasByUser().subscribe(
          data => {
            this.programasTodos = data.data['programasTodos'];
            console.log(this.programasTodos);

            this.cert.getCertificacionesByUser().subscribe(
              data => {
                this.certificaciones = data.data;
                this.loading = false;
              }
            );
          }
        );
      }
    );
    

  }

}
