import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-nueva-clave',
  templateUrl: './nueva-clave.component.html',
  styleUrls: ['./nueva-clave.component.scss']
})
export class NuevaClaveComponent implements OnInit {

  tokenWs: string;
  cambioForm: FormGroup;
  cargando = false;
  error: string;

  constructor(
    private user: UserService, 
    private route: Router,
    private routerA: ActivatedRoute,
    private fb: FormBuilder
  ) { 
    this.tokenWs = this.routerA.snapshot.paramMap.get('tokenWs');
  }

  ngOnInit(): void {
    this.cambioForm = this.fb.group({
          password: ['', [Validators.required]],
          c_password: ['', [Validators.required]],
    });
  }

  
  
  onSubmit(): void {
    if(this.cambioForm.valid){
      if(this.cambioForm.get('password').value !== this.cambioForm.get('c_password').value){
        this.error = 'Las Contraseñas no coinciden';
      }else{
        this.cargando = true;
        this.user.cambioClave(this.cambioForm.get('password').value).subscribe(
          data => {
            this.cargando = false;
            if(data.data === true){
              this.route.navigate(['/panel/certificaciones/', this.tokenWs]);
            }
          }
        );
      }
    }
  }

}

