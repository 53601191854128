import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';

const httpOptions = {
  headers: new HttpHeaders(
    { 
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  )
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  usuario: any[];

  constructor(
    private http: HttpClient,
    private token: TokenStorageService
  ) {
    if (window.sessionStorage.getItem('user') && !this.usuario) {
      this.getUsuario();
    }
  }

  login(form): Observable<any> {
    return this.http.post(environment.api_url + 'auth/login', {
      email: form.get('email').value,
      password: form.get('password').value
    }, httpOptions);
  }  

  olvido_clave(form): Observable<any> {
    return this.http.post(environment.api_url + 'auth/olvido_clave', {
      email: form.get('email').value,
    }, httpOptions);
  }  

  registro(datos): Observable<any> {
    return this.http.post(environment.api_url + 'auth/registro', datos);
  }  

  checkMail(form): Observable<any> {
    return this.http.post(environment.api_url + 'auth/checkMail', {
      email: form.get('email').value
    }, httpOptions);
  }  

  checkRut(rut): Observable<any> {
    return this.http.post(environment.api_url + 'auth/checkRut', {
      rut: rut
    }, httpOptions);
  }  

  logout(): Observable<any> {
    window.sessionStorage.removeItem('user');
    this.usuario = null;
    return this.http.get(environment.api_url + 'auth/logout', { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }

  get_user(): Observable<any> {
    return this.http.post(environment.api_url + 'me', {}, { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }

  getDatosAcuerdoInstructor(token): Observable<any> {
    return this.http.post(environment.api_url + 'auth/getDatosAcuerdoInstructor', {}, { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      }), 
      responseType: 'json' 
    });
  }

  aceptarAcuerdoInstructor(token): Observable<any> {
    return this.http.post(environment.api_url + 'auth/aceptarAcuerdoInstructor', {}, { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      }), 
      responseType: 'json' 
    });
  }

  cambioClave(nuevaClave): Observable<any> {
    return this.http.post(environment.api_url + 'cambioClave', { nuevaClave }, { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }

  actualizarUsuario(datos): Observable<any> {
    return this.http.post(environment.api_url + 'actualizarUsuario', datos, { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }

  actualizarClaveUsuario(datos): Observable<any> {
    return this.http.post(environment.api_url + 'actualizarClaveUsuario', datos, { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }

  actualizarPerfilUsuario(datos): Observable<any> {
    return this.http.post(environment.api_url + 'actualizarPerfilUsuario', datos, { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }

  getInstructores(): Observable<any> {
    return this.http.get(environment.api_url + 'getInstructores', httpOptions);
  }  

  getInstructoresByID(idInstructor): Observable<any> {
    return this.http.get(environment.api_url + 'getInstructoresByID/'+idInstructor, httpOptions);
  }  

  almacenarUsuario( usuario: any[] ): void{
    this.usuario = usuario;
    window.sessionStorage.setItem('user', JSON.stringify(this.usuario));
  }

  getUsuario(): void{
    this.usuario = JSON.parse(window.sessionStorage.getItem('user'));
  }
}