import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { LoginComponent } from './paginas/auth/login/login.component';
import { InstructoresComponent } from './paginas/instructores/instructores.component';
import { CertificacionesComponent } from './paginas/certificaciones/certificaciones.component';
import { ChequearCorreoComponent } from './paginas/certificaciones/chequear-correo/chequear-correo.component';
import { ComprarComponent } from './paginas/certificaciones/comprar/comprar.component';
import { NuevaClaveComponent } from './paginas/panel/nueva-clave/nueva-clave.component';
import { CertificacionesPanelComponent } from './paginas/panel/certificaciones/certificaciones.component';
import { ContenidoPanelComponent } from './paginas/panel/contenido/contenido.component';
import { RegistroComponent } from './paginas/auth/registro/registro.component';
import { OlvidoClaveComponent } from './paginas/auth/olvido_clave/olvido_clave.component';
import { DatosPersonalesComponent } from './paginas/panel/datos-personales/datos-personales/datos-personales.component';
import { MetodosPagoComponent } from './paginas/panel/datos-personales/metodos-pago/metodos-pago.component';
import { ProgramasPanelComponent } from './paginas/panel/programas/programas.component';
import { LicenciasPanelComponent } from './paginas/panel/licencias/licencias.component';
import { BuscadorComponent } from './paginas/buscador/buscador.component';
import { PerfilComponent } from './paginas/panel/datos-personales/perfil/perfil.component';
import { PerfilInstructorComponent } from './paginas/buscador/perfil/perfil.component';
import { LicenciasComponent } from './paginas/licencias/licencias.component';
import { ProgramasComponent } from './paginas/programas/programas.component';
import { BodyattackComponent } from './paginas/programas/bodyattack/bodyattack.component';
import { BodybalanceComponent } from './paginas/programas/bodybalance/bodybalance.component';
import { BodycombatComponent } from './paginas/programas/bodycombat/bodycombat.component';
import { BodyjamComponent } from './paginas/programas/bodyjam/bodyjam.component';
import { BodypumpComponent } from './paginas/programas/bodypump/bodypump.component';
import { BodystepComponent } from './paginas/programas/bodystep/bodystep.component';
import { CxworxComponent } from './paginas/programas/cxworx/cxworx.component';
import { GritComponent } from './paginas/programas/grit/grit.component';
import { PowerjumpComponent } from './paginas/programas/powerjump/powerjump.component';
import { RpmComponent } from './paginas/programas/rpm/rpm.component';
import { ShbamComponent } from './paginas/programas/shbam/shbam.component';
import { SprintComponent } from './paginas/programas/sprint/sprint.component';
import { CoreComponent } from './paginas/programas/core/core.component';
import { CambioClaveComponent } from './paginas/panel/datos-personales/cambio-clave/cambio-clave.component';
import { HomeComponent } from './paginas/panel/home/home.component';
import { MisComprasComponent } from './paginas/panel/mis-compras/mis-compras.component';
import { GimnasiosComponent } from './paginas/gimnasios/gimnasios.component';
import { MktStudioComponent } from './paginas/panel/mkt-studio/mkt-studio.component';
import { TestComponent } from './paginas/test/test.component';
import { TrademarkComponent } from './paginas/trademark/trademark.component';

const routes: Routes = [
  {
    path: 'inicio',
    component: InicioComponent
  },
  {
    path: 'login/:idCertificacion/:correo',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'registro',
    component: RegistroComponent
  },
  {
    path: 'olvido_clave',
    component: OlvidoClaveComponent
  },
  {
    path: 'instructores',
    component: InstructoresComponent
  },
  {
    path: 'licencias',
    component: LicenciasComponent
  },
  {
    path: 'programas',
    component: ProgramasComponent
  },
  {
    path: 'programas/bodyattack',
    component: BodyattackComponent
  },
  {
    path: 'programas/bodybalance',
    component: BodybalanceComponent
  },
  {
    path: 'programas/bodycombat',
    component: BodycombatComponent
  },
  {
    path: 'programas/bodyjam',
    component: BodyjamComponent
  },
  {
    path: 'programas/bodypump',
    component: BodypumpComponent
  },
  {
    path: 'programas/bodystep',
    component: BodystepComponent
  },
  {
    path: 'programas/cxworx',
    component: CxworxComponent
  },
  {
    path: 'programas/core',
    component: CoreComponent
  },
  {
    path: 'programas/grit',
    component: GritComponent
  },
  {
    path: 'programas/powerjump',
    component: PowerjumpComponent
  },
  {
    path: 'programas/rpm',
    component: RpmComponent
  },
  {
    path: 'programas/shbam',
    component: ShbamComponent
  },
  {
    path: 'programas/sprint',
    component: SprintComponent
  },
  {
    path: 'certificaciones/:estadoPago',
    component: CertificacionesComponent,
  },
  {
    path: 'certificaciones',
    component: CertificacionesComponent,
  },
  {
    path: 'buscador',
    component: BuscadorComponent,
  },
  {
    path: 'buscador/perfil/:idInstructor',
    component: PerfilInstructorComponent,
  },
  {
    path: 'certificaciones/check/:idCertificacion',
    component: ChequearCorreoComponent
  },
  {
    path: 'certificaciones/comprar/:idCertificacion/:correo',
    component: ComprarComponent
  },

  // PANEL ADMIN
  {
    path: 'panel/nuevaClave/:tokenWs',
    component: NuevaClaveComponent
  },
  {
    path: 'panel/certificaciones/:tokenWs',
    component: CertificacionesPanelComponent
  },
  {
    path: 'panel/certificaciones',
    component: CertificacionesPanelComponent
  },
  {
    path: 'panel/programas/:resultadoPago',
    component: ProgramasPanelComponent
  },
  {
    path: 'panel/programas',
    component: ProgramasPanelComponent
  },
  {
    path: 'panel/licencias/:resultadoPago',
    component: LicenciasPanelComponent
  },
  {
    path: 'panel/licencias',
    component: LicenciasPanelComponent
  },
  {
    path: 'panel/contenido',
    component: ContenidoPanelComponent
  },
  {
    path: 'panel/datos-personales',
    component: DatosPersonalesComponent
  },
  {
    path: 'panel/perfil',
    component: PerfilComponent
  },
  {
    path: 'panel/cambio-clave',
    component: CambioClaveComponent
  },
  {
    path: 'panel/metodos-pago',
    component: MetodosPagoComponent
  },
  {
    path: 'panel/home',
    component: HomeComponent
  },
  {
    path: 'panel/mis-compras',
    component: MisComprasComponent
  },
  {
    path: 'gimnasios',
    component: GimnasiosComponent
  },
  {
    path: 'mkt-studio-p',
    component: MktStudioComponent
  },
  {
    path: 'test',
    component: TestComponent
  },
  {
    path: 'trademark',
    component: TrademarkComponent
  },
  
  { 
    path: '**', 
    redirectTo: 'inicio' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
