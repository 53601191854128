<div class="bg-foto">
    <div id="gimnasios" class="container mb-5 pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4 class="my-0 font-weight-normal">
                            GIMNASIOS LICENCIADOS
                        </h4>
                        <hr>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="loading">
                            <div class="col-12">
                                <mat-spinner class="mx-auto mt-5"></mat-spinner>
                            </div>
                        </div>
                        <div *ngIf="gimnasios" id="desktop">
                            <table [dtTrigger]="dtTrigger" datatable [dtOptions]="dtOpcionesDesktop" class="table bg-white table-striped w-100 text-center">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Comuna</th>
                                        <th>Región</th>
                                        <th>RRSS</th>
                                        <th>Programas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let g of gimnasios; let i = index">
                                        <tr>
                                            <td>{{g['nombre']}}</td>
                                            <td>{{g['nombreComuna']}}</td>
                                            <td>{{g['nombreRegion']}}</td>
                                            <td>
                                                <a *ngIf="g['web']" matTooltip="Sitio Web" #tooltip="matTooltip" target="_blank" [href]="g['web']"><i class="fas fa-globe icono"></i></a>
                                                <a *ngIf="g['facebook']" matTooltip="Facebook" #tooltip="matTooltip" target="_blank" [href]="g['facebook']"><i class="fab fa-facebook icono"></i></a>
                                                <a *ngIf="g['instagram']" matTooltip="Instagram" #tooltip="matTooltip" target="_blank" [href]="g['instagram']"><i class="fab fa-instagram icono"></i></a>
                                            </td>
                                            <td class="details-control">
                                                <a [matTooltip]="g['programasLicencia']" matTooltipPosition="above" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()">Ver Programas</a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div id="mobile">
                            <table *ngIf="!loading" datatable [dtOptions]="dtOpcionesMobile" class="table bg-white table-striped w-100 text-left">
                                <thead>
                                    <tr>
                                        <th>Gimnasio</th>
                                        <th>Comuna</th>
                                        <th class="text-center">Programas</th>
                                        <th class="text-center">RRSS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let g of gimnasios; let i = index">
                                        <tr>
                                            <td>{{g['nombre']}}</td>
                                            <td>{{g['nombreComuna']}}</td>
                                            <td class="text-center">
                                                <a [matTooltip]="g['programasLicencia']" matTooltipPosition="above" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()">Ver</a>
                                            </td>
                                            <td class="text-center">
                                                <a *ngIf="g['web']" matTooltip="Sitio Web" #tooltip="matTooltip" target="_blank" [href]="g['web']"><i class="fas fa-globe icono"></i></a>
                                                <a *ngIf="g['facebook']" matTooltip="Facebook" #tooltip="matTooltip" target="_blank" [href]="g['facebook']"><i class="fab fa-facebook icono"></i></a>
                                                <a *ngIf="g['instagram']" matTooltip="Instagram" #tooltip="matTooltip" target="_blank" [href]="g['instagram']"><i class="fab fa-instagram icono"></i></a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>