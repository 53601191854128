<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/r-12.png">
</section>

<section class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="tab-content row">
            <div id="programas" class="container tab-pane active"><br>
                <div class="h5 mt-4 text-center">
                    <a routerLink="" (click)="Checkear('0');" id="check_c_0" class="pl-3 pr-3 programs-a border-right border-dark active">
                    TODOS
                    </a>
                    <a routerLink="" (click)="Checkear('1');" id="check_c_1" class="pl-3 pr-3 programs-a border-right border-dark">
                    CARDIO
                    </a>
                    <a routerLink="" (click)="Checkear('2');" id="check_c_2" class="pl-3 pr-3 programs-a border-right border-dark">
                    HIIT
                    </a>
                    <a routerLink="" (click)="Checkear('3');" id="check_c_3" class="pl-3 pr-3 programs-a border-right border-dark">
                    RITMOS
                    </a>
                    <a routerLink="" (click)="Checkear('4');" id="check_c_4" class="pl-3 pr-3 programs-a border-right border-dark">
                    HOLÍSTICO
                    </a>
                    <a routerLink="" (click)="Checkear('5');" id="check_c_5" class="pl-3 pr-3 programs-a">
                    TONIFICACIÓN
                    </a>
                </div>
                <div class="row row-cols-1 row-cols-md-3 text-body mt-5">
                    <div class="col mb-4 categoria-1">
                        <a href="/programas/bodyattack">
                            <img (mouseover)="changeBackground(7, 'in')" (mouseout)="changeBackground(7, 'out')" src="/assets/images/programs/{{ img[7] }}-07.png" alt="" class="card-img-top rounded-0 bg-ba-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-4">
                        <a href="/programas/bodybalance">
                            <img (mouseover)="changeBackground(4, 'in')" (mouseout)="changeBackground(4, 'out')" src="/assets/images/programs/{{ img[4] }}-04.png" alt="" class="card-img-top rounded-0 bg-bb-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-1">
                        <a href="/programas/bodycombat">
                            <img (mouseover)="changeBackground(6, 'in')" (mouseout)="changeBackground(6, 'out')" src="/assets/images/programs/{{ img[6] }}-06.png" alt="" class="card-img-top rounded-0 bg-bc-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-3">
                        <a href="/programas/bodyjam">
                            <img (mouseover)="changeBackground(1, 'in')" (mouseout)="changeBackground(1, 'out')" src="/assets/images/programs/{{ img[1] }}-01.png" alt="" class="card-img-top rounded-0 bg-bj-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-5">
                        <a href="/programas/bodypump">
                            <img (mouseover)="changeBackground(8, 'in')" (mouseout)="changeBackground(8, 'out')" src="/assets/images/programs/{{ img[8] }}-08.png" alt="" class="card-img-top rounded-0 bg-bp-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-1">
                        <a href="/programas/bodystep">
                            <img (mouseover)="changeBackground(3, 'in')" (mouseout)="changeBackground(3, 'out')" src="/assets/images/programs/{{ img[3] }}-03.png" alt="" class="card-img-top rounded-0 bg-bs-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-5">
                        <a href="/programas/core">
                            <img (mouseover)="changeBackground(2, 'in')" (mouseout)="changeBackground(2, 'out')" src="/assets/images/programs/{{ img[2] }}-02.png" alt="" class="card-img-top rounded-0 bg-cx-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-2">
                        <a href="/programas/grit">
                            <img (mouseover)="changeBackground(5, 'in')" (mouseout)="changeBackground(5, 'out')" src="/assets/images/programs/{{ img[5] }}-05.png" alt="" class="card-img-top rounded-0 bg-gr-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-1">
                        <a href="/programas/powerjump">
                            <img (mouseover)="changeBackground(12, 'in')" (mouseout)="changeBackground(12, 'out')" src="/assets/images/programs/{{ img[12] }}-12.png" alt="" class="card-img-top rounded-0 bg-pj-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-1">
                        <a href="/programas/rpm">
                            <img (mouseover)="changeBackground(10, 'in')" (mouseout)="changeBackground(10, 'out')" src="/assets/images/programs/{{ img[10] }}-10.png" alt="" class="card-img-top rounded-0 bg-rp-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-3">
                        <a href="/programas/shbam">
                            <img (mouseover)="changeBackground(11, 'in')" (mouseout)="changeBackground(11, 'out')" src="/assets/images/programs/{{ img[11] }}-11.png" alt="" class="card-img-top rounded-0 bg-sb-change">
                        </a>
                    </div>
                    <div class="col mb-4 categoria-2">
                        <a href="/programas/sprint">
                            <img (mouseover)="changeBackground(9, 'in')" (mouseout)="changeBackground(9, 'out')" src="/assets/images/programs/{{ img[9] }}-09.png" alt="" class="card-img-top rounded-0 bg-sp-change">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>