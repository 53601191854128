<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-gr.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-gr">¿QUÉ ES GRIT?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">Hay una razón por la cual el entrenamiento de intervalos de alta intensidad (HIIT, por sus siglas en inglés) es una de las tendencias más de moda en el mundo del fitness – es la manera más rápida de ponerse en forma. Cortos e intensos
                    periodos de esfuerzo te permiten llegar a tus niveles máximos de entrenamiento, siendo éste el secreto del rendimiento atlético.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-gr">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">RESULTADOS</h3>
                        <h1 class="p-0 m-0 title-color-gr"><strong>RAPIDOS</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">ASESINA</h3>
                        <h1 class="p-0 m-0 title-color-gr"><strong>CALORÍAS</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">SOLO</h3>
                        <h1 class="p-0 m-0 title-color-gr"><strong>30 MINUTOS</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-3 text-center">
                <h2 class="mt-5">LES MILLS GRIT PRESENTA 3 ENTRENAMIENTOS ÚNICOS:</h2>
            </div>
            <div class="col-12 mb-1 programs-bt-gr">
                <div class="row p-4 text-center">
                    <div class="col-sm-4 mt-4">
                        <img src="/assets/images/programs/GRIT-Strength.png">
                        <p class="mt-4">Usando una barra, discos y tu propio peso corporal, LES MILLS GRIT Strength es un intenso entrenamiento de alto impacto en equipo de 30 minutos que hará trabajar los músculos principales de tu cuerpo y llevará tu fuerza a desarrollar
                            nuevos niveles .</p>
                    </div>
                    <div class="col-sm-4 mt-4">
                        <img src="/assets/images/programs/GRIT-Athletic.png">
                        <p class="mt-4">LES MILLS GRIT Athletic es un entrenamiento diseñado para que rindas como un atleta. Este entrenamiento utiliza a menudo un step, ejercicios de peso corporal y un entrenamiento de acondicionamiento deportivo multidimensional para
                            aumentar tu rendimiento atlético general: fuerza, agilidad, velocidad y potencia.</p>
                    </div>
                    <div class="col-sm-4 mt-4">
                        <img src="/assets/images/programs/GRIT-Cardio.png">
                        <p class="mt-4">LES MILLS GRIT Cardio es un entrenamiento de alto impacto. Diseñado para quemar grasa y mejorar rápidamente tu capacidad atlética, este entrenamiento de 30 minutos combina diferentes ejercicios de alto impacto utilizando tu propio
                            peso corporal.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON GRIT?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify">Presta atención a las opciones que ofrece el instructor para que puedas ajustar el entrenamiento a tu nivel de Fitness.</p>
            </div>

        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-gr.mp4" type="video/mp4">
     </video>
    </div>

</section>