import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidadoresService } from '../../../../services/validadores.service';
import { RutValidator } from 'ng9-rut';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { UserService } from '../../../../services/user.service';
import { RegionesService } from '../../../../services/regiones.service';
import { environment } from '../../../../../environments/environment';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {


  datosForm: FormGroup;
  usuario: any[];
  perfil: any[];
  imagen: File;
  cargando = false;
  resultado = false;
  errorImg = false;
  recortarImg = false;
  tipoAlert: string;
  msjAlert: string;
  loadingPage = true;
  s3Url = environment.s3_public_url + environment.perfil;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private fb: FormBuilder,
    private user: UserService, 
  ) { 
    this.usuario = this.user.usuario;
    this.user.get_user().subscribe(
      data => {
        this.perfil = data;

        // console.log(this.perfil);
  
        let correoPerfil = (this.perfil['correo_perfil']) ? this.perfil['correo_perfil'] : this.usuario['email'];
        let descripcionPerfil = (this.perfil['descripcion']) ? this.perfil['descripcion'] : "";
        let nickname = (this.perfil['nickname']) ? this.perfil['nickname'] : "";
        let web = (this.perfil['web']) ? this.perfil['web'] : "";
        let fbPerfil = (this.perfil['instagram']) ? "@"+this.perfil['instagram'] : "@";
        let igPerfil = (this.perfil['facebook']) ? "@"+this.perfil['facebook'] : "@";
        let mostrarPerfil = (this.perfil['mostrar_perfil'] == 1) ? true : false;
        this.datosForm = this.fb.group({
          descripcion   : [descripcionPerfil],
          imagen   : [''],
          nickname   : [nickname],
          correo   : [correoPerfil],
          instagram   : [fbPerfil],
          facebook   : [igPerfil],
          mostrarPerfil   : [mostrarPerfil],
          web   : [web],
        });

        this.loadingPage = false;
      }
    );
  }


  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
    this.imageChangedEvent = null;
    this.errorImg = true;

  }
  
  ngOnInit(): void {
  }

  subirImagen(archivo: FileList, event: any){
    this.errorImg = false;
    this.imageChangedEvent = event;
    this.imagen = archivo.item(0);
  }

  // previsualizacion(event: Event): void {
  //   console.log(event);
  //   if (event.target['files'] && event.target['files'][0]) {
  //       const file = event.target['files'][0];
  //       const reader = new FileReader();
  //       reader.onload = e => this.imgPrevisualizar = reader.result;
  //       reader.readAsDataURL(file);
  //   }
  // }

  onSubmit(): void{
    this.cargando = true;

    const datos = new FormData();
    datos.append('descripcion', this.datosForm.value.descripcion);
    datos.append('correo', this.datosForm.value.correo);
    datos.append('web', this.datosForm.value.web);
    datos.append('nickname', this.datosForm.value.nickname);
    datos.append('mostrarPerfil', this.datosForm.value.mostrarPerfil);
    datos.append('instagram', this.datosForm.value.instagram.replace('@', ''));
    datos.append('facebook', this.datosForm.value.facebook.replace('@', ''));
    if(this.croppedImage){
      datos.append('imagen', this.croppedImage);
    }

    this.user.actualizarPerfilUsuario(datos).subscribe(
      data => {
        // console.log(data);
        if(data.data){
          this.tipoAlert = "alert-success"
          this.msjAlert = data.message;
          
        }else{
          this.tipoAlert = "alert-danger"
          this.msjAlert = data.message;
        }
        this.resultado = true;
        this.cargando = false;
      },
      error => {
        // console.log(error);
        this.tipoAlert = "alert-danger"
        this.msjAlert = 'Revisa que el archivo sea una imagen de tipo jpeg,jpg,png,gif,svg y su peso no sea mayor a 4MB';
        this.resultado = true;
        this.cargando = false;
      }
    );

  }

  

}

