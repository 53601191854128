import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidadoresService } from '../../../../services/validadores.service';
import { RutValidator } from 'ng9-rut';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { UserService } from '../../../../services/user.service';
import { RegionesService } from '../../../../services/regiones.service';

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.scss']
})
export class DatosPersonalesComponent implements OnInit {


  datosForm: FormGroup;
  cambioClaveForm: FormGroup;

  region: any[];
  comuna: any[];
  loadingR = true;
  loadingP = true;
  loadingPS = false;
  loadingC = true;
  loadingCS = false;
  usuario: any[];
  cargando = false;
  resultado = false;
  tipoAlert: string;
  msjAlert: string;
  cargandoCC = false;
  resultadoCC = false;
  tipoAlertCC: string;
  msjAlertCC: string;

  constructor(
    private fb: FormBuilder,
    private rutValidator: RutValidator,
    private validadores: ValidadoresService,
    private tokenStorage: TokenStorageService,
    private user: UserService, 
    private regiones: RegionesService,
  ) { 
    this.usuario = this.user.usuario;
    this.regiones.getRegiones().subscribe(
      data => {
        this.region = data.data;
        this.loadingR = false;
        
        if (this.tokenStorage.checkToken()) {
          this.obtenerComunas(this.usuario['region']);
          this.datosForm.controls['comuna'].setValue(this.usuario['comuna']);
        }
      }
    );
  }

  ngOnInit(): void {
    this.datosForm = this.fb.group({
        email   : [{value: this.usuario['email'], disabled: true}],
        nombres   : [this.usuario['name'], [Validators.required]],
        apellidos   : [this.usuario['apellidos'], [Validators.required]],
        region   : [this.usuario['region'], [Validators.required]],
        comuna   : [this.usuario['comuna'], [Validators.required]],
        direccion   : [this.usuario['direccion'], [Validators.required]],
        rut   : [{value: this.usuario['rut'], disabled: true}],
        telefono   : [this.usuario['telefono'], [Validators.required]],
    });

    this.cambioClaveForm = this.fb.group({
        password_old: ['', [Validators.required]],
        password: ['', [Validators.required]],
        c_password: ['', [Validators.required]],
    });
  }

  obtenerComunas(value): void{
    this.datosForm.controls['comuna'].setValue('', {onlySelf: true});
    this.loadingCS = true;
    this.loadingC = true;
    this.regiones.getComunasByRegion(value).subscribe(
      data => {
        this.comuna = data.data;
        this.loadingC = false;
        this.loadingCS = false;
      }
    );
  }

  onSubmit(): void{
    if(this.datosForm.valid ){
      this.cargando = true;
      this.resultado = false;
      const datos = {
        nombres: this.datosForm.get('nombres').value,
        apellidos: this.datosForm.get('apellidos').value,
        comuna: this.datosForm.get('comuna').value,
        region: this.datosForm.get('region').value,
        direccion: this.datosForm.get('direccion').value,
        telefono: this.datosForm.get('telefono').value,
      }


      this.user.actualizarUsuario(datos).subscribe(
        data => {
          if(data.data){
            this.tipoAlert = "alert-success"
            this.msjAlert = data.message;
            
            //ACTUALIZARLO LOCALMENTE
            this.user.get_user().subscribe( data => {
              this.user.almacenarUsuario(data);
            });
          }else{
            this.tipoAlert = "alert-danger"
            this.msjAlert = data.message;
          }
          this.resultado = true;
          this.cargando = false;
        }
      );
    }
  }

  onSubmitCC(): void{
    if(this.cambioClaveForm.valid ){
      this.cargandoCC = true;
      this.resultadoCC = false;
      const datos = {
        password_old: this.cambioClaveForm.get('password_old').value,
        password: this.cambioClaveForm.get('password').value,
        c_password: this.cambioClaveForm.get('c_password').value,
      }

      this.user.actualizarClaveUsuario(datos).subscribe(
        data => {
          if(data.data){
            this.tipoAlertCC = "alert-success"
            this.msjAlertCC = data.message;
            
            //ACTUALIZARLO LOCALMENTE
            this.user.get_user().subscribe( data => {
              this.user.almacenarUsuario(data);
            });
          }else{
            this.tipoAlertCC = "alert-danger"
            this.msjAlertCC = data.message;
          }
          this.resultadoCC = true;
          this.cargandoCC = false;
        }
      );
    }
  }

  

}

