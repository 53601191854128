import { Component, OnInit } from '@angular/core';
import { Programas } from 'src/app/models/programas.model';
import { ProgramasService } from '../../../services/programas.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { GimnasioService } from 'src/app/services/gimnasios.service';
import { LicenciasService } from 'src/app/services/licencias.service';

@Component({
  selector: 'app-programas',
  templateUrl: './programas.component.html',
  styleUrls: ['./programas.component.scss']
})
export class ProgramasPanelComponent implements OnInit {

  loading = true;
  programasActual: any[];
  programasFuturo: any[];
  programasAnteriores: any[];
  programasTodos: any[];
  resumenPago = new Array();
  totalResumenPago:number = 0;
  dtOpciones: DataTables.Settings = {};
  valorKit: number;
  valorKitFuturo: number;
  valorKitAnterior: number;
  valorKitGrit: number;
  valorKitGritFuturo: number;
  valorKitGritAnterior: number;
  diasEntregaKits: number;
  fechaActual = new Date();
  fechaCambioQFuturo: Date;
  fechaPreventaLicencia: Date;
  mostrarActual = true;
  mostrarFuturo = false;
  mostrarAnteriores = false;
  inputChecked = new Array();
  cargando = false;
  resultadoPago: string;
  licenciaActiva = false;
  detalleLicencia: any[];
  licenciaGimnasio = new Array();
  programasPlataforma: any[];

  constructor(
    private programa: ProgramasService,
    private routerA: ActivatedRoute,
    private gim: GimnasioService,
    private lic: LicenciasService,
  ) {
    this.resultadoPago = this.routerA.snapshot.paramMap.get('resultadoPago');

    this.dtOpciones = {
      lengthMenu: [[ 10, 20, 10000], [ 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      // order: [[3, "desc"]],
      // scrollX: true
      ordering: false
    };

    if(window.innerWidth > 768){
      this.dtOpciones = {
        lengthMenu: [[ 10, 20, 10000], [ 10, 20, "Todos"]],
        language: environment.lenguajeDatatables,
        // order: [[3, "desc"]],
        // scrollX: true
        ordering: false
      };
    }else{
      
    this.dtOpciones = {
      lengthMenu: [[ 10, 20, 10000], [ 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      dom: "t",
      ordering: false
    };
    }

    this.lic.checkLicencia().subscribe(
      data => {
        this.detalleLicencia = data.data;
        console.log(this.detalleLicencia);
        
        this.programa.getProgramasByUser().subscribe(
          data => {
            console.log(data.data);
            this.programasActual = data.data['programasActual'];
            this.programasFuturo = data.data['programasFuturo'];
            this.programasAnteriores = data.data['programasAnteriores'];
            this.programasTodos = data.data['programasTodos'];
    
            this.valorKit = data.data['valorKit'];
            this.valorKitAnterior = data.data['valorKitAnterior'];
            this.valorKitFuturo = data.data['valorKitFuturo'];
            this.valorKitGrit = data.data['valorKitGrit'];
            this.valorKitGritAnterior = data.data['valorKitGritAnterior'];
            this.valorKitGritFuturo = data.data['valorKitGritFuturo'];
    
            this.fechaCambioQFuturo = new Date(data.data['fechaCambioQFuturo']);
            this.fechaPreventaLicencia = new Date(data.data['fechaPreventaLicencia']);
            this.diasEntregaKits = Number(data.data['diasEntregaKits']);
    
            this.licenciaActiva = data.data['licencia']['activa'];
    
            this.gim.getUsuarioGimnasioLicenciado().subscribe(
              pLicGim => {
                this.programa.getProgramas().subscribe(
                  pPlataforma => {
                    this.programasPlataforma = pPlataforma.data;
                    this.programasPlataforma.forEach(pp => {
                      let buscarPrograma = new Array();
                      if(pLicGim.data['programas']){
                        buscarPrograma = pLicGim.data['programas'].find( x => x['nombre'] == pp['nombre']);
                        if(buscarPrograma){
                          this.licenciaGimnasio[pp['nombre']] = true;
                        }else{
                          this.licenciaGimnasio[pp['nombre']] = false;
                        }
                      }else{
                        this.licenciaGimnasio[pp['nombre']] = false;
                      }
                      this.loading = false;
                    });
                    // console.log(this.licenciaGimnasio);
                  }
                );
              }
            );
    
          }
        );
      }
    );

  }


  ngOnInit(): void {
  }

  cambiarPeriodo(valor): void{
    this.mostrarActual = false;
    this.mostrarFuturo = false;
    this.mostrarAnteriores = false;
    switch (valor) {
      case 'actual':
        this.mostrarActual = true;
        break;
      case 'anterior':
        this.mostrarAnteriores = true;
        break;
      case 'proximo':
        this.mostrarFuturo = true;
        break;
    }
  }

  seleccionarMix(checkbox, mix, periodo, ano, nombre, valor, tipoKit): void{
    if(checkbox.target.checked){
      this.inputChecked[nombre+periodo+ano] = true;
      this.resumenPago.push({
        mix: mix,
        periodo: periodo,
        ano: ano,
        nombre: nombre,
        valor: valor,
        tipoKit: tipoKit
      });
    }else{
      this.inputChecked[nombre+periodo+ano] = false;
      for (let i = 0; i < this.resumenPago.length; i++) {
        if(this.resumenPago[i].mix === mix){
          this.resumenPago.splice(i, 1);
        }
      }
    }

    this.totalResumenPago = 0;
    this.resumenPago.forEach(r => {
      this.totalResumenPago+=Number(r.valor);
    });
  }

  removerMix(nombre, periodo, ano, mix): void{
    this.inputChecked[nombre+periodo+ano] = false;
    for (let i = 0; i < this.resumenPago.length; i++) {
      if(this.resumenPago[i].mix === mix){
        this.resumenPago.splice(i, 1);
      }
    }
  }


  comprarProgramas (): void{
    this.cargando = true;
    this.programa.comprarProgramas(this.resumenPago).subscribe(
      data => {
        
         const myform = document.createElement('form');
        myform.method = 'POST';
        myform.action = data.message.url;
        myform.style.display = 'none';

        const my_tb = document.createElement('input');
        my_tb.type = 'TEXT';
        my_tb.name = 'token_ws';
        my_tb.value = data.message.token;

        myform.appendChild(my_tb);
        document.body.appendChild(myform);
        myform.submit(); 
      }
    );
  }

}
