import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Fecha } from '../models/certificaciones.model';

@Pipe({
  name: 'fechasCert'
})
export class FechasCertPipe extends DatePipe implements PipeTransform {

  transform(value: any[]): any {
    let fecha = '';
    let mes_anterior = '';
    let i = 1;
    value.forEach((element: Fecha) => {
      var mes = super.transform(element.fecha_inicio, 'MMM', '', 'es');
      var dia = super.transform(element.fecha_inicio, 'd', '', 'es');
      if(mes_anterior !== mes && mes_anterior){
        if(mes_anterior.length > 0){
          fecha = fecha.replace(" -", "")
          fecha += mes_anterior.toUpperCase().replace('.', '') + ' - '+ dia;
        }else{
          fecha = dia + ' ' + mes.toUpperCase();
        }
      }else{
        fecha += dia;
      }
      if(i !== value.length){
        fecha += ' - ';
      }else{
        fecha += ' ' + mes.toUpperCase().replace('.', '');
      }
      mes_anterior = mes;
      i++;
    });
    return fecha;
  }

}
