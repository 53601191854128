<section class="main-home">
    <header class="masthead">
        <div class="container h-100 overlay">
            <div class="row h-100 align-items-center">
                <div class="col-12 text-center">
                    <h1 class="display-3">LICENCIA DE INSTRUCTOR</h1>
                    <p class="lead mb-0">Expande tu campo laboral y maximiza tus utilidades con nuestro nuevo formato de licenciamiento.</p>
                </div>
            </div>
        </div>
    </header>
</section>
<section class="wrd-block pb-5">
    <div class="container">
        <p class="text-center info">
            En Les Mills Chile disponemos de un nuevo formato de licenciamiento exclusivo para instructores.
        </p>
        <p class="text-center info">
            Con el objetivo de expandir tu campo laboral y permitir la mayor flexibilidad al momento de dictar tus clases, es que desarrollamos la Licencia de Instructor Les Mills.
        </p>

        <h1 class="titulo text-center mt-5">CARACTERÍSTICAS PRINCIPALES</h1>

        <div class="row mt-4">
            <div class="col-lg-6">
                <img class="img-fluid" src="/assets/images/licencias/left.jpeg" alt="">
            </div>
            <div class="col-lg-6 pt-sm-4 my-auto">
                <ul class="h-100 align-items-center">
                    <li class="info espacio">Licencia trimestral, semestral y anual</li>
                    <li class="info espacio">Renovación de licencia no obligatoria</li>
                    <li class="info espacio">Libertad para dictar todos los programas en los que te encuentres certificado.</li>
                    <li class="info espacio">Permite dictar clases presenciales en gimnasios, centros deportivos, centros de eventos, plazas, parques, multicanchas y cualquier otro lugar que permita el correcto desempeño de la actividad.</li>
                    <li class="info espacio">Permite dictar clases en plataformas digitales privadas</li>
                </ul>
            </div>
        </div>
    </div>

</section>
<section class="wrd-block bg-gris mt-5">
    <div class="container">
        <h1 class="titulo text-center">BENEFICIOS</h1>
        <div class="row">
            <!-- <div class="col-lg-4 col-6 text-center mt-4">
                <img src="/assets/images/licencias/brandcentral-white.png" alt="" class="img-fluid icono">
                <h3 class="desc-icono mt-2">Portal de contenido digitales Les Mills</h3>
            </div> -->
            <div class="col-lg-6 col-6 text-center mt-4">
                <img src="/assets/images/licencias/webinar-white.png" alt="" class="img-fluid icono">
                <h3 class="desc-icono mt-2">Webinars para promoción y retención de clientes</h3>
            </div>
            <div class="col-lg-6 col-6 text-center mt-4">
                <img src="/assets/images/licencias/gwx-white.png" alt="" class="img-fluid icono">
                <h3 class="desc-icono mt-2">Acceso a Groundworks trimestrales</h3>
            </div>
            <div class="col-lg-6 col-6 text-center mt-4">
                <img src="/assets/images/licencias/app-white.png" alt="" class="img-fluid icono">
                <h3 class="desc-icono mt-2">Les Mills App y portal de Kits Digitales</h3>
            </div>
            <!-- <div class="col-lg-4 col-6 text-center mt-4">
                <img src="/assets/images/licencias/financiera-white.png" alt="" class="img-fluid icono">
                <h3 class="desc-icono mt-2">Herramientas para administración financiera</h3>
            </div> -->
            <div class="col-lg-6 col-6 text-center mt-4">
                <img src="/assets/images/licencias/perfil-white.png" alt="" class="img-fluid icono">
                <h3 class="desc-icono mt-2">Perfil de instructor en sitio web</h3>
            </div>
        </div>
    </div>
</section>
<section class="main-home d-none">
    <header class="masthead2">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12 text-center">
                    <h1 class="display-3">¿CÓMO OBTENGO MI LICENCIA DE INSTRUCTOR?</h1>
                    <!--<p class="lead mb-0">Expande tu campo laboral y maximiza tus utilidades con nuestro nuevo formato de licenciamiento.</p>-->
                </div>
            </div>
        </div>
    </header>
</section>

<section class="container" id="licencias">

    <div class="row mt-5">
        <div class="col-10 mx-auto text-center pb-3">
            <h3 class="card-title">Elige tu Licencia Instructor Les Mills</h3>
            <h4>Aprovecha todas las herramientas que tenemos disponibles para ti</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-10 mx-auto">
            <header class="pricing-table-header">
                <div class="container mx-auto text-center">

                    <div class="nav price-tabs" role="tablist">
                        <a class="nav-link active" href="#instructores-certificados" id="instructores-certificados-tab" data-toggle="list" href="#instructores-certificados" role="tab" aria-controls="instructores-certificados" aria-selected="false">Licencia Básica</a>
                        <a class="nav-link" href="#instructores-nuevos-tab" id="instructores-nuevos-tab" data-toggle="list" href="#instructores-nuevos" role="tab" aria-controls="instructores-nuevos" aria-selected="true">Licencia Pro</a>
                    </div>
                </div>
            </header>
            <div class="container">
                <div class="tab-content pricing-tab-content" id="pills-tabContent">
                    <div class="tab-pane active show" id="instructores-certificados" role="tabpanel" aria-labelledby="instructores-certificados-tab">
                        <div class="row">
                            <div class="col-md-5 mx-auto">
                                <div class="card pricing-card text-left shadow border-0">
                                    <div class="card-header">
                                        <h3 class="pricing-plan-title">{{ licencias[0]['nombre_mostrar'] }}</h3>
                                        <h5 class="pricing-plan-cost">${{ licencias[0]['precio_trimestral'] | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} + IVA / Trimestral</h5>
                                    </div>
                                    <div class="card-body">
                                        <ul class="pricing-plan-features">
                                            <li>+ Contenidos digitales</li>
                                            <li>+ Webinars y Groundworks</li>
                                            <li>+ Les Mills App</li>
                                            <li>+ Perfil de Instructor</li>
                                        </ul>
                                    </div>
                                    <div class="card-footer">
                                        <A class="btn btn-primary btn-block" href="/login">SELECCIONAR</A>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 mx-auto">
                                <div class="card pricing-card text-left shadow border-0">
                                    <div class="card-header">
                                        <h3 class="pricing-plan-title">{{ licencias[0]['nombre_mostrar'] }}</h3>
                                        <h5 class="pricing-plan-cost">${{ licencias[0]['precio_semestral'] | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} + IVA / Semestral</h5>
                                        <h5 class="text-success pricing-plan-descuento">Hasta 33% Dscto</h5>
                                    </div>
                                    <div class="card-body">
                                        <ul class="pricing-plan-features">
                                            <!-- <li>+ 25% Descto en 1 Certificación</li> -->
                                            <li>+ Contenidos digitales</li>
                                            <li>+ Webinars y Groundworks</li>
                                            <li>+ Les Mills App</li>
                                            <li>+ Perfil de Instructor</li>
                                        </ul>
                                    </div>
                                    <div class="card-footer">
                                        <A class="btn btn-primary btn-block" href="/login">SELECCIONAR</A>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="instructores-nuevos" role="tabpanel" aria-labelledby="instructores-nuevos-tab">
                        <div class="row">
                            <div class="col-md-6 mx-auto">
                                <div class="card pricing-card text-left shadow border-0">
                                    <div class="card-header">
                                        <h3 class="pricing-plan-title">{{ licencias[1]['nombre_mostrar'] }}</h3>
                                        <h5 class="pricing-plan-cost">${{ licencias[1].precio | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} + IVA / Anual</h5>
                                        <h5 class="text-success pricing-plan-descuento">Mejor Valor</h5>
                                    </div>
                                    <div class="card-body">
                                        <ul class="pricing-plan-features">
                                            <li>+ Beneficios Licencia Básica</li>
                                            <li class="text-success mas-grande">+ Certificaciones ilimitadas GRATIS</li>
                                            <li>+ Liberación anticipada de releases</li>
                                            <li>+ Contenidos digitales exclusivos</li>
                                        </ul>
                                    </div>
                                    <div class="card-footer">
                                        <A class="btn btn-primary btn-block" href="/login">SELECCIONAR</A>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="wrd-block bg-negro d-none">
    <div class="container mt-4 mb-4">
        <h1 class="titulo text-center">¿CÓMO OBTENGO MI LICENCIA DE INSTRUCTOR?</h1>
        <p class="info mt-4 text-center">
            Existen varias opciones que dependen de tu situación en Les Mills Chile:
        </p>

        <div class="row mt-5">
            <div class="col borde">
                <h4>Estoy certificado en 1 o más programas Les Mills. En este caso tienes 2 opciones para elegir:</h4>

                <div id="accordion">
                    <div class="card mt-5">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            1. Licencia Básica $59.990 <i class="fas fa-angle-down"></i>
                                </button>
                            </h5>
                        </div>

                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <p class="info">Duración anual. </p>
                                <p class="info">
                                    Pagos Trimestrales:<br> &nbsp;&nbsp;&nbsp;&nbsp;1er Trimestre: $59.990<br> &nbsp;&nbsp;&nbsp;&nbsp;2do Trimestre: $59.990<br> &nbsp;&nbsp;&nbsp;&nbsp;3er Trimestre: $59.990<br> &nbsp;&nbsp;&nbsp;&nbsp;4to Trimestre:
                                    $59.990
                                </p>
                                <p class="info">Acceso a Les Mills MKT Studio (programas certificados)</p>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4 pt-3">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    2. Licencia Pro $399.990 <i class="fas fa-angle-down"></i>
                                </button>
                            </h5>
                        </div>

                        <div id="collapseTwo" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <p class="info">Duración anual.</p>
                                <p class="info">Pago anual.</p>
                                <p class="info">Permite tomar todas las certificaciones en todos los programas, GRATIS durante el año de licencia.</p>
                                <p class="info">Acceso a nuevos Releases 2 semanas antes al lanzamiento general (Early Bird)</p>
                                <p class="info">Acceso a Les Mills MKT Studio (programas certificados)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <h4>No estoy certificado en programas Les Mills.<br> En este caso tienes 2 opciones para elegir:</h4>

                <div id="accordion">
                    <div class="card mt-5">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                    Certificación de programa + Licencia Básica Trimestral $129.990 <i class="fas fa-angle-down"></i>
                                </button>
                            </h5>
                        </div>

                        <div id="collapseOne1" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <p class="info">Certificación en 1 programa Les Mills a elección</p>
                                <p class="info">
                                    Licencia Básica:
                                    <br> &nbsp;&nbsp;&nbsp;&nbsp;Duración anual.<br> &nbsp;&nbsp;&nbsp;&nbsp;Pagos Trimestrales:<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1er Trimestre: <b>Free</b><br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2do
                                    Trimestre: $59.990
                                    <br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3er Trimestre: $59.990<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4to Trimestre: $59.990<br> &nbsp;&nbsp;&nbsp;&nbsp;Acceso a Les Mills MKT Studio (programas
                                    certificados)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo1" aria-expanded="true" aria-controls="collapseTwo1">
                                    2. Licencia Pro $399.990 <i class="fas fa-angle-down"></i>
                                </button>
                            </h5>
                        </div>

                        <div id="collapseTwo1" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <p class="info">Duración anual.</p>
                                <p class="info">Pago anual.</p>
                                <p class="info">Permite tomar todas las certificaciones en todos los programas, GRATIS durante el año de licencia.</p>
                                <p class="info">Acceso a nuevos Releases 2 semanas antes al lanzamiento general (Early Bird)</p>
                                <p class="info">Acceso a Les Mills MKT Studio (programas certificados)</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 text-center  mt-5">
                <h4><a href="/assets/pdf/Lic. Instructor_Com.pdf" target="_blank">Condiciones generales Licencia de Instructor</a> <br></h4>
                <a class="btn btn-lg w-50 btn-primary mt-4" href="/login">REGÍSTRATE</a>
            </div>
        </div>
    </div>
</section>
<section class="wrd-block d-none">
    <div class="container">
        <h1 class="titulo text-center mt-5">VALORES LES MILL CHILE</h1>
        <div *ngIf="licencias" class="row mt-5 ">
            <div class="col-lg-12">
                <div class="row text-center mx-auto">
                    <div class="col-lg-5 mx-auto">
                        <div class="card mb-4 shadow-sm">
                            <div class="card-body">
                                <h3 class="my-0 font-weight-normal pb-4">{{ licencias[0].nombre }}</h3>

                                <h2 class="card-title pricing-card-title">{{ licencias[0].precio | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} <small class="text-muted">/ trimestral</small></h2>
                                <!-- <h3 class="card-title pricing-card-title">{{ licencias[0].precio / 3 | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} <small class="text-muted">/ mensual</small></h3> -->
                                <h3 class="card-title pricing-card-title"><small class="text-muted">Pago por WebPay</small></h3>

                                <ul class="list-unstyled mt-4 pt-3 mb-4 pb-1">
                                    <li>Contenidos y herramientas digitales</li>
                                    <li>Webinars y Groundworks</li>
                                    <li>Les Mills App / Portal de Kit Digitales</li>
                                    <li>Perfil de Instructor</li>
                                </ul>

                                <a class="btn btn-lg btn-block btn-dark" href="/login">REGÍSTRATE</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 mx-auto">
                        <div class="card card-black mb-4 shadow-sm">
                            <div class="card-body">
                                <h3 class="my-0 font-weight-normal pb-4">{{ licencias[1].nombre }}</h3>

                                <h2 class="card-title pricing-card-title">{{ licencias[1].precio | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} <small class="text-muted">/ anual</small></h2>
                                <!-- <h3 class="card-title pricing-card-title">{{ licencias[1].precio / 12 | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} <small class="text-muted">/ mensual</small></h3> -->
                                <h3 class="card-title pricing-card-title"><small class="text-muted">Pago por WebPay</small></h3>

                                <ul class="list-unstyled mt-4 pt-2 mb-4">
                                    <li class="aumentar">Certificaciones ilimitadas gratis</li>
                                    <li>Liberación anticipada de mix</li>
                                    <li>Beneficios Licencia Básica</li>
                                </ul>
                                <a class="btn btn-lg btn-block btn-dark mt-5" href="/login">REGÍSTRATE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 text-center">
                <div class="card mb-4 shadow-sm">
                    <div class="card-body">
                        <h3 class="my-0 font-weight-normal pb-1">CERTIFICACIONES</h3>

                        <h2 class="card-title pricing-card-title pb-1">120.000 </h2>

                        <a class="btn btn-lg btn-block btn-dark" href="/certificaciones">REGÍSTRATE</a>
                    </div>
                </div>
                <div class="card mb-4 shadow-sm">
                    <div class="card-body">
                        <h3 class="my-0 font-weight-normal pb-1">KIT DIGITAL</h3>

                        <h2 class="card-title pricing-card-title pb-1">15.000 </h2>

                        <a class="btn btn-lg btn-block btn-dark" href="/login">REGÍSTRATE</a>
                    </div>
                </div>
            </div> -->

        </div>

    </div>
</section>