<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-ba.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-ba">¿QUÉ ES BODYATTACK?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">BODYATTACK es un entrenamiento intervalado inspirado en movimientos de diferentes disciplinas deportivas que combina ejercicios aeróbicos atléticos con ejercicios de fuerza y estabilización muscular. Mejora la resistencia cardiovascular,
                    la fuerza y la resistencia muscular.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-bb">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">FITNESS</h3>
                        <h1 class="p-0 m-0 title-color-ba"><strong>CARDIO</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">SIENTE LA</h3>
                        <h1 class="p-0 m-0 title-color-ba"><strong>ENERGIA</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">MEJORA TU</h3>
                        <h1 class="p-0 m-0 title-color-ba"><strong>FITNESS</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON BODYATTACK?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify">Puedes adaptar el entrenamiento con BODYATTACK a tu nivel actual de fitness</p>
                <ul class="desc-programs">
                    <li>Escoge las opciones de bajo impacto.</li>
                    <li>El primer día realiza cuatro o cinco tracks y progresa hasta realizar una clase completa.</li>
                </ul>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-bb">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Tonifica y define todo el cuerpo.</li>
                            <li>Mejora la coordinación, agilidad y potencia.</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <p class="desc-programs text-center">
                            Sin Material
                        </p>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>735</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            55, 45 ó 30 minutos
                        </p>
                    </div>
                </div>
            </div>



        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-ba.mp4" type="video/mp4">
     </video>
    </div>

</section>