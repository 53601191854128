<table #ref datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered">
    <thead>
        <tr>
            <th>code</th>
            <th>name</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let client of clients">
            <td> {{client.code}} </td>
            <td> {{client.name}} </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th>
                <input type="text" placeholder="code" name="search-code" />
            </th>
            <th>
                <input type="text" placeholder="name" name="search-name" />
            </th>
        </tr>
    </tfoot>
</table>
<br/>
<br/>
<div class="btn-group btn-group-justified">
    <a class="btn btn-default">
        <button type="submit" class="btn btn-default" (click)="addRow()">Creation</button>
    </a>
</div>
<div class="btn-group btn-group-justified">
    <a class="btn btn-default">
        <button type="submit" class="btn btn-default" (click)="removeRow()">Remove</button>
    </a>
</div>