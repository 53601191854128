import { Component, OnInit, Inject } from '@angular/core';
import { LicenciasService } from '../../../services/licencias.service';
import { Licencias } from '../../../models/licencias.model';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-licencias',
  templateUrl: './licencias.component.html',
  styleUrls: ['./licencias.component.scss']
})
export class LicenciasPanelComponent implements OnInit {

  loading = true;
  licenciaActual: any[];
  siguientePago: any[];
  pagos: any[];
  licencias: Licencias[];
  cargando = false;
  disponibilidadPago = false;
  errorUsuarioNuevo = false;
  errorLicencia = false;
  resultadoPago: string;
  dtOpciones: DataTables.Settings = {};

  constructor(
    private licencia: LicenciasService,
    private routerA: ActivatedRoute,
    public dialog: MatDialog,
    private user: UserService
  ) {
    this.dtOpciones = {
      lengthMenu: [[5, 10, 20, 10000], [5, 10, 20, "Todos"]],
      language: environment.lenguajeDatatables,
      order: [[2, "desc"]],
    };

    this.resultadoPago = this.routerA.snapshot.paramMap.get('resultadoPago');
    this.licencia.getLicencias().subscribe(
      data =>{
        this.licencias = data.data;
      }
    );

    this.licencia.getLicenciasByUser().subscribe(
      data => {
        this.licenciaActual = data.data['licenciaActual'];
        this.pagos = data.data['pagos'];
        this.siguientePago = data.data['siguientePago'];
        this.disponibilidadPago = data.data['disponibilidadPago'];
        this.loading = false;
      }
    );
  }

  ngOnInit(): void {
  }

  comprarLicencia (idLicencia, tipoPago): void{
    this.cargando = true;
    if(!this.disponibilidadPago){
      this.errorLicencia = true;
      this.cargando = false;
    }else{
      this.user.get_user().subscribe(
        data => {
          this.errorUsuarioNuevo = false;
          // if(data['usuario_nuevo'] == 1 && idLicencia == 1){
          //   this.errorUsuarioNuevo = true;
          // }else{
            if(data['licencia_instructor'] == 0 || (data['datosLicencia']['idLicencia'] != false  && data['datosLicencia']['idLicencia'] != idLicencia)){
              this.dialog.open(LicenciaInstructorDialog, {
                width: '1200px',
                data: {
                  licencias: this.licencias,
                  siguientePago: this.siguientePago,
                  idLicencia: idLicencia,
                  tipoPago: tipoPago,
                  user: this.user.usuario
                }
              });
            }else{
              const datos = {
                idLicencia: idLicencia,
                tipoPago: tipoPago,
                periodoSiguiente: this.siguientePago['periodo'],
                anoSiguiente: this.siguientePago['ano'],
              }
              this.licencia.comprarLicencia(datos).subscribe(
                data => {
                  const myform = document.createElement('form');
                  myform.method = 'POST';
                  myform.action = data.message.url;
                  myform.style.display = 'none';
          
                  const my_tb = document.createElement('input');
                  my_tb.type = 'TEXT';
                  my_tb.name = 'token_ws';
                  my_tb.value = data.message.token;
          
                  myform.appendChild(my_tb);
                  document.body.appendChild(myform);
                  myform.submit();
                }
              );
            }
          }
        // }
      );
    }
  }

}


@Component({
  selector: 'licencia-instructor',
  templateUrl: '../../terminos_condiciones/licencia-instructor.html',
})
export class LicenciaInstructorDialog {

  cargando = true;
  fechaHoy = new Date();
  licencias: Licencias[];
  usuario: any[];
  idLicencia: number;
  precio: any;
  tipoPago: any;
  siguientePago: any[];

  constructor(
    public dialogRef: MatDialogRef<LicenciaInstructorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private licencia: LicenciasService,
  ) {
    this.licencias = data.licencias;
    this.idLicencia = data.idLicencia;
    this.tipoPago = data.tipoPago;
    this.siguientePago = data.siguientePago;
    this.usuario = data.user;

    console.log(this.licencias);

    if(this.idLicencia == 1){
      if(this.tipoPago == 'trimestral'){
        this.precio = this.licencias[this.idLicencia-1]['precio_trimestral'];
      }else{
        this.precio = this.licencias[this.idLicencia-1]['precio_semestral'];
      }
    }else{
      this.precio = this.licencias[this.idLicencia-1]['precio'];
    }
  }
  

  aceptarLicenciaInstructor(): void{
    this.cargando = true;

    const datos = {
      idLicencia: this.idLicencia,
      tipoPago: this.tipoPago,
      periodoSiguiente: this.siguientePago['periodo'],
      anoSiguiente: this.siguientePago['ano'],
    }
    this.licencia.comprarLicencia(datos).subscribe(
      data => {
        const myform = document.createElement('form');
        myform.method = 'POST';
        myform.action = data.message.url;
        myform.style.display = 'none';

        const my_tb = document.createElement('input');
        my_tb.type = 'TEXT';
        my_tb.name = 'token_ws';
        my_tb.value = data.message.token;

        myform.appendChild(my_tb);
        document.body.appendChild(myform);
        myform.submit();
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
