import { Component, OnInit } from '@angular/core';
import { ContenidosService } from '../../../services/contenido.service';
import { Contenidos } from '../../../models/contenidos.model';
import { environment } from '../../../../environments/environment';
import { LicenciasService } from '../../../services/licencias.service';

@Component({
  selector: 'app-contenido',
  templateUrl: './contenido.component.html',
  styleUrls: ['./contenido.component.scss']
})
export class ContenidoPanelComponent implements OnInit {

  tokenWs: string;
  contenidos: Contenidos[];
  contenidosFiltrados: Contenidos[];
  filtros = new Array();
  programasFiltros: any[];
  categoriasFiltros: any[];
  licenciaActiva = false;
  anosFiltros = new Array();
  loading = true;
  s3Url = environment.s3_public_url + environment.previsualizaciones;

  constructor(
    private cont: ContenidosService,
    private lic: LicenciasService,
  ) {
    var anoActual = new Date().getFullYear();
    for (let index = 2021; index <= anoActual+1; index++) {
      this.anosFiltros.push(index);
    }
    this.lic.checkLicencia().subscribe(
      data => {
        this.licenciaActiva = data.data['activa'];
        this.cont.getContenidos().subscribe(
          data => {
            console.log(data.data);
            this.contenidos = data.data.contenidos;
            this.contenidosFiltrados = data.data.contenidos;
            this.programasFiltros = data.data.programas;
            this.categoriasFiltros = data.data.categorias;
              

            this.loading = false;
          }
        );
      }
    );
  }

  ngOnInit(): void {
    
  }

  filtrar(valor, filtro): void{
    let arrayFiltro = {
      valor: valor,
      filtro: filtro
    }
    let iFiltro = this.filtros.map((el) => el.filtro).indexOf(filtro);
    if(valor === 'TODOS'){
      this.filtros.splice(iFiltro, 1);
    }else{
      if(iFiltro < 0){
        this.filtros.push(arrayFiltro);
      }else{
        this.filtros.splice(iFiltro, 1);
        this.filtros.push(arrayFiltro);
      }
    }
    this.contenidosFiltrados = this.contenidos;
    this.contenidosFiltrados = this.contenidosFiltrados.filter(
      item => {
        for (let index = 0; index < this.filtros.length; index++) {
          switch (this.filtros[index].filtro) {
            case 'ano':
              if(item.ano != this.filtros[index].valor){
                return false;
              }
            break;
            case 'periodo':
              if(item.periodo != this.filtros[index].valor){
                return false;
              }
            break;
            case 'categoria':
              if(item.categorias.length > 0){
                for (let x = 0; x < item.categorias.length; x++) {
                  let categoriaEncontrada = false;
                  if(item.categorias[x].id_categoria == this.filtros[index].valor){
                    categoriaEncontrada = true;
                  }
                  if(!categoriaEncontrada){
                    return false;
                  }
                }
              }else{
                return false;
              }
            break;
            case 'programa':
              if(item.programas.length > 0){
                for (let x = 0; x < item.programas.length; x++) {
                  let categoriaEncontrada = false;
                  if(item.programas[x].id_programa == this.filtros[index].valor){
                    categoriaEncontrada = true;
                  }
                  if(!categoriaEncontrada){
                    return false;
                  }
                }
              }else{
                return false;
              }
            break;
          }
        }
        return true;
      }
    );
    
  }

  verContenido(nombre): void{
    this.cont.verContenido(nombre).subscribe(
      data => {
        console.log(data);
        window.open(data.data, '_blank');
      }
    );
  }

}
