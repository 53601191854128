import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';
import { RegionesService } from '../../services/regiones.service';

@Component({
  selector: 'app-certificaciones',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss']
})
export class BuscadorComponent implements OnInit {
  
  s3Url = environment.s3_public_url + environment.perfil;
  instructores: any[];
  instructoresFiltros: any[];
  region: any[];
  comuna: any[];
  filtros = new Array();
  loading = true;
  loadingR = true;
  loadingC = true;
  loadingCS = false;

  constructor(
    private user: UserService,
    private regiones: RegionesService,
  ) {

    this.regiones.getRegiones().subscribe(
      data => {
        this.region = data.data;
        this.loadingR = false;
      }
    );

    this.user.getInstructores().subscribe(
      data => {
        console.log(data);
        this.instructores = data.data;
        this.instructoresFiltros = this.instructores;
      }
    )
  }

  ngOnInit(): void {
  }

  obtenerComunas(value): void{
    if(value !== 'TODOS'){
      this.loadingCS = true;
      this.loadingC = true;
      this.regiones.getComunasByRegion(value).subscribe(
        data => {
          this.comuna = data.data;
          this.loadingC = false;
          this.loadingCS = false;
        }
      );
    }
  }

  filtrar(valor, filtro): void{
    let arrayFiltro = {
      valor: valor,
      filtro: filtro
    }
    let iFiltro = this.filtros.map((el) => el.filtro).indexOf(filtro);
    if(valor === 'TODOS'){
      this.filtros.splice(iFiltro, 1);
    }else{
      if(iFiltro < 0){
        this.filtros.push(arrayFiltro);
      }else{
        this.filtros.splice(iFiltro, 1);
        this.filtros.push(arrayFiltro);
      }
    }
    this.instructoresFiltros = this.instructores;
    this.instructoresFiltros = this.instructoresFiltros.filter(
      item => {
        for (let index = 0; index < this.filtros.length; index++) {
          switch (this.filtros[index].filtro) {
            case 'region':
              if(item.region != this.filtros[index].valor){
                return false;
              }
            break;
            case 'comuna':
              if(item.comuna != this.filtros[index].valor){
                return false;
              }
            break;
            case 'nombre':
              if(this.filtros[index].valor.length > 0){
                let nombreApellido = item.name + " " + item.apellidos;
                nombreApellido = nombreApellido.toLowerCase();

                let nombreApellidoBuscar = this.filtros[index].valor.toLowerCase();

                if(nombreApellido.search(nombreApellidoBuscar) == -1){
                  return false;
                }
              }
            break;
          }
        }
        return true;
      }
    );
    
  }



}
