import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';
import { CertificacionesService } from 'src/app/services/certificaciones.service';
import { GimnasioService } from 'src/app/services/gimnasios.service';
import { LicenciasService } from 'src/app/services/licencias.service';
import { ProgramasService } from 'src/app/services/programas.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading = true;
  usuario: any[];
  certificaciones: any[];
  licencia: any[];
  vigenciaLicencia: any[];
  gimnasiosLicenciados: any[];
  programas: any[];
  programasPlataforma: any[];
  actDisponibles: any[] = new Array();
  certDisponibles: any[] = new Array();
  nCertDisponibles: number = 0;
  nActDisponibles: number = 0;
  nProgramas: number = 0;
  nCertificaciones: number = 0;
  nGimnasiosLicenciados: number = 0;

  constructor(
    private user: UserService,
    private cert: CertificacionesService,
    private lic: LicenciasService,
    private prog: ProgramasService,
    private gim: GimnasioService
  ) { }

  ngOnInit(): void {
    this.user.get_user().subscribe(
      data => {
        this.usuario = data;

        this.cert.getCertificacionesByUser().subscribe(
          data => {
            this.certificaciones = data.data;
            this.nCertificaciones = this.certificaciones.length;
            
            this.lic.getLicenciasByUser().subscribe(
              data => {
                this.licencia = data.data['licenciaActual'];
                if(this.licencia){
                  this.licencia['tipoLicencia'] = this.licencia['tipoLicencia'].replace('Licencia ', '')
                }
                this.prog.getProgramasByUser().subscribe(
                  data => {
                    this.programas = data.data['programasActual'];
                    this.nProgramas = this.programas.length;
                    this.gim.getUsuarioGimnasioLicenciado().subscribe(
                      data => {
                        this.gimnasiosLicenciados = data.data;
                        console.log(data.data);
                        if(this.gimnasiosLicenciados['info']){
                          this.nGimnasiosLicenciados = this.gimnasiosLicenciados['info'].length;
                        }else{
                          this.nGimnasiosLicenciados = 0;
                        }

                        this.lic.checkLicencia().subscribe(
                          data => {
                            this.vigenciaLicencia = data.data;
                            if(this.vigenciaLicencia['activa']){
                              this.actDisponibles = this.programas;
                              this.nActDisponibles = this.actDisponibles.length;
    
                              this.prog.getProgramas().subscribe(
                                data => {
                                  this.programasPlataforma = data.data;
      
                                  this.programasPlataforma.forEach(pp => {
                                    let buscarPrograma = new Array();
                                    buscarPrograma = this.programas.find( x => x['nombre'] == pp['nombre']);
                                    if(!buscarPrograma){
                                      this.certDisponibles.push(pp)
                                    }
                                  });
                                  this.nCertDisponibles = this.certDisponibles.length;
                                  this.loading = false;
                                }
                              );
                            }else{
                              if(this.gimnasiosLicenciados['programas']){
                                this.programas.forEach(pp => {
                                  let buscarPrograma = new Array();
                                  buscarPrograma = this.gimnasiosLicenciados['programas'].find( x => x['nombre'] == pp['nombre']);
                                  if(buscarPrograma){
                                    this.actDisponibles.push(pp)
                                  }
                                })
                                this.nActDisponibles = this.actDisponibles.length;
                                this.gimnasiosLicenciados['programas'].forEach(pp => {
                                  let buscarPrograma = new Array();
                                  buscarPrograma = this.programas.find( x => x['nombre'] == pp['nombre']);
                                  if(!buscarPrograma){
                                    let buscarProgramaArray = new Array();
                                    buscarProgramaArray = this.certDisponibles.find( x => x['nombre'] == pp['nombre']);
                                    if(!buscarProgramaArray){
                                      this.certDisponibles.push(pp);
                                    }
                                  }
                                });
                                this.nCertDisponibles = this.certDisponibles.length;
                              }
                              this.loading = false;
                            }
                          }
                        );
                      }
                    );


                  }
                );
              }
            );
          }
        );
      }
    );
  }

}
