import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { TokenStorageService } from '../../../services/token-storage.service';

@Component({
  selector: 'app-chequear-correo',
  templateUrl: './chequear-correo.component.html',
  styleUrls: ['./chequear-correo.component.scss']
})
export class ChequearCorreoComponent implements OnInit {

  idCertificacion: number;
  checkForm: FormGroup;
  cargando = false;
  mensajeError: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private user: UserService,
    private routeN: Router,
    private tokenStorage: TokenStorageService,
  ) {
    this.idCertificacion = Number(this.route.snapshot.paramMap.get('idCertificacion'));
    if (this.tokenStorage.checkToken()) {
      this.routeN.navigate(['/certificaciones/comprar/', this.idCertificacion, btoa(this.user.usuario['email'])]);
    }
   }

  ngOnInit(): void {
    this.checkForm = this.fb.group({
        email   : ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit(): void {
    if(this.checkForm.valid){
      this.cargando = true;
      this.user.checkMail(this.checkForm).subscribe(
          data => {
            if(data.data === true){
              this.routeN.navigate(['/login/', this.idCertificacion, btoa(this.checkForm.get('email').value)]);
            }else{
              this.routeN.navigate(['/certificaciones/comprar/', this.idCertificacion, btoa(this.checkForm.get('email').value)]);
            }
          },
          err => {
              this.mensajeError = 'Tenemos problemas para conectarnos al servidor, intenta en unos minutos';
              this.cargando = false;
          }
      );
    }
}

}
