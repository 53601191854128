<div class="bg-foto">
    <div id="registro" class="container main-registro">
        <form name="loginForm" [formGroup]="registroForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-12 col-md-9 col-lg-8 mx-auto">
                    <div class="card my-2 mx-2">
                        <div class="card-body">
                            <h3 class="card-title text-center">Registro</h3>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Correo</mat-label>
                                        <input matInput formControlName="email">
                                        <mat-icon matSuffix>mail</mat-icon>
                                        <mat-error *ngIf="registroForm.get('email').hasError('required')">
                                            El Correo es Obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="!registroForm.get('email').hasError('required') && registroForm.get('email').hasError('email')">
                                            Por favor ingresa un correo válido
                                        </mat-error>
                                        <mat-error *ngIf="registroForm.get('email').hasError('correoExiste')">
                                            El Correo ya esta registrado
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Nombres</mat-label>
                                        <input matInput formControlName="nombres">
                                        <mat-error *ngIf="registroForm.get('nombres').hasError('required')">
                                            El Nombre es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Apellidos</mat-label>
                                        <input matInput formControlName="apellidos">
                                        <mat-error *ngIf="registroForm.get('apellidos').hasError('required')">
                                            El Apellido es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Contraseña</mat-label>
                                        <input matInput type="password" formControlName="password">
                                        <mat-icon matSuffix>password</mat-icon>
                                        <mat-error>
                                            La Contraseña es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Confirma Contraseña</mat-label>
                                        <input matInput type="password" formControlName="c_password">
                                        <mat-icon matSuffix>password</mat-icon>
                                        <mat-error>
                                            La Confirmación de Contraseña es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Rut</mat-label>
                                        <input matInput formControlName="rut" formatRut>
                                        <mat-icon matSuffix>perm_identity</mat-icon>
                                        <mat-error *ngIf="registroForm.get('rut').hasError('required')">
                                            El Rut es Obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="registroForm.get('rut').hasError('invalidRut')">
                                            El Rut es inválido
                                        </mat-error>
                                        <mat-error *ngIf="registroForm.get('rut').hasError('rutExiste')">
                                            El Rut ya esta registrado
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Teléfono</mat-label>
                                        <mat-icon matSuffix>local_phone</mat-icon>
                                        <input mask="(0) 0000-0000" matInput formControlName="telefono">
                                        <mat-error *ngIf="registroForm.get('telefono').hasError('required')">
                                            El Teléfono es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>
                                            Región
                                            <mat-spinner class="progress-spinner-inline" *ngIf="loadingR" class="float-right" diameter="20"></mat-spinner>
                                        </mat-label>
                                        <mat-select [disabled]="loadingR" formControlName="region" (selectionChange)="obtenerComunas($event.value)">
                                            <mat-option *ngFor="let r of region" [value]="r.id">{{ r.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="registroForm.get('region').hasError('required')">
                                            La Región es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>
                                            Comuna
                                            <mat-spinner class="progress-spinner-inline" *ngIf="loadingCS" class="float-right" diameter="20"></mat-spinner>
                                        </mat-label>
                                        <mat-select [disabled]="loadingC" formControlName="comuna">
                                            <mat-option *ngFor="let r of comuna" [value]="r.id">{{ r.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="registroForm.get('comuna').hasError('required')">
                                            La Comuna es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Dirección</mat-label>
                                        <input matInput formControlName="direccion">
                                        <mat-icon matSuffix>place</mat-icon>
                                        <mat-error *ngIf="registroForm.get('direccion').hasError('required')">
                                            La Dirección es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox (change)="terminosPress($event)" color="accent" formControlName="terminos">
                                        He leído y acepto los
                                        <a (click)="abrirPolitica()">Política de Privacidad</a>
                                    </mat-checkbox>
                                    <mat-error *ngIf="errorCheckbox">
                                        Debes aceptar la Política de Privacidad
                                    </mat-error>
                                    <mat-error class="text-center pb-3 w-75 mx-auto" *ngIf="errorRegistro">
                                        {{MensajeError}}
                                    </mat-error>
                                </div>
                            </div>

                            <button *ngIf="cargando" class="btn btn-lg btn-primary btn-block" type="submit" disabled>
                                CARGANDO 
                                <mat-icon>
                                    <mat-spinner class="mt-1 ml-3" color="primary" diameter="25"></mat-spinner>
                                </mat-icon>
                            </button>
                            <button *ngIf="!cargando" class="btn btn-lg btn-primary btn-block" type="submit">REGISTRAR</button>
                            <hr>
                            <div class="text-center">
                                <span>¿Ya tienes cuenta?, </span>
                                <a [routerLink]="'/login'">Inicia Sesión</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>