import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bodycombat',
  templateUrl: './bodycombat.component.html',
  styleUrls: ['./bodycombat.component.scss']
})
export class BodycombatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
