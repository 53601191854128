<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-bs.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-bs">¿QUÉ ES BODYSTEP?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">BODYSTEP es un programa de entrenamiento cardiovascular en el que se utiliza el step para conseguir grandes beneficios en piernas, glúteos y reducir el porcentaje de grasa corporal. Los participantes quemarán muchas calorías, moldearán
                    todo el cuerpo y, sobre todo, sentirán sus piernas en acción.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-bs">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">FITNESS</h3>
                        <h1 class="p-0 m-0 title-color-bs"><strong>CARDIO</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">TONIFICA</h3>
                        <h1 class="p-0 m-0 title-color-bs"><strong>GLÚTEOS Y PIERNAS</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">SIÉNTETE ACTIVO CON</h3>
                        <h1 class="p-0 m-0 title-color-bs"><strong>LA MEJOR MUSICA</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON BODYSTEP?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify"></p>
                <ul class="desc-programs">
                    <li>Sólo tienes que seguir a tu instructor mientras imparte la clase y te explica las diferentes opciones.</li>
                    <li>El primer día realiza cuatro o cinco tracks y progresa hasta realizar una clase completa.</li>
                </ul>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-bs">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Aumenta el nivel general del fitness</li>
                            <li>Define piernas, glúteos y reduce el porcentaje de grasa corporal</li>
                            <li>Mejora tu coordinación general</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <p class="desc-programs text-center">
                            Step ajustable en altura y discos
                        </p>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>620</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            55, 45 ó 30 minutos
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-bs.mp4" type="video/mp4">
     </video>
    </div>

</section>