import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContenidosService {

  constructor(
    private http: HttpClient,
    private token: TokenStorageService
  ) { }

  getContenidos(): Observable<any> {
    return this.http.get(environment.api_url + 'contenidos', { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }

  verContenido(contenido): Observable<any> {
    return this.http.post(environment.api_url + 'verContenido', {contenido}, { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }
  

  getProgramas(): Observable<any> {
    return this.http.get(environment.api_url + 'programas', { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }

}
