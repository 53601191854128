import { Component, OnInit } from '@angular/core';
import { CertificacionesService } from '../../services/certificaciones.service';
import { Certificaciones } from '../../models/certificaciones.model';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-certificaciones',
  templateUrl: './certificaciones.component.html',
  styleUrls: ['./certificaciones.component.scss']
})
export class CertificacionesComponent implements OnInit {

  dtOpciones: DataTables.Settings = {};
  certs: Certificaciones[];
  certsFiltros: Certificaciones[];
  programas = [];
  tiposCertificacion = [];
  loading = true;
  estadoPago: string;
  pagoRechazado = false;
  licenciaActual: any[];
  licenciaFutura: any[];
  logeado = false;

  constructor(
    private certificaciones: CertificacionesService,
    private routerA: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    public dialog: MatDialog,
  ) {

    
    if (this.tokenStorage.checkToken()) {
      this.logeado = true;
    }

    this.dtOpciones = {
      lengthMenu: [[ 10000], [  "Todos"]],
      language: environment.lenguajeDatatables,
      order: [[0, "asc"]],
      dom: "t",
      scrollX: true
    };

    this.estadoPago = this.routerA.snapshot.paramMap.get('estadoPago');
    if(this.estadoPago === 'pagoRechazado'){
      this.pagoRechazado = true;
    }

    this.certificaciones.getCertificaciones().subscribe(
      (data) => {
        console.log(data.data.certificaciones);
        this.certs = data.data.certificaciones;
        this.certsFiltros = data.data.certificaciones;
        this.licenciaActual = data.data.periodoActual;
        this.licenciaFutura = data.data.periodoFuturo;
        this.listaProgramas();
        this.listaTiposCert();
      }
    );
  }

  ngOnInit(): void {
  }

  listaProgramas():void {
    this.certs.forEach((element) => {
      if(!this.programas.includes(element.programa_nombre)){
        this.programas.push(element.programa_nombre);
      }
    });
    this.programas.sort();
  }

  listaTiposCert():void {
    this.certs.forEach((element) => {
      if(!this.tiposCertificacion.includes(element.nombre_tipo)){
        this.tiposCertificacion.push(element.nombre_tipo);
      }
    });
    this.tiposCertificacion.sort();
    this.loading = false;
  }

  filtrar(valor, filtro):void{
    this.certsFiltros = this.certs;
    if(filtro === 'programa'){
      if(valor !== 'TODOS'){
        this.certsFiltros = this.certsFiltros.filter(cert => cert.programa_nombre.includes(valor));
      }
    }else{
      if(valor !== 'TODOS'){
        this.certsFiltros = this.certsFiltros.filter(cert => cert.nombre_tipo.includes(valor));
      }
    }
  }


  abrirPopup(): void{
    this.dialog.open(PopupCertificacionesDialog, {
      width: '400px',
    });
  }


}


@Component({
  selector: 'popup-certificaciones',  
  templateUrl: './popup.html',
})
export class PopupCertificacionesDialog {

  constructor(
    public dialogRef: MatDialogRef<PopupCertificacionesDialog>,
    private route: Router,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  redirigir(){
    this.dialogRef.close();
    this.route.navigate(['/login']); 
  }
  redirigirGim(){
    this.dialogRef.close();
    this.route.navigate(['/gimnasios']); 
  }
  redirigirLic(){
    this.dialogRef.close();
    this.route.navigate(['/licencias']); 
  }

}