import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bodystep',
  templateUrl: './bodystep.component.html',
  styleUrls: ['./bodystep.component.scss']
})
export class BodystepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
