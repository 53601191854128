<div class="logo-repeat-bg">
    <div id="programas" class="container-xl mt-5 mb-5">
        <div class="row mt-5">
            <div *ngIf="loading" class="col-12">
                <mat-spinner class="mx-auto mt-5"></mat-spinner>
            </div>
            <div *ngIf="!loading" class="col-12">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-9">
                        <div class="card shadow-sm">
                            <div class="card-header">
                                <h4 class="my-0 font-weight-normal">
                                    <div class="row">
                                        <div class="col-md-8 mt-3">
                                            ACTUALIZACIONES DISPONIBLES
                                        </div>
                                        <div class="col-md-4 h6 mt-2">
                                            <select #cambioPeriodo (change)="cambiarPeriodo(cambioPeriodo.value)" class="form-control" name="test" id="test">
                                                <option value="actual">Periodo Actual</option>
                                                <option value="anterior">Periodo Anterior</option>
                                                <option value="proximo">Periodo Próximo</option>
                                            </select>
                                        </div>
                                    </div>
                                </h4>
                                <hr>
                            </div>
                            <div class="card-body">
                                <div *ngIf="resultadoPago && resultadoPago === 'pagoExitoso'" class="alert alert-success text-center">
                                    Haz realizado el pago de tus actualizaciones exitosamente.
                                </div>
                                <div *ngIf="resultadoPago && resultadoPago === 'errorPago'" class="alert alert-danger text-center">
                                    El pago por tus actualizaciones no pudo ser completado, intentalo nuevamente.
                                </div>

                                <div *ngIf="mostrarAnteriores">
                                    <table datatable [dtOptions]="dtOpciones" class="table bg-white table-striped w-100 text-center">
                                        <thead>
                                            <tr>
                                                <th>Programa</th>
                                                <th>Mix</th>
                                                <th>Periodo</th>
                                                <th>Estado</th>
                                                <th>Entrega Estimada</th>
                                                <th>Valor</th>
                                                <th>Sel.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let p of programasAnteriores; let i = index">
                                                <tr>
                                                    <td>{{p['nombre']}}</td>
                                                    <td>{{p['mix']}}</td>
                                                    <td>{{p['periodo']}} {{ p['ano'] }}</td>
                                                    <td>
                                                        <span *ngIf="p['estado'] == 1">Pagado</span>
                                                        <span *ngIf="p['estado'] == 0">Disponible</span>
                                                    </td>
                                                    <td>{{ fechaActual | agregarDiasFecha: diasEntregaKits}}</td>
                                                    <td *ngIf="p['id'] != 16">${{valorKitAnterior | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                                    <td *ngIf="p['id'] == 16">${{valorKitGritAnterior | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                                    <td *ngIf="((licenciaActiva) || licenciaGimnasio[p['nombre']]) && p['estado'] == 0">
                                                        <ng-container *ngIf="p['nombre'] == 'POWER JUMP' && p['mix'] >= 72 || p['nombre'] != 'POWER JUMP'">
                                                            <input *ngIf="p['id'] != 16" type="checkbox" class="form-control" (change)="seleccionarMix($event, p['mix'], p['periodo'], p['ano'], p['nombre'], valorKitAnterior, 'anterior')" [checked]="inputChecked[p['nombre']+p['periodo']+p['ano']]">
                                                            <input *ngIf="p['id'] == 16" type="checkbox" class="form-control" (change)="seleccionarMix($event, p['mix'], p['periodo'], p['ano'], p['nombre'], valorKitGritAnterior, 'anterior')" [checked]="inputChecked[p['nombre']+p['periodo']+p['ano']]">
                                                        </ng-container>
                                                    </td>
                                                    <td class="text-danger" *ngIf="!licenciaActiva && !licenciaGimnasio[p['nombre']] && p['estado'] == 0">Sin Licencia</td>
                                                    <td class="text-success" *ngIf="p['estado'] == 1">Pagado</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="mostrarActual">
                                    <table datatable [dtOptions]="dtOpciones" class="table bg-white table-striped w-100 text-center">
                                        <thead>
                                            <tr>
                                                <th>Programa</th>
                                                <th>Mix</th>
                                                <th>Periodo</th>
                                                <th>Estado</th>
                                                <th>Entrega Estimada</th>
                                                <th>Valor</th>
                                                <th>Sel.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let p of programasActual let i = index">
                                                <tr>
                                                    <td>{{p['nombre']}}</td>
                                                    <td>{{p['mix']}}</td>
                                                    <td>{{p['periodo']}} {{ p['ano'] }}</td>
                                                    <td>
                                                        <span *ngIf="p['estado'] == 1">Pagado</span>
                                                        <span *ngIf="p['estado'] == 0">Disponible</span>
                                                    </td>
                                                    <td>{{ fechaActual | agregarDiasFecha: diasEntregaKits}}</td>
                                                    <td *ngIf="p['id'] != 16">${{valorKit | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                                    <td *ngIf="p['id'] == 16">${{valorKitGrit | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                                    <td *ngIf="((licenciaActiva) || licenciaGimnasio[p['nombre']]) && p['estado'] == 0">
                                                        <input *ngIf="p['id'] != 16" type="checkbox" class="form-control" (change)="seleccionarMix($event, p['mix'], p['periodo'], p['ano'], p['nombre'], valorKit, 'actual')" [checked]="inputChecked[p['nombre']+p['periodo']+p['ano']]">
                                                        <input *ngIf="p['id'] == 16" type="checkbox" class="form-control" (change)="seleccionarMix($event, p['mix'], p['periodo'], p['ano'], p['nombre'], valorKitGrit, 'actual')" [checked]="inputChecked[p['nombre']+p['periodo']+p['ano']]">
                                                    </td>
                                                    <td class="text-danger" *ngIf="!licenciaActiva && !licenciaGimnasio[p['nombre']] && p['estado'] == 0">Sin Licencia</td>
                                                    <td class="text-success" *ngIf="p['estado'] == 1">Pagado</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="mostrarFuturo">
                                    <table datatable [dtOptions]="dtOpciones" class="table bg-white table-striped w-100 text-center">
                                        <thead>
                                            <tr>
                                                <th>Programa</th>
                                                <th>Mix</th>
                                                <th>Periodo</th>
                                                <th>Estado</th>
                                                <th>Entrega Estimada</th>
                                                <th>Valor</th>
                                                <th>Sel.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let p of programasFuturo let i = index">
                                                <tr>
                                                    <td>{{p['nombre']}}</td>
                                                    <td>{{p['mix']}}</td>
                                                    <td>{{p['periodo']}} {{ p['ano'] }}</td>
                                                    <td>
                                                        <span *ngIf="(fechaCambioQFuturo > fechaActual) && p['estado'] == 0 && detalleLicencia['idLicencia'] != 2">No Disponible</span>
                                                        <span *ngIf="(fechaCambioQFuturo <= fechaActual) && p['estado'] == 0 && detalleLicencia['idLicencia'] != 2">Disponible</span>

                                                        <span *ngIf="(fechaPreventaLicencia > fechaActual) && p['estado'] == 0 && detalleLicencia['idLicencia'] == 2">No Disponible</span>
                                                        <span *ngIf="(fechaPreventaLicencia <= fechaActual) && p['estado'] == 0 && detalleLicencia['idLicencia'] == 2">Disponible</span>
                                                        <span *ngIf="p['estado'] == 1">Pagado</span>
                                                    </td>
                                                    <td *ngIf="fechaCambioQFuturo > fechaActual && detalleLicencia['idLicencia'] != 2">{{ fechaCambioQFuturo | agregarDiasFecha: diasEntregaKits}}</td>
                                                    <td *ngIf="fechaCambioQFuturo <= fechaActual && detalleLicencia['idLicencia'] != 2">{{ fechaActual | agregarDiasFecha: diasEntregaKits}}</td>
                                                    <td *ngIf="fechaPreventaLicencia > fechaActual && detalleLicencia['idLicencia'] == 2">{{ fechaPreventaLicencia | agregarDiasFecha: diasEntregaKits}}</td>
                                                    <td *ngIf="fechaPreventaLicencia <= fechaActual && detalleLicencia['idLicencia'] == 2">{{ fechaActual | agregarDiasFecha: diasEntregaKits}}</td>
                                                    <td *ngIf="p['id'] != 16">${{valorKitFuturo | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                                    <td *ngIf="p['id'] == 16">${{valorKitGritFuturo | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                                    <td *ngIf="((licenciaActiva) || licenciaGimnasio[p['nombre']]) && p['estado'] == 0 && detalleLicencia['idLicencia'] == 2">
                                                        <input *ngIf="fechaPreventaLicencia <= fechaActual && p['id'] != 16" type="checkbox" class="form-control" (change)="seleccionarMix($event, p['mix'], p['periodo'], p['ano'], p['nombre'], valorKitFuturo, 'futuro')" [checked]="inputChecked[p['nombre']+p['periodo']+p['ano']]">
                                                        <input *ngIf="fechaPreventaLicencia <= fechaActual && p['id'] == 16" type="checkbox" class="form-control" (change)="seleccionarMix($event, p['mix'], p['periodo'], p['ano'], p['nombre'], valorKitGritFuturo, 'futuro')" [checked]="inputChecked[p['nombre']+p['periodo']+p['ano']]">
                                                    </td>
                                                    <td class="text-danger" *ngIf="!licenciaActiva && !licenciaGimnasio[p['nombre']] && p['estado'] == 0">Sin Licencia</td>
                                                    <td class="text-danger" *ngIf="detalleLicencia['idLicencia'] != 2 && p['estado'] != 1">Sin Licencia Pro</td>
                                                    <td class="text-success" *ngIf="p['estado'] == 1">Pagado</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3">
                        <div class="card shadow-sm">
                            <div class="card-header">
                                <h4 class="my-0 font-weight-normal">
                                    RESUMEN DE PAGO
                                </h4>
                                <hr>
                            </div>
                            <div class="card-body margin-mobile">
                                <table class="w-100 text-center" *ngIf="resumenPago.length > 0">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Programa</th>
                                            <th>Mix</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let r of resumenPago">
                                            <td class="text-left">{{ r.nombre }}</td>
                                            <td>{{ r.mix }}</td>
                                            <td>
                                                ${{ r.valor | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}
                                                <a (click)="removerMix(r.nombre, r.periodo, r.ano, r.mix)">
                                                    <i class="fa fa-times text-danger"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="totalResumenPago > 0">
                                            <td colspan="3">
                                                <hr>
                                            </td>
                                        </tr>
                                        <tr *ngIf="totalResumenPago > 0">
                                            <td class="text-left" colspan="2">Total</td>
                                            <td>${{ totalResumenPago | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="text-center" *ngIf="resumenPago.length === 0">
                                    No hay programas seleccionados
                                </div>
                                <hr *ngIf="totalResumenPago > 0">
                                <button *ngIf="totalResumenPago > 0 && !cargando" class="btn btn-block btn-dark rounded-0" (click)="comprarProgramas()">
                                    PAGAR
                                </button>
                                <button *ngIf="totalResumenPago > 0  && cargando" class="btn rounded-0 btn-dark btn-block" type="submit" disabled>
                                    CARGANDO 
                                    <mat-icon>
                                        <mat-spinner class="mt-1 ml-3" color="white" diameter="25"></mat-spinner>
                                    </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-sm-12 col-md-12 col-lg-9 mt-5">
                        <div class="card shadow-sm">
                            <div class="card-header">
                                <h4 class="my-0 font-weight-normal">
                                    <div class="row">
                                        <div class="col-md-8 mt-3">
                                            HISTORIAL DE PAGOS
                                        </div>
                                    </div>
                                </h4>
                                <hr>
                            </div>
                            <div class="card-body">
                                <table datatable [dtOptions]="dtOpciones" class="table bg-white table-striped w-100 text-center">
                                    <thead>
                                        <tr>
                                            <th>Programa</th>
                                            <th>Mix</th>
                                            <th>Periodo</th>
                                            <th>Estado</th>
                                            <th>Fecha de Entrega</th>
                                            <th>Boleta</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let p of programasTodos; let i = index">
                                            <tr *ngIf="p['id_pago']">
                                                <td>{{p['nombre']}}</td>
                                                <td>{{p['mix']}}</td>
                                                <td>{{p['periodo']}} {{ p['ano'] }}</td>
                                                <td>
                                                    <span *ngIf="p['id_pago']">Pagado</span>
                                                    <span *ngIf="!p['id_pago']">Disponible</span>
                                                </td>
                                                <td>NN</td>
                                                <td>NN</td>
                                                <td>${{valorKitAnterior | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>