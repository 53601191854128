<style>
    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }
    
    a {
        color: #000 !important;
        font-weight: bold !important
    }
    
    @media only screen and (min-width: 768px) {
        .margenes {
            margin: 15px !important;
        }
    }
</style>
<h1 mat-dialog-title class="text-center">ACUERDO DE INSTRUCTOR</h1>
<div mat-dialog-content class="margenes">
    <div class="text-justify">
        <p>
            En la ciudad de Concepción, a {{ fechaHoy | date: 'EEEE d \'de\' MMMM yyyy':'':'es'}}, comparecen por una parte, don <b>GREGORIO ANDRÉS YANQUEZ SABUGO</b>, Ingeniero Comercial, cédula nacional de identidad N° 15.593.737-8, en representación
            de <b>BODY SYSTEMS CHILE S.A.</b>, rol único tributario Nº 96.982.500-7, ambos con domicilio para estos efectos en Av. Bernardo O'higgins Poniente #77, Of 1503, Comuna de Concepción, Ciudad de Concepción, en adelante <b>"la empresa"</b>; y
            por la otra parte, el Sr./Sra. <b>{{ usuario['name'] | uppercase }} {{ usuario['apellidos'] | uppercase }}</b>, cédula nacional de identidad Nº {{ usuario['rut'] | rut }}, domiciliado en {{ usuario['direccion'] }}, comuna de {{ usuario['comuna']
            }}, región de {{usuario['region']}} en adelante "el instructor", quienes convienen en celebrar el siguiente contrato de Capacitación de acuerdo a las siguientes cláusulas y condiciones:
        </p>
        <p>
            <u>PRIMERO:</u> Las partes están en pleno conocimiento que la Empresa es legítima titular y propietaria de todos los derechos exclusivos para otorgar la licencia de ejercitación con música de LES MILLS® en territorio Chileno, inscrito en INAPI
            (Instituto Nacional de Propiedad Intelectual) del Ministerio de Economía, que comprende los programas de entrenamiento LES MILLS GRIT SERIES®, LES MILLS GRIT®, BODYPUMP®, BODYBALANCE®, BODYATTACK®, BODYSTEP®, BODYCOMBAT®, RPM®, BODYJAM®, BODYVIVE®,
            SH ́BAM®, SPRINT®, CXWORX®, LES MILLS CORE®, LES MILLS BARRE® y POWERJUMP®.
        </p>
        <p>
            <u>SEGUNDO:</u> <b><b>"la empresa"</b></b> acredita que el <b>"el instructor"</b> está capacitado en el dictado de las clases de los programas indicados en el ordinal precedente, y que proporcionó todo el material para su capacitación, que
            incluye el de tipo visual, escrito, musical, técnico y coreográfico. Por su parte <b>"el instructor"</b> reconoce en términos formales, explícitos y directos que <b><b>"la empresa"</b></b> es la titular de los derechos de los programas, por
            lo que no tiene derecho alguno sobre los mismos, más allá del uso en los términos y condiciones señalados en el presente contrato. Además <b>"el instructor"</b> confirma que es una persona apta físicamente, con capacidad jurídica y económica
            para contratar y obligarse en los términos del presente contrato.
        </p>

        <u>TERCERO:</u> <b><b>"la empresa"</b></b> acredita que traspasó a <b>"el instructor"</b> todos los conocimientos, entrenamientos y la calificación final de el o los programas de entrenamiento indicados en la presente cláusula, cuidando la obtención
        del nivel exigido para la impartición de clases en los establecimientos licenciados y éste cumplió con la totalidad de los requisitos exigidos durante los módulos de entrenamientos. Por consecuencia <b><b>"la empresa"</b></b> corrobora que <b>"el instructor"</b>        se encuentra totalmente certificado en los siguientes programas de entrenamiento LES MILLS®:
        <ol type="1">
            <li *ngFor="let p of usuario['programas']">{{ p['nombre'] }}</li>
        </ol>

        <p>
            <u>CUARTO:</u> <b><b>"la empresa"</b></b> confiere a "el instructor", una autorización restringida, no exclusiva e intransferible, para usar el o los programas indicados en el ordinal anterior durante la vigencia del presente contrato, los
            cuales sólo podrán impartirse en los gimnasios que <b><b>"la empresa"</b></b> señale y que se encuentran publicados en la página web www.lesmills.cl.
        </p>

        <p>
            <u>QUINTO:</u> La emisión del correspondiente CERTIFICADO emitido por BODY SYSTEMS CHILE S.A. y reconocido por "LES MILLS INTERNATIONAL LTD." y sus agentes en todo el mundo se encuentra condicionada a la aprobación por parte de <b>"el instructor"</b>            sobre el presente contrato.
        </p>

        <u>SEXTO:</u> Se elevan a cláusulas esenciales de este contrato las siguientes:
        <ol type="1">
            <li>Queda prohibido a <b>"el instructor"</b> impartir los programas en conferencias, reuniones públicas o privadas, fiestas, clases particulares, entre otras, si no es respaldada con una autorización previa y por escrito de <b>"la empresa"</b>.
            </li>
            <li>Queda prohibido al <b>"el instructor"</b> trabajar con los programas mientras no reúna los requisitos para su certificación previa. </li>
            <li><b>"el instructor"</b> se obliga a dar aviso a <b><b>"la empresa"</b></b> en caso de que terceras personas ajenas a este contrato hagan uso de los programas o bien a impedir el uso de los mismos en sitios o por personas no autorizadas por
                <b>"la empresa"</b>.
            </li>
            <li><b>"el instructor"</b> únicamente podrá utilizar los programas en un gimnasio que se encuentre pagando una licencia, por ende, queda estrictamente prohibido cobrar a título personal por clases dentro o fuera de las instalaciones del mismo,
                sin autorización previa de <b>"la empresa"</b>. </li>
        </ol>

        <u>SÉPTIMO:</u> En el evento que se produzca una infracción por el "instructor" a cualquiera de las indicaciones de la cláusula anterior, <b><b>"la empresa"</b></b> podrá revocar la autorización otorgada, tanto en forma temporaria como en forma
        definitiva, y con respecto a alguno o a todos los programas. En los mismos términos, se podrá proceder, por las siguientes causas.
        <ol type="1">
            <li>Cuando <b>"el instructor"</b> se desvincule de <b>"la empresa"</b>. Esta revocación operará al día siguiente de la desvinculación. </li>
            <li>Cuando <b>"el instructor"</b> no imparta los programas conforme a los lineamientos que le fueron enseñados. </li>
            <li>Cuando <b>"el instructor"</b> imparta formas de acondicionamiento propios, haciéndolos pasar como si fueran los programas que le fueron impartidos por <b>"la empresa"</b>. </li>
            <li>Cuando <b>"el instructor"</b> no emplee o utilice los materiales otorgados por <b><b>"la empresa"</b></b> para la impartición de sus clases. </li>
            <li>Cuando <b>"el instructor"</b> haga comentarios o aseveraciones que vayan en descrédito o menoscabo de la efectividad o fines de los programas o de <b>"la empresa"</b>. </li>
            <li>Cuando <b>"el instructor"</b> adquiera copias del material por cualquier medio no autorizado, ya sea por medio de un tercero o cualquier medio electrónico o cibernético sin el permiso o autorización de la empresa o que lo proporcione a personas
                no autorizadas por <b>"la empresa"</b>. Se incluye como infracción al presente número, los casos en que <b>“el instructor”</b> sea sorprendido realizando un programa de entrenamiento o promocionando como habilitado para usar un programa
                de entrenamiento (a través de redes sociales, por la web o a través de cualquier otro medio de prueba) que no haya sido adquirido a través de <b>“la empresa”</b>. En este caso <b>“la empresa”</b> podrá, unilateralmente y sin forma de juicio,
                revocar toda autorización de uso de todos los programas de entrenamiento contratados por <b>“el instructor”</b>, pudiendo dar de baja la aplicación web, la aplicación móvil, los kits digitales (contenido fonográfico y audiovisual) y los
                releases.
            </li>
            <li>Cuando "el instructor", imparta los programas en lugares diferentes a los Gimnasios Licenciados por <b><b>"la empresa"</b></b>. </li>
            <li>Cuando "el instructor", comparta su material para fines no autorizados por <b>"la empresa"</b>.</li>
        </ol>
        La revocación de la autorización se puede dar de dos formas:
        <ol type="a">
            <li>Puede tener un límite temporal de hasta dieciocho (18) meses, y transcurrido dicho plazo, podrá <b>"el instructor"</b> emplear los programas que considere pertinentes, previa aprobación por escrito por parte de <b>"la empresa"</b>. </li>
            <li>Puede ser definitiva: </li>
        </ol>
        <p>
            La revocación de la autorización definitiva operará en los casos de las infracciones el número 4 de la cláusula sexta y 1, 3, 5, 6, 7 y 8 de la presente cláusula.
        </p>
        <p>
            Para los efectos de la revocación de la licencia <b>"el instructor"</b> fija el correo electrónico <b>{{usuario['email']}}</b>, entendiéndose notificado a contar de la remisión de la misma.
        </p>
        <p>
            En el evento que "el instructor", sea sancionado por <b><b>"la empresa"</b></b> con la revocación definitiva y luego de habérsele notificado al correo electrónico fijado por él, <b>"la empresa"</b>, no estará obligada a capacitarlo por los
            programas contratados con anterioridad a dicha sanción, ni con otros que pretenda contratar en el futuro, como tampoco estará obligada a prestar cualquier otra prestación que se requiera.
        </p>
        <p>
            <u>OCTAVO:</u> La ACTUALIZACIÓN es la AUTORIZACIÓN RESTRINGIDA que BODY SYSTEMS CHILE S.A. otorga a <b>"el instructor"</b> para dictar el programa de entrenamiento en el que fue capacitado. La ACTUALIZACIÓN tiene un costo que lo determina
            <b>"la empresa"</b>, se realiza trimestralmente.
        </p>
        <p>
            El proceso de actualización se realiza a través de la página web; https://www.lesmills.cl/login sitio en donde el <b>"el instructor"</b> posee una cuenta a la que sólo podrá acceder a través de su ID de usuario: <b>{{usuario['email']}}</b>.
        </p>

        Al momento de actualizarse, <b>"el instructor"</b> recibirá un material digital de estudio denominado "Release", correspondiente al nuevo mix del programa que actualizó, además de todo el material audiovisual y/o gráfico que LES MILLS INTERNATIONAL
        LTD haya habilitado para <b>"el instructor"</b> a través de BODY SYSTEMS CHILE S.A. Dicho Release será activado única y exclusivamente en las siguientes cuentas digitales que posee "el instructor":

        <ul>
            <li>Página Web: <a target="_blank" href="https://lesmills.secure.force.com/digital/es_default_login">https://lesmills.secure.force.com/digital/es_default_login</a></li>
            <li>App para dispositivos móbiles: <b>Les Mills Releases</b> </li>
        </ul>
        En ambas plataformas <b>"el instructor"</b> sólo podrá acceder a través de su ID de usuario: <b>{{usuario['email']}}</b>.

        <p>
            Se deja constancia que <b>"el instructor"</b> no está obligado a renovar los programas de entrenamiento, pero en caso de hacer uso de un programa de entrenamiento que no haya sido adquirido desde <b>“la empresa”</b>, estará sujeto a lo dispuesto
            en el número 6 de la cláusula Sexta.
        </p>

        <p>
            <u>NOVENO:</u> En caso que <b>"el instructor"</b> necesite modificar su correo electrónico, podrá hacerlo redactando un mail con la solicitud a instructor@bodysystems.cl. En dicho caso la empresa se fija un plazo máximo de 15 días hábiles,
            para realizar el cambio de correo en las diferentes cuentas y plataformas señaladas en el presente contrato. <br> Una vez realizado el cambio de correo, <b><b>"la empresa"</b></b> enviará un mail de; confirmación de cambio correo a la nueva
            dirección indicada por
            <b>"el instructor"</b> que a su vez, será establecida para efectuar todo tipo de notificaciones futuras.
        </p>

        <p>
            <u>DÉCIMO:</u> Los derechos y obligaciones derivadas de este contrato y a cargo de las partes no podrán ser cedidos, transferidos, negociados o afectados en manera alguna por cualquiera de las partes, sin el consentimiento expreso y por escrito
            de la otra. Asimismo,
            <b>"el instructor"</b> no podrá comunicar o ventilar a terceros ningún tipo de información no publicada o de carácter confidencial de que haya tenido conocimiento, con motivo de la ejecución de sus obligaciones emanadas del presente contrato,
            salvo que <b><b>"la empresa"</b></b> lo hubiera expresamente autorizado por escrito. Esta obligación de reserva o confidencialidad seguirá en vigencia aún después del vencimiento del plazo contractual o eventual resolución o revocación del
            presente contrato, haciéndose responsable <b>"el instructor"</b> de los daños y perjuicios que pudiera ocasionar la difusión de datos o informes no publicitados. Será causa de resolución instantánea del presente contrato sin responsabilidad
            para "la empresa", sí <b>"el instructor"</b> llegare a violar dicho principio de confidencialidad, siendo responsable de cualquier consecuencia de tal violación que se contempla en esta cláusula y de cualquier responsabilidad de carácter,
            civil, penal o mercantil.
        </p>

        <p>
            <u>DÉCIMO PRIMERO:</u> Las partes señalan como su domicilio para los efectos legales del presente contrato, aquel fijado en este contrato.
        </p>

        <p>
            <u>DÉCIMO SEGUNDO:</u> El presente contrato contiene todos los términos, declaraciones y garantías vigentes acordadas entre las partes y, por ende, sustituye o reemplaza todas las discusiones y acuerdos previos que se refieren a las materias
            aquí tratadas, debiendo considerarse para su aplicación y/o interpretación como única vinculación contractual existente entre <b>"el instructor"</b> y <b>"la empresa"</b>.
        </p>

        <p>
            <u>DÉCIMO TERCERO:</u> El plazo del presente contrato será por tiempo indefinido, salvo que una de las partes notifique a la otra su deseo de darlo por terminado con 30 (treinta) días de anticipación a la terminación deseada.
        </p>

        <p>
            <u>DÉCIMO CUARTO:</u> Para el caso de controversias legales, las partes vienen expresamente en prorrogar la competencia a los Tribunales Ordinarios de Justicia de Concepción, Chile.
        </p>



    </div>
</div>
<div mat-dialog-actions align="start">
    <mat-checkbox (change)="cargando = !$event.checked" color="accent">
        He leído y acepto el Contrato de Acuerdo de Instructor
    </mat-checkbox>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-raised-button (click)="aceptarAcuerdoInstructor()" color="primary" [disabled]="cargando">Aceptar Acuerdo de Instructor</button>
</div>