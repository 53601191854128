import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const EXPIRES = 'token-expires';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }


  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    let hoy = new Date();
    hoy.setSeconds( 3600 * 2);
    window.sessionStorage.removeItem(EXPIRES);
    window.sessionStorage.setItem(EXPIRES, hoy.getTime().toString());
  }

  public checkToken(): boolean {
    if (!sessionStorage.getItem(TOKEN_KEY)){
      return false;
    }
    const token = sessionStorage.getItem(TOKEN_KEY);

    if (token.length < 2){
      return false;
    }

    const expira = Number(sessionStorage.getItem(EXPIRES));
    const expiraFecha = new Date();

    expiraFecha.setTime(expira);

    if (expiraFecha > new Date()) {
      return true;
    } else {
      return false;
    }
  }

  public getToken(): string{
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
}