<section class="wrd-block" style="background-color: #000">
    <div class="container-xl">
        <img class="img-fluid mt-3" src="/assets/images/instructors/title-home-white.png">
        <div class="row justify-content-center align-items-center">
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/instructors/col-1-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/instructors/col-2-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/instructors/col-3-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/instructors/col-4-2.png">
            </div>
        </div>
    </div>
</section>
<section id="certificaciones" class="main wrd-block p-0 main-gallery">
    <div class="container text-dark pt-5 pb-5">
        <div class="row">

            <div class="col-12">
                <div id="pagoRechazado" *ngIf="pagoRechazado" class="alert alert-danger">
                    Tu pago fue rechazado, intentalo nuevamente.
                </div>
            </div>
            <div class="col-md-6">
                <h1 class="ml-3">Próximas Certificaciones</h1>
            </div>
            <div class="col-md-6">
                <div class="row ">
                    <div class="col-sm-6">
                        <mat-form-field appearance="fill" class="float-md-right ml-3 mr-3 w-100">
                            <mat-label>
                                Programa
                                <mat-spinner class="progress-spinner-inline" *ngIf="loading" class="float-right" diameter="20"></mat-spinner>
                            </mat-label>
                            <mat-select [disabled]="loading" (selectionChange)="filtrar($event.value, 'programa')">
                                <mat-option value="TODOS">TODOS</mat-option>
                                <mat-option *ngFor="let programa of programas" value="{{ programa }}">{{ programa }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field appearance="fill" class="float-md-right ml-3 mr-3 w-100">
                            <mat-label>
                                Tipo de Certificación
                                <mat-spinner class="progress-spinner-inline" *ngIf="loading" class="float-right" diameter="20"></mat-spinner>
                            </mat-label>
                            <mat-select [disabled]="loading" (selectionChange)="filtrar($event.value, 'tipo')">
                                <mat-option value="TODOS">TODOS</mat-option>
                                <mat-option *ngFor="let tipo of tiposCertificacion" value="{{ tipo }}">{{ tipo | uppercase }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="!certs" class="col-12">
                <mat-spinner color="accent" class="mx-auto mt-4"></mat-spinner>
            </div>
            <div *ngIf="certs" class="col-12 cert-desktop">
                <table *ngIf="certs" datatable [dtOptions]="dtOpciones" class="table bg-white table-striped w-100 text-center table-border">
                    <thead>
                        <tr>
                            <th class="d-none">Orden</th>
                            <th>Programa</th>
                            <th>Tipo</th>
                            <th>Fechas</th>
                            <th>Precio</th>
                            <!-- <th>Licencias</th> -->
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let cert of certsFiltros">
                            <tr>
                                <td class="d-none">
                                    {{cert.fechas[0]['fecha_inicio'] | date:"yyyyMMdd"}}
                                </td>
                                <td>
                                    <h4>{{ cert.programa_nombre }} {{ cert.nombre_rango | uppercase }}</h4>
                                </td>
                                <td>{{ cert.nombre_tipo | uppercase }}</td>
                                <td>{{ cert.fechas | fechasCert }}</td>
                                <td>{{ cert.precio | currency:' ': 'symbol-narrow': '0.0-5': 'es' }}</td>
                                <!-- <td>{{ licenciaActual.periodo }}-{{ licenciaActual.ano }} y {{ licenciaFutura.periodo }}-{{ licenciaFutura.ano }}</td> -->
                                <td>
                                    <button *ngIf="!logeado" class="btn btn-primary rounded-0 " type="submit" (click)="abrirPopup();">COMPRAR</button>
                                    <button *ngIf="logeado" class="btn btn-primary btn-block rounded-0" type="submit" [routerLink]="['/certificaciones/check', cert.id_certificacion]">COMPRAR</button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div *ngIf="certs" class="col-12 cert-mobile">
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item" *ngFor="let cert of certsFiltros">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row h-100">
                                    <div class="col-8 my-auto">
                                        <h4>{{ cert.programa_nombre }} {{ cert.nombre_rango | uppercase }}</h4>
                                    </div>
                                    <div class="col-4 text-right">
                                        <h4>${{ cert.precio | currency:' ': 'symbol-narrow': '0.0-5': 'es' }}</h4>
                                    </div>
                                    <div class="col-12 my-auto">
                                        {{ cert.nombre_tipo | uppercase }}
                                    </div>
                                    <div class="col-8 my-auto">
                                        {{ cert.fechas | fechasCert }}
                                    </div>
                                    <div class="col-4 text-right">
                                        <button *ngIf="!logeado" class="btn btn-primary rounded-0 " (click)="abrirPopup();">COMPRAR</button>
                                        <button *ngIf="logeado" class="btn btn-primary rounded-0 " type="submit" [routerLink]="['/certificaciones/check', cert.id_certificacion]">COMPRAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>