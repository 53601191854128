<section class="wrd-block" style="background-color: #333333">
    <div class="container-xl">
        <img class="img-fluid mt-5 pt-5" src="/assets/images/instructors/title-home-white.png">
        <div class="row justify-content-center align-items-center">
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/instructors/col-1-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/instructors/col-2-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/instructors/col-3-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/instructors/col-4-2.png">
            </div>
        </div>
    </div>
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm  ancho-70 ">
        <div style="border-bottom: 1px solid #333" class="row pb-3">
            <div class="col-lg-4">
                <img style="max-width: 100%;" src="/assets/images/instructors/iconos2-12.png">
            </div>
            <div class="col-lg-8 my-auto text-center-mobile">
                <h1 class="display-5 title-tab-gim">REVISA NUESTRO CALENDARIO E INSCRÍBETE</h1>
                <p class="h5 desc-tab-gim mt-3">
                    En CERTIFICACIONES, encontrarás los módulos de capacitación Les Mills disponibles Elige tu programa e inscríbete a través de Webpay.<br> El plazo de inscripción finaliza 3 días antes del inicio de la capacitación.<br> Es requisito
                    vivir en Chile.
                </p>
            </div>

        </div>
        <div style="border-bottom: 1px solid #333" class="row pb-3">
            <div class="col-lg-4">
                <img style="max-width: 100%;" src="/assets/images/instructors/iconos2-13.png">
            </div>
            <div class="col-lg-8 my-auto text-center-mobile">
                <h1 class="display-5 title-tab-gim">DESCARGA LOS MATERIALES DE ESTUDIO</h1>
                <p class="h5 desc-tab-gim mt-3">
                    Una vez inscrito, te contactaremos vía teléfono y mail para entregarte todo el material necesario para tu capacitación.<br><br> Para módulos online es necesario tener computador o tablet, smartphone y conexión estable a internet.<br>
                </p>
            </div>

        </div>
        <div style="border-bottom: 1px solid #333" class="row pt-3 pb-3">
            <div class="col-lg-4">
                <img style="max-width: 100%;" src="/assets/images/instructors/iconos2-14.png">
            </div>
            <div class="col-lg-8 my-auto text-center-mobile">
                <h1 class="display-5 title-tab-gim">CERTIFICACIÓN INICIAL</h1>
                <p class="h5 desc-tab-gim mt-3">
                    Para Certificaciones Presenciales y Online:<br>
                    <p class="h5 desc-tab-gim ml-3">
                        Entrenamiento y capacitación de 2 días -9 horas por día-.<br> Trabajo teórico y práctico.<br> Se exige un 100% de asistencia, sin excepción.<br> En caso de no asistir o finalizar el proceso correctamente, podrás repetir el módulo
                        pagando $50.000.<br>
                    </p>
            </div>

        </div>
        <div class="row pt-3">
            <div class="col-lg-4">
                <img style="max-width: 100%;" src="/assets/images/instructors/iconos2-15.png">
            </div>
            <div class="col-lg-8 my-auto text-center-mobile">
                <h1 class="display-5 title-tab-gim">GRABA TU CLASE Y CERTIFÍCATE</h1>
                <p class="h5 desc-tab-gim mt-3">
                    Finalizada la certificación y dependiendo de tu evaluación, tendrás 45 días para grabar un video de la clase.<br> Nuestros entrenadores evaluarán tu video y definirán si estás certificado. De lo contrario, tendrás que grabar un nuevo
                    video.
                    <br> Una vez certificado estarás autorizado para dictar el programa de Les Mills en cualquier centro licenciado del mundo.<br>
                </p>
            </div>

        </div>
    </div>
</section>