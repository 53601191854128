import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidadoresService {

  constructor(
    private http: HttpClient,
  ) {
  }
  
  rutExiste(): ValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const isUnique$ = this.http.post(environment.api_url + 'auth/checkRut', {
        rut: control.value ,
      }); //Fake request for now
      return isUnique$.pipe(
        map(
          (data) => {
            return data['data'] ? { rutExiste: true } : null;
          }
        )
      );
    };
  }

  correoExiste(): ValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const isUnique$ = this.http.post(environment.api_url + 'auth/checkMail', {
        email: control.value ,
      }); //Fake request for now
      return isUnique$.pipe(
        map(
          (data) => {
            return data['data'] ? { correoExiste: true } : null;
          }
        )
      );
    };
  }

}
