import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificacionesService } from '../../../services/certificaciones.service';
import { Certificaciones } from '../../../models/certificaciones.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RegionesService } from '../../../services/regiones.service';
import { RutValidator } from 'ng9-rut';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenStorageService } from '../../../services/token-storage.service';
import { UserService } from '../../../services/user.service';
import { ValidadoresService } from '../../../services/validadores.service';
import { LicenciasService } from '../../../services/licencias.service';
import { Licencias } from '../../../models/licencias.model';
import { ProgramasService } from 'src/app/services/programas.service';
import { GimnasioService } from 'src/app/services/gimnasios.service';

@Component({
  selector: 'app-comprar',
  templateUrl: './comprar.component.html',
  styleUrls: ['./comprar.component.scss']
})
export class ComprarComponent implements OnInit {
  
  idCertificacion: number;
  correo: string;
  cert: Certificaciones;
  compraForm: FormGroup;
  region: any[];
  comuna: any[];
  loadingR = true;
  loadingP = true;
  loadingPS = false;
  loadingC = true;
  loadingCS = false;
  cargando = false;
  cuponDescuento: string;
  loadingCupon = false;
  errorCupon: string;
  cuponExito: string;
  descuento = 0;
  errorCheckbox = false;
  terminos = false;
  usuario: any[];
  certificacionesUsuario: any[];
  licenciaFull = false;
  licenciaActiva = false;
  licenciaGimnasio = false;
  usuarioNuevo = false;
  cargandoLicencia = true;
  yaTienePrograma = false;
  yaTieneCert = false;
  muchasCertPendientes = false;
  certMismoDia = false;

  constructor(
    private route: ActivatedRoute,
    private certificacion: CertificacionesService,
    private fb: FormBuilder,
    private regiones: RegionesService,
    private rutValidator: RutValidator,
    private validadores: ValidadoresService,
    public dialog: MatDialog,
    private tokenStorage: TokenStorageService,
    private user: UserService, 
    private licencias: LicenciasService,
    private router: Router,
    private programas: ProgramasService,
    private gim: GimnasioService
  ) {
    this.idCertificacion = Number(this.route.snapshot.paramMap.get('idCertificacion'));
    this.correo = atob(this.route.snapshot.paramMap.get('correo'));



    this.certificacion.getCertificacion(this.idCertificacion).subscribe(
      data => {
        this.cert = data.data;
        if (this.tokenStorage.checkToken()) {
          this.programas.getProgramasByUser().subscribe(
            data => {
              if(data.data['programasActual']){
                data.data['programasActual'].forEach(prog => {
                  if(prog['nombre'] == this.cert.programa_nombre){
                    this.yaTienePrograma = true;
                  }
                });
              }
            }
          );

          this.certificacion.getCertificacionesByUser().subscribe(
            data => {
              if(data.data){
                let certPendientes = 0;
                console.log(data.data);
                data.data.forEach(cert => {
                  if(cert['programa_nombre'] == this.cert.programa_nombre){
                    this.yaTieneCert = true;
                  }
                  if(cert['id_estado_usuario'] != 9){ //SI NO SE A CERTIFICADO LA CERTIFICACIÓN ESTA PENTIENTE
                    certPendientes++;
                  }

                  cert['fechas'].forEach(fecha => {
                    if(fecha['tipo'] != 1){ //Si es diferente a bienvenida
                      this.cert.fechas.forEach(fCert =>{
                        let fechaCertUsuario = new Date(fecha['fecha_inicio']);
                        let fechaFormatUsuario = fechaCertUsuario.getFullYear() + "-" + fechaCertUsuario.getMonth() + "-" + fechaCertUsuario.getDate();


                        let fechaCertificacion = new Date(fCert['fecha_inicio']);
                        let fechaFormatCertificacion = fechaCertificacion.getFullYear() + "-" + fechaCertificacion.getMonth() + "-" + fechaCertificacion.getDate();
        
                        if(fechaFormatUsuario == fechaFormatCertificacion){
                          this.certMismoDia = true;
                        }
                      })
                    }
                  });
                })
                if(certPendientes >= 2 && this.licenciaFull){
                  this.muchasCertPendientes = true;
                }
              }
            }
          );

    
          this.licencias.checkLicenciaFull().subscribe(
            data => {
              if(data.data){
                this.licenciaFull = true;
                this.cert.precio = 0;
              }
            }
          );
    
          this.user.get_user().subscribe(
            data => {
              // if(data['usuario_nuevo'] == 1){
              //   this.usuarioNuevo = true;
              // }
    
              this.licencias.checkLicencia().subscribe(
                data => {
                  this.licenciaActiva = data.data['activa'];
                  this.cargandoLicencia = false;
                  if(this.licenciaActiva){
                    this.usuarioNuevo = false;
                  }else{
                    this.gim.getUsuarioGimnasioLicenciado().subscribe(
                      data => {
                        if(data.data['programas']){
                          data.data['programas'].forEach(p => {
                            if(p['nombre'] == this.cert.programa_nombre){
                              this.licenciaGimnasio = true;
                            }
                          });
                        }

                        if(this.licenciaGimnasio){
                          this.licenciaActiva = true;
                        }
                      }
                    );
                  }
                }
              );
            }
          );
        }
        // }else{
        //   this.usuarioNuevo = true;
        // }
      }
    );

    this.regiones.getRegiones().subscribe(
      data => {
        this.region = data.data;
        this.loadingR = false;
        
        if (this.tokenStorage.checkToken()) {
          this.obtenerComunas(this.usuario['region']);
          this.compraForm.controls['comuna'].setValue(this.usuario['comuna']);
        }
      }
    );
  }

  ngOnInit(): void {
    
    if (this.tokenStorage.checkToken()) {
      this.usuario = this.user.usuario;

      this.compraForm = this.fb.group({
          email   : [{value: this.usuario['email'], disabled: true}, [Validators.required, Validators.email]],
          nombres   : [this.usuario['name'], [Validators.required]],
          apellidos   : [this.usuario['apellidos'], [Validators.required]],
          region   : [this.usuario['region'], [Validators.required]],
          comuna   : [this.usuario['comuna'], [Validators.required]],
          direccion   : [this.usuario['direccion'], [Validators.required]],
          terminos   : ['', [Validators.required]],            
          rut   : [{value: this.usuario['rut'], disabled: true}, [Validators.required, this.rutValidator]],
          telefono   : [this.usuario['telefono'], [Validators.required]],
          cupon: ['']
      });
      this.compraForm.controls['region'].setValue(this.usuario['region']);
    }else{
      if(this.correo){
        this.compraForm = this.fb.group({
            email   : [{value: this.correo, disabled: true}, [Validators.required, Validators.email]],
            nombres   : ['', [Validators.required]],
            apellidos   : ['', [Validators.required]],
            region   : ['', [Validators.required]],
            comuna   : ['', [Validators.required]],
            direccion   : ['', [Validators.required]],
            terminos   : ['', [Validators.required]],
            rut   : ['', [Validators.required, this.rutValidator], this.validadores.rutExiste()],
            telefono   : ['', [Validators.required]],
            cupon: ['']
        });
      }
    }
  }


  obtenerComunas(value): void{
    this.compraForm.controls['comuna'].setValue('', {onlySelf: true});
    this.loadingCS = true;
    this.loadingC = true;
    this.regiones.getComunasByRegion(value).subscribe(
      data => {
        this.comuna = data.data;
        this.loadingC = false;
        this.loadingCS = false;
      }
    );
  }

  onSubmit():void{
    console.log('ok');
  }

  checkCupon():void{
    this.loadingCupon = true;
    this.cuponDescuento = this.compraForm.get('cupon').value,
    this.certificacion.checkCupon(this.cuponDescuento).subscribe(
      data => {
        if(data.data.monto_descuento){
          this.descuento = data.data.monto_descuento;
        }else if(data.data.porcentaje){
          this.descuento = (this.cert.precio / 100) * data.data.porcentaje;
        }else{
          this.descuento = 0;
        }
        this.errorCupon = '';
        this.cuponExito = data.message;
        this.loadingCupon = false;
      },
      err => {
        this.descuento = 0;
        this.cuponExito = '';
        this.errorCupon = err.error.message;
        this.loadingCupon = false;
      }
    );
  }

  terminosPress(event): void{
    this.terminos = event.checked;
}

  abrirTerminosCondiciones(): void{
    if(!this.terminos){
      this.errorCheckbox = true;
    }else{
      this.errorCheckbox = false;
    }

    if(this.compraForm.valid && this.terminos){
      this.dialog.open(TyCCertificacionesDialog, {
        width: '800px',
        data: {
          form: this.compraForm, 
          certificacion: this.cert, 
          cupon: this.cuponDescuento,
          licenciaFull: this.licenciaFull,
          comunas: this.comuna,
          regiones: this.region,
          precioDescuento: this.cert.precio - this.descuento,
          usuarioNuevo: this.usuarioNuevo
        },
        autoFocus: false,
      });
    }
  }

  abrirPolitica(): void{
    this.dialog.open(TyCRegistroDialog, {
      width: '800px',
    });
  }
}

@Component({
  selector: 'terminos-condiciones',
  templateUrl: '../../terminos_condiciones/certificacion.html',
})
export class TyCCertificacionesDialog {

  formulario: FormGroup;
  cupon: string;
  comunas:string;
  regiones:string;
  licenciaFull:boolean;
  precioDescuento:number;
  usuarioNuevo:boolean;
  cert: Certificaciones;
  fechaHoy = new Date();
  cargando = true;

  constructor(
    public dialogRef: MatDialogRef<TyCCertificacionesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private certificacion: CertificacionesService,
    private tokenStorage: TokenStorageService,
    private user: UserService, 
    public dialog: MatDialog,
    private licencia: LicenciasService,
  ) {
    this.formulario = data.form;
    this.cupon = data.cupon;
    this.cert = data.certificacion;
    this.licenciaFull = data.licenciaFull;
    this.usuarioNuevo = data.usuarioNuevo;
    this.precioDescuento = data.precioDescuento;

    data.comunas.forEach(element => {
      if(element.id === this.formulario.get('comuna').value){
          this.comunas = element.nombre;
      }
    });

    data.regiones.forEach(element => {
      if(element.id === this.formulario.get('region').value){
          this.regiones = element.nombre;
      }
    });
    
  }
  

  comprarCertificacion(): void{
    this.cargando = true;
    const datos = {
      correo: this.formulario.get('email').value,
      nombres: this.formulario.get('nombres').value,
      apellidos: this.formulario.get('apellidos').value,
      comuna: this.formulario.get('comuna').value,
      region: this.formulario.get('region').value,
      direccion: this.formulario.get('direccion').value,
      rut: this.formulario.get('rut').value,
      telefono: this.formulario.get('telefono').value,
      cupon: this.cupon,
      licenciaFull: this.licenciaFull,
      certificacion: this.cert.id_certificacion
    }
    this.certificacion.comprarCertificacion(datos).subscribe(
      data => {
        if(this.licenciaFull){
          window.location.href = data.data;
        }else{
          if(data.message.user){
            this.user.almacenarUsuario(data.message.user.user);
            this.tokenStorage.saveToken(data.message.user.access_token);
            this.tokenStorage.saveUser(data.message.user);
            this.user.getUsuario();
          }
          const myform = document.createElement('form');
          myform.method = 'POST';
          myform.action = data.message.url;
          myform.style.display = 'none';
  
          const my_tb = document.createElement('input');
          my_tb.type = 'TEXT';
          my_tb.name = 'token_ws';
          my_tb.value = data.message.token;
  
          myform.appendChild(my_tb);
          document.body.appendChild(myform);
          myform.submit();
        }
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'terminos-condiciones',  
  templateUrl: '../../terminos_condiciones/registro.html',

})
export class TyCRegistroDialog {

  constructor(
    public dialogRef: MatDialogRef<TyCRegistroDialog>,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  

}
