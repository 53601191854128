<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-rp.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-rp">¿QUÉ ES RPM?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">RPM es una clase de ciclismo indoor en la que los alumnos pedalean al ritmo de música. Tiene música para todos los gustos por lo que la hace muy motivante. Pedaleamos para llevar al pelotón a través de terrenos llanos, colinas, y montañas,
                    descensos y contrarreloj para que se sientan unos verdaderos ciclistas. Los participantes sudan, queman calorías y liberan una gran cantidad de endorfinas.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-rp">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">FITNESS</h3>
                        <h1 class="p-0 m-0 title-color-rp"><strong>CARDIO</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">QUEMA</h3>
                        <h1 class="p-0 m-0 title-color-rp"><strong>CALORIAS</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">CUERPO</h3>
                        <h1 class="p-0 m-0 title-color-rp"><strong>DEFINIDO</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON RPM?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify">Los instructores te indicarán cómo ajustar tu bicicleta y cómo adaptar tu entrenamiento de RPM a tu nivel de fitness.</p>
                <ul class="desc-programs">
                    <li>El primer día realiza cuatro o cinco tracks y progresa hasta realizar una clase completa.</li>
                </ul>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-rp">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Trabajo cardiovascular sobre la bicicleta</li>
                            <li>Tonifica y define piernas, caderas y glúteos</li>
                            <li>Utiliza diferentes fuentes energéticas</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <p class="desc-programs text-center">
                            Bicicleta indoor
                        </p>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>675</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            60, 45 ó 30 minutos
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-rp.mp4" type="video/mp4">
     </video>
    </div>

</section>