<div class="logo-repeat-bg">
    <div id="licencias" class="container mt-5 mb-5">
        <div class="row mt-5">
            <div class="col-10 mx-auto text-center pb-3">
                <h3 class="card-title">Elige tu Licencia Instructor Les Mills</h3>
                <h4>Aprovecha todas las herramientas que tenemos disponibles para ti</h4>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-10 mx-auto">
                <mat-spinner class="mx-auto mt-5"></mat-spinner>
            </div>
        </div>


        <div *ngIf="!loading" class="row">
            <div class="col-lg-6 mx-auto">
                <div *ngIf="errorUsuarioNuevo" class="alert alert-danger text-center">
                    Para comprar una licencia básica debes comprar una certificación + licencia, lo puedes hacer en el siguiente <a routerLink="/certificaciones">LINK</a>.
                </div>
                <!-- <div *ngIf="!licenciaActual" class="alert alert-warning text-center">
                    No tienes licencia o a expirado, para poder pagar tus actualizaciones y obtener el contenido exclusivo te invitamos a seleccionar una licencia a continuación.
                </div> -->
                <div *ngIf="resultadoPago && resultadoPago === 'pagoExitoso'" class="alert alert-success text-center">
                    Haz realizado el pago de tu licencia exitosamente.
                </div>
                <div *ngIf="resultadoPago && resultadoPago === 'errorPago'" class="alert alert-danger text-center">
                    El pago por tu licencia no pudo ser completado, intentalo nuevamente.
                </div>
                <div *ngIf="licenciaActual && errorLicencia" class="alert alert-info text-center">
                    Ya tienes una licencia activa con vigencia hasta el <b>{{licenciaActual['fechaVencimiento'] | date: 'dd-MM-y'}}</b>.
                </div>
            </div>
            <div class="col-10 mx-auto">

                <header class="pricing-table-header">
                    <div class="container mx-auto text-center">

                        <div class="nav price-tabs" role="tablist">
                            <a class="nav-link active" href="#instructores-certificados" id="instructores-certificados-tab" data-toggle="list" href="#instructores-certificados" role="tab" aria-controls="instructores-certificados" aria-selected="false">Licencia Básica</a>
                            <a class="nav-link" href="#instructores-nuevos-tab" id="instructores-nuevos-tab" data-toggle="list" href="#instructores-nuevos" role="tab" aria-controls="instructores-nuevos" aria-selected="true">Licencia Pro</a>
                        </div>
                    </div>
                </header>
                <div class="container">
                    <div class="tab-content pricing-tab-content" id="pills-tabContent">
                        <div class="tab-pane active show" id="instructores-certificados" role="tabpanel" aria-labelledby="instructores-certificados-tab">
                            <div class="row">
                                <div class="col-md-5 mx-auto">
                                    <div class="card pricing-card text-left shadow border-0">
                                        <div class="card-header">
                                            <h3 class="pricing-plan-title">{{ licencias[0].nombre_mostrar }}</h3>
                                            <h5 class="pricing-plan-cost">${{ licencias[0].precio_trimestral | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} + IVA / Trimestral</h5>
                                        </div>
                                        <div class="card-body">
                                            <ul class="pricing-plan-features">
                                                <li>+ Contenidos digitales</li>
                                                <li>+ Webinars y Groundworks</li>
                                                <li>+ Les Mills App</li>
                                                <li>+ Perfil de Instructor</li>
                                            </ul>
                                        </div>
                                        <div class="card-footer">
                                            <btn class="btn btn-primary btn-block" (click)="comprarLicencia(licencias[0].id, 'trimestral')">SELECCIONAR</btn>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 mx-auto">
                                    <div class="card pricing-card text-left shadow border-0">
                                        <div class="card-header">
                                            <h3 class="pricing-plan-title">{{ licencias[0].nombre_mostrar }}</h3>
                                            <h5 class="pricing-plan-cost">${{ licencias[0].precio_semestral | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} + IVA / Semestral</h5>
                                            <h5 class="text-success pricing-plan-descuento">Hasta 33% Dscto</h5>
                                        </div>
                                        <div class="card-body">
                                            <ul class="pricing-plan-features">
                                                <!-- <li>+ 25% Descto en 1 Certificación</li> -->
                                                <li>+ Contenidos digitales</li>
                                                <li>+ Webinars y Groundworks</li>
                                                <li>+ Les Mills App</li>
                                                <li>+ Perfil de Instructor</li>
                                            </ul>
                                        </div>
                                        <div class="card-footer">
                                            <btn class="btn btn-primary btn-block" (click)="comprarLicencia(licencias[0].id, 'semestral')">SELECCIONAR</btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="instructores-nuevos" role="tabpanel" aria-labelledby="instructores-nuevos-tab">
                            <div class="row">
                                <div class="col-md-6 mx-auto">
                                    <div class="card pricing-card text-left shadow border-0">
                                        <div class="card-header">
                                            <h3 class="pricing-plan-title">{{ licencias[1].nombre_mostrar }}</h3>
                                            <h5 class="pricing-plan-cost">${{ licencias[1].precio | currency:' ': 'symbol-narrow': '0.0-0': 'es' }} + IVA / Anual</h5>
                                            <h5 class="text-success pricing-plan-descuento">Mejor Valor</h5>
                                        </div>
                                        <div class="card-body">
                                            <ul class="pricing-plan-features">
                                                <li>+ Beneficios Licencia Básica</li>
                                                <li class="text-success mas-grande">+ Certificaciones ilimitadas GRATIS</li>
                                                <li>+ Liberación anticipada de releases</li>
                                                <li>+ Contenidos digitales exclusivos</li>
                                            </ul>
                                        </div>
                                        <div class="card-footer">
                                            <btn class="btn btn-primary btn-block" (click)="comprarLicencia(licencias[1].id, 'anual')">SELECCIONAR</btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>