<div id="datos-personales" class="logo-repeat-bg">
    <div class="container main-registro mb-5 pb-3">
        <div class="bg-light shadow-lg rounded-3 overflow-hidden">
            <div class="row">
                <!-- Sidebar-->
                <aside class="col-lg-4 pe-xl-5">
                    <app-panel-menu-left></app-panel-menu-left>
                </aside>
                <!-- Content-->
                <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
                    <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
                        <h2 class="h3 py-2 text-center text-sm-start">Datos Personales</h2>

                        <form name="cambioClaveForm" [formGroup]="cambioClaveForm" (ngSubmit)="onSubmitCC()">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngIf="resultadoCC" class="alert {{tipoAlertCC}}">
                                        {{msjAlertCC}}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Contraseña Anterior</mat-label>
                                        <input matInput type="password" formControlName="password_old">
                                        <mat-icon matSuffix>password</mat-icon>
                                        <mat-error>
                                            La Contraseña Anterior es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Contraseña</mat-label>
                                        <input matInput type="password" formControlName="password">
                                        <mat-icon matSuffix>password</mat-icon>
                                        <mat-error>
                                            La Contraseña es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Confirma Contraseña</mat-label>
                                        <input matInput type="password" formControlName="c_password">
                                        <mat-icon matSuffix>password</mat-icon>
                                        <mat-error>
                                            La Confirmación de Contraseña es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <button *ngIf="!cargandoCC" class="btn btn-lg btn-primary mt-3 float-right" type="submit">GUARDAR</button>
                                    <button *ngIf="cargandoCC" class="btn btn-lg btn-primary mt-3 float-right" type="submit" disabled>
                                        cargandoCC 
                                        <mat-icon>
                                            <mat-spinner class="mt-1 ml-3" color="primary" diameter="20"></mat-spinner>
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>