import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from '../../../services/token-storage.service';
import { UserService } from '../../../services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  Logeado = false;
  ErrorLogeo = false;
  MensajeError = '';
  cargando = false;
  idCertificacion: number;
  correo: string;

  constructor(
    private tokenStorage: TokenStorageService,
    private user: UserService, 
    private route: Router,
    private routerA: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    if(this.routerA.snapshot.paramMap.get('idCertificacion')){
      this.idCertificacion = Number(this.routerA.snapshot.paramMap.get('idCertificacion'));
    }
    if(this.routerA.snapshot.paramMap.get('correo')){
      this.correo = atob(this.routerA.snapshot.paramMap.get('correo'));
    }
  }

  ngOnInit(): void {
    if (this.tokenStorage.checkToken()) {
        this.Logeado = true;
        this.route.navigate(['/panel/datos-personales']); 
    }
    this.loginForm = this.fb.group({
        email   : [this.correo, [Validators.required, Validators.email]],
        password: ['', Validators.required]
    });
  }

  
  onSubmit(): void {
    if(this.loginForm.valid){
      this.cargando = true;
      this.user.login(this.loginForm).subscribe(
          data => {
            if(data.user['acuerdo_instructor'] == 0){ 

              this.user.getDatosAcuerdoInstructor(data.access_token).subscribe(
                dataAI => {
                  this.cargando = false;
                  this.dialog.open(AcuerdoInstructorDialog, {
                    width: '800px',
                    data: {
                      user: dataAI.message, 
                      datos: data,
                      idCertificacion: this.idCertificacion,
                      correo: this.correo,
                      loginForm: this.loginForm
                    },
                    autoFocus: false,
                  });
                }
              )
            }else{
              this.user.almacenarUsuario(data.user);
  
              this.tokenStorage.saveToken(data.access_token);
              this.tokenStorage.saveUser(data);
              this.user.getUsuario();
  
              this.ErrorLogeo = false;
              this.Logeado = true;

              if(this.idCertificacion && this.correo){
                this.route.navigate(['/certificaciones/comprar/', this.idCertificacion, btoa(this.loginForm.get('email').value)]);
              }else{
                if(data.user['usuario_nuevo'] == 1){
                  this.route.navigate(['/panel/datos-personales']); 
                }else{
                  this.route.navigate(['/panel/programas']); 
                }
              }
            }
              
          },
          err => {
              if(err.status === 401){
                  this.MensajeError = 'Correo o Contraseña Inválidos';
              }else if(err.status === 503){
                  this.MensajeError = 'Estamos realizando un mantenimiento en nuestro servicio, intenta en unos minutos'
              }else{
                  this.MensajeError = 'Tenemos problemas para conectarnos al servidor, intenta en unos minutos';
              }
              this.ErrorLogeo = true;
              this.cargando = false;
          }
      );
    }
}

}

@Component({
  selector: 'acuerdo-instructor',
  templateUrl: '../../terminos_condiciones/acuerdo-instructor.html',
})
export class AcuerdoInstructorDialog {

  formulario: FormGroup;
  usuario: any[];
  datos: any[];
  fechaHoy = new Date();
  cargando = true;
  idCertificacion: number;
  correo: string;
  loginForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AcuerdoInstructorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public user: UserService,
    public tokenStorage: TokenStorageService,
    private route: Router,
  ) {
    this.usuario = data.user;
    this.datos = data.datos;
    this.idCertificacion = data.idCertificacion;
    this.correo = data.correo;
    this.loginForm = data.loginForm;
  }
  

  aceptarAcuerdoInstructor(): void{
    this.cargando = true;

    this.user.aceptarAcuerdoInstructor(this.datos['access_token']).subscribe(
      data => {
        if(data.success){
        
          this.user.almacenarUsuario(this.datos['user']);
  
          this.tokenStorage.saveToken(this.datos['access_token']);
          this.tokenStorage.saveUser(this.datos);
          this.user.getUsuario();

          this.dialogRef.close();
          if(this.idCertificacion && this.correo){
            this.route.navigate(['/certificaciones/comprar/', this.idCertificacion, btoa(this.loginForm.get('email').value)]);
          }else{
            this.route.navigate(['/panel/datos-personales']); 
          }
        }
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
