import { Component, OnInit } from '@angular/core';
declare var $:JQueryStatic;

@Component({
  selector: 'app-programas',
  templateUrl: './programas.component.html',
  styleUrls: ['./programas.component.scss']
})
export class ProgramasComponent implements OnInit {
  array_filtro = [];
  cantidad_programas = 5;
  img = Array();

  constructor(
  ) {
    for (let i = 0; i < 20; i++) {
      this.img[i] = 'web';
    }
  }

  ngOnInit(): void {
  }

  changeBackground(nImg, accion): void{
    if(accion === 'in'){
      this.img[nImg] = 'pro';
    }else{
      this.img[nImg] = 'web';
    }
  }

  Checkear(id) {
      id = parseInt(id);
      if (id == 0) {
          $('#check_c_' + id).addClass('active');
          for (var i = 1; i <= this.cantidad_programas; i++) {
              $('.categoria-' + i).show();
              $('#check_c_' + i).removeClass('active');
              this.array_filtro = jQuery.grep(this.array_filtro, function(value) { //ELIMINAR ELEMENTO DE ARRAY JQUERY
                  return value != i;
              });
          }
      } else {
          $('#check_c_0').removeClass('active');
          if ($('#check_c_' + id).hasClass('active')) {
              $('#check_c_' + id).removeClass('active');

              this.array_filtro = jQuery.grep( this.array_filtro, function(value) { //ELIMINAR ELEMENTO DE ARRAY JQUERY
                  return value != id;
              });

          } else {
              $('#check_c_' + id).addClass('active');
              this.array_filtro.push(id);
          }

          if ( this.array_filtro.length != 0) {
              for (var i = 1; i <=  this.cantidad_programas; i++) {
                  if ($.inArray(i,  this.array_filtro) >= 0) {
                      $('.categoria-' + i).show();
                  } else {
                      $('.categoria-' + i).hide();
                  }
              }
          } else {
              for (var i = 1; i <=  this.cantidad_programas; i++) {
                  $('.categoria-' + i).show();
              }
          }
      }
  }
}
