<section class="main wrd-block p-0 main-gallery">
    <div class="container text-dark pt-5 pb-5">
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <mat-spinner class="mx-auto mt-5"></mat-spinner>
            </div>
        </div>
        <div class="row" *ngIf="!loading">
            <div class="col-12 mb-4">
                <h1 id="tituloPerfil" class="ml-3">Perfil de Instructor</h1>
            </div>
            <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                <div class="card card-profile shadow">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                                <img *ngIf="instructor['imagen']" [src]="s3Url+instructor['imagen']" class="rounded-circle">
                                <img *ngIf="!instructor['imagen']" [src]="s3Url+'imgPerfil-1622745740.png'" class="rounded-circle">
                            </div>
                        </div>
                    </div>
                    <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <div class="d-flex justify-content-between">
                        </div>
                    </div>
                    <div class="card-body pt-0 pt-md-4">
                        <div class="row">
                            <div class="col">
                                <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                    <div *ngIf="instructor['correo_perfil']">
                                        <a [href]="'mailto:' + instructor['correo_perfil']" target="_blank">
                                            <i class="far fa-envelope text-white circle-icon"></i>
                                        </a>
                                    </div>
                                    <div *ngIf="instructor['instagram']">
                                        <a [href]="'https://www.instagram.com/' + instructor['instagram']" target="_blank">
                                            <i class="fab fa-instagram text-white circle-icon"></i>
                                        </a>
                                    </div>
                                    <div *ngIf="instructor['facebook']">
                                        <a [href]="'https://www.facebook.com/' + instructor['facebook']" target="_blank">
                                            <i class="fab fa-facebook text-white circle-icon"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <h3>{{ instructor['name'] | titlecase }} {{ instructor['apellidos'] | titlecase }}</h3>
                            <div class="h5 font-weight-300">
                                <i class="fas fa-map-marker-alt mr-2 text-warning"></i>{{ instructor['comunaNombre'] }}, {{instructor['regionNombre']}}
                            </div>
                            <hr *ngIf="instructor['descripcion']" class="my-4">
                            <p *ngIf="instructor['descripcion']">{{ instructor['descripcion'] }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 order-xl-1">
                <div class="card shadow">
                    <div class="card-body">
                        <h4 class="heading-small text-muted mb-4">PROGRAMAS LICENCIADOS</h4>
                        <div class="pl-lg-4">
                            <div *ngIf="instructor['programas'].length > 0" class="row">
                                <div *ngFor="let p of instructor['programas']" class="col-lg-3 col-md-6 mt-3">
                                    <img [src]="'/assets/images/programas/' + p.id_programa + '.png'" class="img-fluid">
                                </div>
                            </div>
                            <div *ngIf="instructor['programas'].length === 0" class="row text-center">
                                <h5 class="heading-small text-muted mb-4">El instructor no tiene programas licenciados.</h5>
                            </div>
                        </div>
                        <!-- <hr class="my-4"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>