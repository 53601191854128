<style>
    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }
    
    a {
        color: #000 !important;
        font-weight: bold !important
    }
    
    @media only screen and (min-width: 768px) {
        .margenes {
            margin: 15px !important;
        }
    }
</style>
<h1 mat-dialog-title class="text-center">CONTRATO DE ACREDITACION DE INSTRUCTORES</h1>
<div mat-dialog-content class="margenes">
    <div class="text-justify">
        <p>
            En la ciudad de Santiago, a {{ fechaHoy | date: 'EEEE d \'de\' MMMM yyyy':'':'es' }}, comparecen por una parte, don <b>GREGORIO ANDRÉS YANQUEZ SABUGO</b>, Ingeniero Comercial, cédula nacional de identidad N° 15.593.737-8, en representación
            de <b>BODY SYSTEMS CHILE S.A.</b>, rol único tributario Nº 96.982.500-7, ambos con domicilio para estos efectos en Av. Bernardo O'higgins Poniente #77, Of 1503, Comuna de Concepción, Ciudad de Concepción, en adelante <b>"la empresa"</b>; y
            por la otra parte, el Sr./Sra. <b>{{formulario.get('nombres').value | uppercase}} {{ formulario.get('apellidos').value | uppercase }}</b>, cédula nacional de identidad Nº <b>{{ formulario.get('rut').value | rut }}</b>, domiciliado en <b>{{ formulario.get('direccion').value  }}</b>,
            comuna de <b>{{ comunas }}</b>, región de <b>{{regiones}}</b> en adelante <b>"el instructor"</b>, quienes convienen en celebrar el siguiente contrato de Capacitación de acuerdo a las siguientes cláusulas y condiciones:
        </p>
        <p>PRIMERO: Las partes están en pleno conocimiento que la Empresa es legítima titular y propietaria de todos los derechos exclusivos para otorgar la licencia de ejercitación con música de LES MILLS en territorio Chileno, inscrito en INAPI (Instituto
            Nacional de Propiedad Intelectual) del Ministerio de Economía, que comprende los programas de entrenamiento LES MILLS GRIT SERIES®, LES MILLS GRIT®, BODYPUMP®, BODYBALANCE®, BODYATTACK®, BODYSTEP®, BODYCOMBAT®, RPM®, BODYJAM®, BODYVIVE®, SH
            ́BAM®, SPRINT®, CXWORX®, LES MILLS CORE®, LES MILLS BARRE® y POWERJUMP®.
        </p>
        <p>
            SEGUNDO: <b>"la empresa"</b> se obliga a capacitar a <b>"el instructor"</b> en el dictado de las clases de los programas indicado en el ordinal precedente, debiendo proporcionar el material para dar capacitación, que incluye el de tipo visual,
            escrito, musical, técnico y coreográfico. Por su parte <b>"el instructor"</b> reconoce en términos formales, explícitos y directos que <b>"la empresa"</b> es la titular de los derechos de los programas, por lo que no tiene derecho alguno sobre
            los mismos, más allá del uso en los términos y condiciones señalados en el presente contrato.
        </p>
        <p>
            TERCERO: Por lo anterior, <b>"la empresa"</b> confiere a <b>"el instructor"</b>, una autorización restringida, no exclusiva e intransferible, para usar el programa durante la vigencia del presente contrato, el cual sólo podrá impartirse en
            los gimnasios que ella señale y que se encuentra publicados en la página web www.lesmills.cl. Consecuentemente, <b>"la empresa"</b> traspasará a <b>"el instructor"</b> el conocimiento, entrenamiento y la calificación final del programa de
            entrenamiento <b>{{ cert.programa_nombre }}®,</b> cuidando la obtención del nivel exigido para la impartición de clases y la certificación como instructor de LES MILLS, que se materializará mediante la emisión del correspondiente CERTIFICADO
            emitido por BODY SYSTEMS CHILE S.A. y reconocido por "LES MILLS INTERNATIONAL LTD." y sus agentes en todo el mundo. La emisión del mencionado CERTIFICADO se encuentra condicionada a la comprobación por parte de <b>"la empresa"</b> de que <b>"el instructor"</b>            haya cumplido con la totalidad de los requisitos exigidos durante el módulo de capacitación para el desarrollo del programa en los establecimientos licenciados en especial, que es una persona apta físicamente, con capacidad jurídica y económica
            para contratar y obligarse en los términos del presente contrato.
        </p>
        CUARTO: Como contraprestación y atendido que <b>"el instructor"</b> será capacitado en el programa <b>{{ cert.programa_nombre }}®</b> nivel <b>{{ cert.nombre_rango }}</b> en las siguiente fechas: <br> <br>
        <ul>
            <li *ngFor="let fecha of cert.fechas">
                <span *ngIf="fecha.nombre == 'Bienvenida'">Reunión bienvenida: </span>
                <span *ngIf="fecha.nombre == 'Día 1'">Capacitación día 1: </span>
                <span *ngIf="fecha.nombre == 'Día 2'">Capacitación día 2: </span> {{fecha.fecha_inicio | date: 'dd/MM/y'}} - Modalidad: {{cert.nombre_tipo}}
            </li>
        </ul>
        <p>
            "El instructor” pagará a <b>"la empresa"</b>, la suma única de <b>{{ precioDescuento  | currency:' ': 'symbol-narrow': '0.0-5': 'es' }}</b>. Dicho pago se deberá efectuar por medio de WebPay con tarjeta de débito o crédito, depósito bancario,
            transferencia, pago efectivo o cheque a la cuenta bancaria de <b>"la empresa"</b>.
        </p>


        QUINTO: Se elevan a cláusulas esenciales de este contrato las siguientes:
        <ol type="1">
            <li><b>"el instructor"</b> se obliga a participar activamente en el entrenamiento que lleve a cabo <b>"la empresa"</b> para realizar y enseñar los programas. </li>
            <li>Queda prohibido a <b>"el instructor"</b> impartir los programas en ningún tipo de conferencias, reuniones públicas o privadas, fiestas, clases particulares, entre otras, si no es respaldada con una autorización previa y por escrito de <b>"la empresa"</b>.
            </li>
            <li>Queda prohibido al <b>"el instructor"</b> trabajar con los programas mientras no reúna los requisitos para su certificación previa. </li>
            <li><b>"el instructor"</b> se obliga a dar aviso a <b>"la empresa"</b> en caso de que terceras personas ajenas a este contrato hagan uso de los programas o bien a impedir el uso de los mismos en sitios o por personas no autorizadas por <b>"la empresa"</b>.
            </li>
            <li><b>"el instructor"</b> únicamente podrá utilizar los programas en un gimnasio que se encuentre pagando una licencia, por ende, queda estrictamente prohibido cobrar a título personal por clases dentro o fuera de las instalaciones del mismo,
                sin autorización previa de <b>"la empresa"</b>. </li>
        </ol>

        SEXTO: En el evento que se produzca una violación por el "instructor" a cualquiera de las indicaciones del ordinal anterior, <b>"la empresa"</b> podrá revocar la autorización otorgada, tanto en forma temporaria como en forma definitiva, y con
        respecto a alguno o a todos los programas. En los mismos términos, se podrá proceder, por las siguientes causas.

        <ol type="1">
            <li>Cuando <b>"el instructor"</b> se desvincule de <b>"la empresa"</b>. Esta revocación operará al día siguiente de la desvinculación. </li>
            <li>Cuando <b>"el instructor"</b> no imparta los programas conforme a los lineamientos que le fueron enseñados. </li>
            <li>Cuando <b>"el instructor"</b> imparta formas de acondicionamiento propios, haciéndolos pasar como si fueran los programas que le fueron impartidos por <b>"la empresa"</b>. </li>
            <li>Cuando <b>"el instructor"</b> no emplee o utilice los materiales otorgados por <b>"la empresa"</b> para la impartición de sus clases. </li>
            <li>Cuando <b>"el instructor"</b> haga comentarios o aseveraciones que vayan en descrédito o menoscabo de la efectividad o fines de los programas o de <b>"la empresa"</b>. </li>
            <li>Cuando <b>"el instructor"</b> adquiera copias del material por cualquier medio no autorizado, ya sea por medio de un tercero o cualquier medio electrónico o cibernético sin el permiso o autorización de la empresa o que lo proporcione a personas
                no autorizadas por <b>"la empresa"</b>. Se incluye como infracción al presente número, los casos en que <b>“el instructor”</b> sea sorprendido realizando un programa de entrenamiento o promocionando como habilitado para usar un programa
                de entrenamiento (a través de redes sociales, por la web o a través de cualquier otro medio de prueba) que no haya sido adquirido a través de <b>“la empresa”</b>. En este caso <b>“la empresa”</b> podrá, unilateralmente y sin forma de juicio,
                revocar toda autorización de uso de todos los programas de entrenamiento contratados por <b>“el instructor”</b>, pudiendo dar de baja la aplicación web, la aplicación móvil, los kits digitales (contenido fonográfico y audiovisual) y los
                releases. </li>
            <li>Cuando <b>"el instructor"</b>, imparta los programas en lugares diferentes a los Gimnasios Licenciados por <b>"la empresa"</b>. </li>
            <li>Cuando <b>"el instructor"</b>, comparta su material para fines no autorizados por <b>"la empresa"</b> </li>
        </ol>
        <p>
            La revocación de la autorización se puede dar de dos formas:
        </p>
        <ol type="a">
            <li>Puede tener un límite temporal de hasta dieciocho (18) meses, y transcurrido dicho plazo, podrá <b>"el instructor"</b> emplear los programas que considere pertinentes, previa aprobación por escrito por parte de <b>"la empresa"</b>. </li>
            <li>Puede ser definitiva: </li>
        </ol>
        <p>
            La revocación de la autorización definitiva operará en los casos de las infracciones del numeral 5 del ordinal quinto y 1, 3, 5, 6, 7 y 8 del presente ordinal. Para los efectos de la revocación de la licencia <b>"el instructor"</b> fija el
            correo electrónico
            <b>{{ formulario.get('email').value }}</b>, entendiéndose notificado a contar de la remisión de la misma. En el evento que <b>"el instructor"</b>, sea sancionado por <b>"la empresa"</b> con la revocación definitiva y luego de habérsele notificado
            al correo electrónico fijado por él, <b>"la empresa"</b>, no estará obligada a capacitarlo por los programas contratados con anterioridad a dicha sanción, ni con otros que pretenda contratar en el futuro, como tampoco estará obligada a prestar
            cualquier otra prestación que se requiera.
        </p>

        <p>
            SÉPTIMO: En el caso de ACEPTAR los términos y condiciones del presente contrato, se confirmará definitivamente la inscripción de <b>"el instructor"</b> a la certificación indicada la cláusula Cuarta. A partir de ese momento <b>"la empresa"</b>            no realizará devoluciones de dinero. En el caso de RECHAZAR los términos y condiciones del presente contrato, <b>"la empresa"</b> cancelará la inscripción a la certificación por parte de <b>"el instructor"</b> y realizará la devolución del
            total cancelado por <b>"el instructor"</b>. Para tales efectos, se define el correo electrónico certificaciones@bodysystems.cl para coordinar la devolución del dinero. Para compras realizadas por este medio no aplica el derecho de retracto
            establecido en el artículo 3 bis letra b) de la Ley N° 19.496 sobre Protección de los Derechos de los Consumidores vigente en Chile.
        </p>
        <p>
            OCTAVO: Si <b>"el instructor"</b> no puede asistir a la certificación por motivos de fuerza mayor <b>"la empresa"</b> establece que no realizará devolución de dinero. En este caso se le ofrece a <b>"el instructor"</b> un plazo de hasta 12
            meses a partir de la fecha del presente contrato, para inscribirse en la siguiente certificación del mismo programa a la que pueda asistir. En dicho caso <b>"el instructor"</b> deberá solicitar su participación al correo certificaciones@bodysystems.cl,
            con un plazo máximo de siete días antes del inicio de la certificación.
        </p>
        <p>
            NOVENO: La ACTUALIZACIÓN es la AUTORIZACIÓN RESTRINGIDA que BODY SYSTEMS CHILE S.A. otorga a <b>"el instructor"</b> para dictar el programa de entrenamiento en el que fue capacitado. Ésta, tiene un costo que lo determina <b>"la empresa"</b>.
        </p>
        <p>
            El proceso de actualización se realiza a través de la página web; https://www.lesmills.cl/login sitio en donde el <b>"el instructor"</b> posee una cuenta a la que sólo podrá acceder a través de su ID de usuario: <b>{{ formulario.get('email').value }}</b>.
        </p>

        <p>
            Al momento de actualizarse, <b>"el instructor"</b> recibirá un material digital de estudio denominado "Release", correspondiente al nuevo mix del programa que actualizó, además de todo el material audiovisual y/o gráfico que LES MILLS INTERNATIONAL
            LTD haya habilitado para <b>"el instructor"</b> a través de BODY SYSTEMS CHILE S.A. Dicho Release será activado única y exclusivamente en las siguientes cuentas digitales que posee <b>"el instructor"</b>:
        </p>

        <ul>
            <li>Página Web: <a target="_blank" href="https://lesmills.secure.force.com/digital/es_default_login">https://lesmills.secure.force.com/digital/es_default_login</a></li>
            <li>App para dispositivos móbiles: <b>Les Mills Releases </b></li>
        </ul>

        <p>
            En ambas plataformas <b>"el instructor"</b> sólo podrá acceder a través de su ID de usuario: <b>{{ formulario.get('email').value }}</b>.
        </p>
        <p>
            Se deja constancia que <b>"el instructor"</b> no está obligado a renovar los programas de entrenamiento, pero en caso de hacer uso de un programa de entrenamiento que no haya sido adquirido desde <b>“la empresa”</b>, estará sujeto a lo dispuesto
            en el número 6 de la cláusula Sexta.
        </p>
        <p>
            DÉCIMO: En el caso de que <b>"la empresa"</b> deba suspender la certificación por motivos de fuerza mayor, ésta se compromete a realizar únicamente, la devolución de la totalidad del dinero cancelado por <b>"el instructor"</b> por motivo de
            su capacitación señalada en el numeral 4 del presente contrato. <b>"la empresa"</b> se fija un plazo máximo de 15 días hábiles una vez que <b>"el instructor"</b> haya solicitado por email la devolución de su dinero adjuntando los datos bancarios
            para realizar la transferencia. Para este caso se fija el email certificaciones@bodysystems.cl como canal exclusivo de comunicación. Por su parte <b>"la empresa"</b> deja constancia que no realizará reembolsos de dinero por costos en los que
            haya incurrido <b>"el instructor"</b> tales como; pasajes, alojamiento, alimentación, traslados y de ningún otro tipo.
        </p>
        <p>
            DÉCIMO PRIMERO: En caso que <b>"el instructor"</b> necesite modificar su correo electrónico, podrá hacerlo redactando un mail con la solicitud a instructor@bodysystems.cl. En dicho caso la empresa se fija un plazo máximo de 15 días hábiles,
            para realizar el cambio de correo en las diferentes cuentas y plataformas señaladas en el presente contrato. <br> Una vez realizado el cambio de correo, <b>"la empresa"</b> enviará un mail de; <i>confirmación de cambio correo</i> a la nueva
            dirección indicada por <b>"el instructor"</b> que a su vez, será establecida para efectuar todo tipo de notificaciones futuras.
        </p>
        <p>
            DÉCIMO SEGUNDO: Los derechos y obligaciones derivadas de este contrato y a cargo de las partes no podrán ser cedidos, transferidos, negociados o afectados en manera alguna por cualquiera de las partes, sin el consentimiento expreso y por escrito de la
            otra. Asimismo, <b>"el instructor"</b> no podrá comunicar o ventilar a terceros ningún tipo de información no publicada o de carácter confidencial de que haya tenido conocimiento, con motivo de la ejecución de sus obligaciones
            emanadas del presente contrato, salvo que <b>"la empresa"</b> lo hubiera expresamente autorizado por escrito. Esta obligación de reserva o confidencialidad seguirá en vigencia aún después del vencimiento del plazo contractual o eventual resolución
            o revocación del presente contrato, haciéndose responsable <b>"el instructor"</b> de los daños y perjuicios que pudiera ocasionar la difusión de datos o informes no publicitados. Será causa de resolución instantánea del presente contrato sin
            responsabilidad para <b>"la empresa"</b>, sí <b>"el instructor"</b> llegare a violar dicho principio de confidencialidad, siendo responsable de cualquier consecuencia de tal violación que se contempla en esta cláusula y de cualquier responsabilidad
            de carácter, civil, penal o mercantil.
        </p>
        <p>
            DÉCIMO TERCERO: Las partes señalan como su domicilio para los efectos legales del presente contrato, aquel fijado en este contrato.
        </p>
        <p>
            DÉCIMO CUARTO: El presente contrato contiene todos los términos, declaraciones y garantías vigentes acordadas entre las partes y, por ende, sustituye o reemplaza todas las discusiones y acuerdos previos que se refieren a las materias aquí tratadas, debiendo
            considerarse para su aplicación y/o interpretación como única vinculación contractual existente entre <b>"el instructor"</b> y <b>"la empresa"</b>.
        </p>
        <p>
            DECIMO QUINTO: El plazo del presente contrato será por tiempo indefinido, salvo que una de las partes notifique a la otra su deseo de darlo por terminado con 30 (treinta) días de anticipación a la terminación deseada.
        </p>
        <p>
            DECIMO SEXTO: Para el caso de controversias legales, las partes vienen expresamente en prorrogar la competencia a los Tribunales Ordinarios de Justicia de Santiago de Chile.
        </p>

    </div>
</div>
<div mat-dialog-actions align="start">
    <mat-checkbox (change)="cargando = !$event.checked" color="accent">
        He leído y acepto los Términos y Condiciones
    </mat-checkbox>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-raised-button (click)="comprarCertificacion()" color="primary" [disabled]="cargando">Comprar Certificacion</button>
</div>