import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mkt-studio',
  templateUrl: './mkt-studio.component.html',
  styleUrls: ['./mkt-studio.component.scss']
})
export class MktStudioComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
  }

  abrirVideo(): void{
    this.dialog.open(VideoMKTStudioDialog, {
      width: '800px',
    });
  }
}


@Component({
  selector: 'video-mkt-studio',  
  templateUrl: './video.html',
})
export class VideoMKTStudioDialog {

  constructor(
    public dialogRef: MatDialogRef<VideoMKTStudioDialog>,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  

}