<div class="logo-repeat-bg">
    <div id="home" class="container-xl mt-5 mb-5">
        <div class="row mt-5">
            <div *ngIf="loading" class="col-12">
                <mat-spinner class="mx-auto mt-5"></mat-spinner>
            </div>
            <div *ngIf="!loading" class="col-12">
                <div class="row">

                    <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                        <div class="card shadow-sm mt-3">
                            <div class="card-header">
                                <h4 class="my-0 font-weight-normal">
                                    DATOS PERSONALES
                                </h4>
                            </div>
                            <div class="card-body">
                                <p>
                                    <b>Nombre: </b>{{usuario['name']}} {{usuario['apellidos']}}
                                </p>
                                <p>
                                    <b>Mail: </b>{{usuario['email']}}
                                </p>
                            </div>
                        </div>
                        <div class="card shadow-sm mt-3">
                            <div class="card-header">
                                <h4 class="my-0 font-weight-normal">
                                    Programas Certificados: <span class="text-success">({{nProgramas}})</span>
                                </h4>
                            </div>
                            <div class="card-body">
                                <p class="ml-4">
                                    <span *ngFor="let p of programas; let i = index">
                                    {{p['nombre']}}
                                    <span *ngIf="(nProgramas - 1) != i"> -</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div class="card shadow-sm mt-3">
                            <div class="card-header">
                                <h4 class="my-0 font-weight-normal">
                                    <b>Licencia de Instructor: </b>
                                    <span class="text-success" *ngIf="licencia">{{licencia['tipoLicencia'] | uppercase}}</span>
                                    <span *ngIf="!licencia">Sin Registro</span>
                                </h4>
                            </div>
                            <div class="card-body">
                                <span class="mr-4"></span>Inicio de Licencia: <span *ngIf="licencia">{{licencia['fechaCompra'] | date: 'dd.MM.y'}}</span>
                                <br><span class="mr-4"></span>Término de Licencia: <span *ngIf="licencia">{{licencia['fechaVencimiento'] | date: 'dd.MM.y'}}</span>
                            </div>
                        </div>
                        <div class="card shadow-sm mt-3">
                            <div class="card-header">
                                <h4 class="my-0 font-weight-normal">
                                    <b>Gimnasios Licenciados Asociados: </b><span class="text-success">({{nGimnasiosLicenciados}})</span>
                                </h4>
                            </div>
                            <div class="card-body">
                                <ol *ngIf="nGimnasiosLicenciados > 0" type="1">
                                    <li *ngFor="let g of gimnasiosLicenciados['info']">
                                        <b>{{g['nombre'] | uppercase}}:</b><br>
                                        <p>{{g['programasLicencia']}}</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="card shadow-sm mt-3">
                            <div class="card-header">
                                <h4 class="my-0 font-weight-normal">
                                    RESUMEN: (Tienes acceso a los siguientes productos)
                                </h4>
                            </div>
                            <div class="card-body">
                                <b>Actualizaciones Disponibles:  </b>({{nActDisponibles}})<br>
                                <p class="ml-4">
                                    <span *ngFor="let a of actDisponibles; let i = index">
                                        {{a['nombre']}}
                                        <span *ngIf="(nActDisponibles - 1) != i"> -</span>
                                    </span>
                                </p>
                                <b>Certificaciones Disponibles:  </b>({{nCertDisponibles}})<br>
                                <p class="ml-4">
                                    <span *ngFor="let c of certDisponibles; let i = index">
                                            {{c['nombre']}}
                                            <span *ngIf="(nCertDisponibles - 1) != i"> -</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>