import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegionesService {

  constructor(
    private http: HttpClient,
    private token: TokenStorageService
  ) { }

  getRegiones(): Observable<any> {
    return this.http.get(environment.api_url + 'regiones');
  }

  getComunasByRegion(idRegion): Observable<any> {
    return this.http.get(environment.api_url + 'getComunasByRegion/'+idRegion);
  }
}
