<div id="datos-personales" class="bg-foto">
    <div class="container main-registro  pb-5">
        <div class="bg-light shadow-lg rounded-3 overflow-hidden">
            <div class="row">
                <!-- Sidebar-->
                <aside class="col-lg-4 pe-xl-5">
                    <app-panel-menu-left></app-panel-menu-left>
                </aside>
                <!-- Content-->
                <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
                    <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
                        <h2 class="h3 py-2 text-center text-sm-start">Datos Personales</h2>

                        <form name="datosForm" [formGroup]="datosForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngIf="resultado" class="alert {{tipoAlert}}">
                                        {{msjAlert}}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Correo</mat-label>
                                        <input matInput formControlName="email">
                                        <mat-icon matSuffix>mail</mat-icon>
                                        <mat-error *ngIf="datosForm.get('email').hasError('required')">
                                            El Correo es Obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="!datosForm.get('email').hasError('required') && datosForm.get('email').hasError('email')">
                                            Por favor ingresa un correo válido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Nombres</mat-label>
                                        <input matInput formControlName="nombres">
                                        <mat-error *ngIf="datosForm.get('nombres').hasError('required')">
                                            El Nombre es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Apellidos</mat-label>
                                        <input matInput formControlName="apellidos">
                                        <mat-error *ngIf="datosForm.get('apellidos').hasError('required')">
                                            El Apellido es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Rut</mat-label>
                                        <input matInput formControlName="rut" formatRut>
                                        <mat-icon matSuffix>perm_identity</mat-icon>
                                        <mat-error *ngIf="datosForm.get('rut').hasError('required')">
                                            El Rut es Obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="datosForm.get('rut').hasError('invalidRut')">
                                            El Rut es inválido
                                        </mat-error>
                                        <mat-error *ngIf="datosForm.get('rut').hasError('rutExiste')">
                                            El Rut ya esta registrado
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Teléfono</mat-label>
                                        <mat-icon matSuffix>local_phone</mat-icon>
                                        <input mask="(0) 0000-0000" matInput formControlName="telefono">
                                        <mat-error *ngIf="datosForm.get('telefono').hasError('required')">
                                            El Teléfono es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>
                                            Región
                                            <mat-spinner class="progress-spinner-inline" *ngIf="loadingR" class="float-right" diameter="20"></mat-spinner>
                                        </mat-label>
                                        <mat-select [disabled]="loadingR" formControlName="region" (selectionChange)="obtenerComunas($event.value)">
                                            <mat-option *ngFor="let r of region" [value]="r.id">{{ r.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="datosForm.get('region').hasError('required')">
                                            La Región es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>
                                            Comuna
                                            <mat-spinner class="progress-spinner-inline" *ngIf="loadingCS" class="float-right" diameter="20"></mat-spinner>
                                        </mat-label>
                                        <mat-select [disabled]="loadingC" formControlName="comuna">
                                            <mat-option *ngFor="let r of comuna" [value]="r.id">{{ r.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="datosForm.get('comuna').hasError('required')">
                                            La Comuna es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Dirección</mat-label>
                                        <input matInput formControlName="direccion">
                                        <mat-icon matSuffix>place</mat-icon>
                                        <mat-error *ngIf="datosForm.get('direccion').hasError('required')">
                                            La Dirección es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <button *ngIf="!cargando" class="btn btn-lg btn-primary mt-3 float-right" type="submit">GUARDAR</button>
                                    <button *ngIf="cargando" class="btn btn-lg btn-primary mt-3 float-right" type="submit" disabled>
                                        CARGANDO 
                                        <mat-icon>
                                            <mat-spinner class="mt-1 ml-3" color="primary" diameter="20"></mat-spinner>
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <hr>

                        <h2 class="h3 py-2 text-center text-sm-start">Cambio de Clave</h2>


                        <form name="cambioClaveForm" [formGroup]="cambioClaveForm" (ngSubmit)="onSubmitCC()">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngIf="resultadoCC" class="alert {{tipoAlertCC}}">
                                        {{msjAlertCC}}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Contraseña Anterior</mat-label>
                                        <input matInput type="password" formControlName="password_old">
                                        <mat-icon matSuffix>password</mat-icon>
                                        <mat-error>
                                            La Contraseña Anterior es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Contraseña</mat-label>
                                        <input matInput type="password" formControlName="password">
                                        <mat-icon matSuffix>password</mat-icon>
                                        <mat-error>
                                            La Contraseña es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Confirma Contraseña</mat-label>
                                        <input matInput type="password" formControlName="c_password">
                                        <mat-icon matSuffix>password</mat-icon>
                                        <mat-error>
                                            La Confirmación de Contraseña es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <button *ngIf="!cargandoCC" class="btn btn-lg btn-primary mt-3 float-right" type="submit">GUARDAR</button>
                                    <button *ngIf="cargandoCC" class="btn btn-lg btn-primary mt-3 float-right" type="submit" disabled>
                                        Cargando 
                                        <mat-icon>
                                            <mat-spinner class="mt-1 ml-3" color="primary" diameter="20"></mat-spinner>
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>