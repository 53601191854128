import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-certificaciones',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilInstructorComponent implements OnInit {
  
  s3Url = environment.s3_public_url + environment.perfil;
  idInstructor: number;
  instructor: any[];
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private user: UserService
  ) {
    this.idInstructor = Number(atob(this.route.snapshot.paramMap.get('idInstructor')));

    this.user.getInstructoresByID(this.idInstructor).subscribe(
      data => {
        // console.log(data.data);
        this.instructor = data.data;
        this.loading = false;
      }
    );

  }

  ngOnInit(): void {
  }

}
