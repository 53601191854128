<!-- Account menu toggler (hidden on screens larger 992px)-->
<div class="d-block d-lg-none p-4 menu-left">
    <div class="container mx-auto text-center">
        <div class="nav price-tabs" role="tablist">
            <a class="nav-link" data-bs-toggle="collapse" routerLinkActive="active" routerLink="/panel/datos-personales">Datos Personales</a>
            <a class="nav-link" h data-bs-toggle="collapse" routerLinkActive="active" routerLink="/panel/perfil">Perfil</a>
        </div>
    </div>
</div>

<!-- Actual menu-->
<div class="h-100 border-end mb-2 menu-left border-menu">
    <div class="d-lg-block collapse" id="account-menu">
        <div class="bg-secondary p-4">
            <h3 class="fs-sm mb-0 text-muted">Cuenta</h3>
        </div>
        <ul class="list-unstyled mb-0">
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLinkActive="active" routerLink="/panel/datos-personales">
                    <i class="fa fa-user mr-2"></i>Datos Personales
                </a>
            </li>
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLinkActive="active" routerLink="/panel/perfil">
                    <i class="far fa-address-card mr-2"></i>Perfil
                </a>
            </li>
            <!-- <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLinkActive="active" routerLink="/panel/cambio-clave">
                    <i class="fas fa-key mr-2"></i>Cambio de Contraseña
                </a>
            </li> -->
        </ul>
        <!--  <div class="bg-secondary p-4">
            <h3 class="fs-sm mb-0 text-muted">Seller Dashboard</h3>
        </div>
        <ul class="list-unstyled mb-0">
            <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-sales.html"><i class="ci-dollar opacity-60 me-2"></i>Sales<span class="fs-sm text-muted ms-auto">$1,375.00</span></a></li>
            <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-products.html"><i class="ci-package opacity-60 me-2"></i>Products<span class="fs-sm text-muted ms-auto">5</span></a></li>
            <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-add-new-product.html"><i class="ci-cloud-upload opacity-60 me-2"></i>Add New Product</a></li>
            <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-payouts.html"><i class="ci-currency-exchange opacity-60 me-2"></i>Payouts</a></li>
            <li class="mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" href="account-signin.html"><i class="ci-sign-out opacity-60 me-2"></i>Cerrar Sesión</a></li>
        </ul> -->
    </div>
</div>