import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GimnasioService } from 'src/app/services/gimnasios.service';
import { environment } from '../../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-gimnasios',
  templateUrl: './gimnasios.component.html',
  styleUrls: ['./gimnasios.component.scss']
})
export class GimnasiosComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  loading = true;
  dtOpcionesDesktop: DataTables.Settings = {};
  dtOpcionesMobile: DataTables.Settings = {};
  gimnasios = Array();

  dtTrigger: Subject<any> = new Subject();

  constructor(
    private gim: GimnasioService
  ) {
    
    
    // this.dtOpcionesMobile = {
    //   lengthMenu: [[ 10, 20, 10000], [ 10, 20, "Todos"]],
    //   language: environment.lenguajeDatatables,
    //   dom: "ftp",
    //   columnDefs: [
    //     { "width": "45%", "targets": 0 },
    //     { "width": "20%", "targets": 1 },
    //     { "width": "10%", "targets": 2 },
    //     { "width": "25%", "targets": 3 }
    //   ]
    // };

  }

  ngOnInit(): void {

    this.gim.getGimnasiosLicenciados().subscribe(
      data => {
        this.gimnasios = data.data;
        this.loading = false;

        this.dtOpcionesDesktop = {
          language: environment.lenguajeDatatables,
          scrollX: true,
          processing: true
        };
        this.rerender();
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    const self = this
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Add event listener for opening and closing details
      $('table tbody').on('click', 'td.details-control', function () {
          var tr = $(this).closest('tr');
          var row = dtInstance.row( tr );
          console.log(row);
  
          if ( row.child.isShown() ) {
              // This row is already open - close it
              row.child.hide();
              tr.removeClass('shown');
          }
          else {
              // Open this row
              row.child( self.format(row.data()) ).show();
              tr.addClass('shown');
          }
      });
    })
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  format(d) {
    console.log(d);
    // `d` is the original data object for the row
    return `<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">
        <tr>
            <td>Full name:</td>
            <td>Test</td>
        </tr>
        <tr>
            <td>Extra info:</td>
            <td>And any further details here (images etc)...</td>
        </tr>
    </table>`;
  }

}
