import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';

@Component({
  selector: 'app-metodos-pago',
  templateUrl: './metodos-pago.component.html',
  styleUrls: ['./metodos-pago.component.scss']
})
export class MetodosPagoComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  agregarTarjeta(): void{
    this.dialog.open(AgregarTarjetaDialog, {
      width: '800px',
    });
  }
}


@Component({
  selector: 'agregar-tarjeta',  
  templateUrl: './agregar-tarjeta/agegar-tarjeta.html',

})
export class AgregarTarjetaDialog {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AgregarTarjetaDialog>,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      numeroTarjeta: ['', [Validators.required, CreditCardValidators.validateCCNumber]],
      fechaExpiracion: ['', [Validators.required, CreditCardValidators.validateExpDate]],
      ccv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    });
  }

  onSubmit(form: FormGroup) {
    console.log(form.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  

}

