<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-sp.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-sp">¿QUÉ ES SPRINT?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">SPRINT fue diseñado con base en la ciencia del Entrenamiento de Intervalos de Alta Intensidad (HIIT). Un estilo de entrenamiento acelerado e intenso que brinda rápidos resultados con mínimo impacto en las articulaciones.</p>

                <p class="desc-programs text-justify">La corta duración de un entrenamiento de SPRINT te motivará a empujar tus límites físicos y mentales. Lo caracterizan explosiones de intensidad donde trabajarás tan duro como puedas, seguidas de periodos cortos de recuperación que te preparan
                    para el siguiente esfuerzo. La recompensa es la quema de calorías que continúa durante horas después.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-sp">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">ALTA</h3>
                        <h1 class="p-0 m-0 title-color-sp"><strong>INTENSIDAD</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">RESULTADOS</h3>
                        <h1 class="p-0 m-0 title-color-sp"><strong>RAPIDOS</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">ALCANZA</h3>
                        <h1 class="p-0 m-0 title-color-sp"><strong>TUS METAS</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON SPRINT?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify">Idealmente, debes tener algo de condición para participar de un entrenamiento de SPRINT. No tengas miedo de bajar la intensidad en las primeras sesiones, y sólo siente de lo que se trata. Si lo encuentras muy fuerte baja el ritmo, reduce
                    la carga o siéntate y recupera. Si buscas un plan de entrenamiento balanceado, recomendamos integrar dos entrenamientos HIIT por semana.</p>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-sp">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Elimina el impacto de cualquier otro entrenamiento HIIT</li>
                            <li>Mejora la resistencia cardiovascular</li>
                            <li>Mejora velocidad, fuerza y potencia del tren inferior</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <p class="desc-programs text-center">
                            Bicicleta indoor
                        </p>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>500</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            35 minutos
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-sp.mp4" type="video/mp4">
     </video>
    </div>

</section>