<h1 mat-dialog-title>Agregar Tarjeta de Crédito</h1>
<form #demoForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit(demoForm)" novalidate>
    <div mat-dialog-content>
        <div class="row pb-4">
            <div class="col-12">
                <div class="alert alert-info">
                    Se realizará un cobro de $50 pesos el cual será reversado para validar la tarjeta de crédito.
                </div>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline" color="accent">
                    <mat-label>Número de Tarjeta</mat-label>
                    <input matInput id="cc-number" formControlName="numeroTarjeta" type="tel" autocomplete="cc-number" ccNumber #ccNumber="ccNumber">
                    <mat-icon matSuffix *ngIf="(ccNumber.resolvedScheme$ | async) === 'unknown'">credit_card</mat-icon>
                    <mat-icon matSuffix *ngIf="(ccNumber.resolvedScheme$ | async) === 'visa'"><i class="fab fa-cc-visa"></i></mat-icon>
                    <mat-icon matSuffix *ngIf="(ccNumber.resolvedScheme$ | async) === 'mastercard'"><i class="fab fa-cc-mastercard"></i></mat-icon>
                    <mat-icon matSuffix *ngIf="(ccNumber.resolvedScheme$ | async) === 'amex'"><i class="fab fa-cc-amex"></i></mat-icon>
                    <mat-icon matSuffix *ngIf="(ccNumber.resolvedScheme$ | async) === 'discover'"><i class="fab fa-cc-discover"></i></mat-icon>
                    <mat-icon matSuffix *ngIf="(ccNumber.resolvedScheme$ | async) === 'dinersclub'"><i class="fab fa-cc-diners-club"></i></mat-icon>
                    <mat-error *ngIf="form.get('numeroTarjeta').hasError('required')">
                        El número de la tarjeta de obligatorio.
                    </mat-error>
                    <mat-error *ngIf="form.get('numeroTarjeta').hasError('ccNumber')">
                        El número de la tarjeta es inválido.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline" color="accent">
                    <mat-label>Expiración (06/2025)</mat-label>
                    <input matInput id="cc-exp-date" formControlName="fechaExpiracion" type="tel" autocomplete="cc-exp" ccExp>
                    <mat-icon matSuffix>date_range</mat-icon>
                    <mat-error *ngIf="form.get('fechaExpiracion').hasError('required')">
                        La fecha de expiración de obligatoria.
                    </mat-error>
                    <mat-error *ngIf="form.get('fechaExpiracion').hasError('expDate')">
                        La fecha de expiración de inválida.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline" color="accent">
                    <mat-label>CCV</mat-label>
                    <input matInput id="cc-cvc" formControlName="ccv" type="tel" autocomplete="off" ccCvc maxlength="4">
                    <mat-icon matSuffix>password</mat-icon>
                    <mat-error *ngIf="form.get('ccv').hasError('required')">
                        El CCV de obligatorio.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cerrar</button>
        <button type="submit" class="btn btn-primary float-right">Guardar</button>
    </div>
</form>