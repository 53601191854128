<div *ngIf="this.token.checkToken()" class="nav-black">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark-lm navbar-lm">
        <a class="navbar-brand" routerLink="inicio">
            <img src="/assets/images/logos/LMWhite.svg" height="20" class="d-inline-block align-top mt-1" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-contenido-plataforma" aria-controls="navbar-contenido" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>


        <div class="collapse navbar-collapse" id="navbar-contenido-plataforma">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown i-compressed">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        LICENCIAS
                    </a>
                    <div class="dropdown-menu dropdown-menu-left rounded-0 d-compressed">
                        <a class="dropdown-item" routerLinkActive="active" routerLink="panel/licencias" data-toggle="collapse" data-target="#navbar-contenido-plataforma">INSTRUCTOR</a>
                        <a class="dropdown-item" routerLinkActive="active" routerLink="gimnasios" data-toggle="collapse" data-target="#navbar-contenido-plataforma">GIMNASIOS</a>
                    </div>
                </li>
                <li class="nav-item dropdown i-compressed">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        CERTIFICACIONES
                    </a>
                    <div class="dropdown-menu dropdown-menu-left rounded-0 d-compressed">
                        <a class="dropdown-item" routerLinkActive="active" routerLink="certificaciones" data-toggle="collapse" data-target="#navbar-contenido-plataforma">INSCRIPCIONES</a>
                        <a class="dropdown-item" routerLinkActive="active" routerLink="panel/certificaciones" data-toggle="collapse" data-target="#navbar-contenido-plataforma">MIS CERTIFICACIONES</a>
                    </div>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="panel/programas" data-toggle="collapse" data-target="#navbar-contenido-plataforma">ACTUALIZACIONES</a>
                </li>
                <!-- <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="panel/mkt-studio" data-toggle="collapse" data-target="#navbar-contenido-plataforma">MKT STUDIO</a>
                </li> -->
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="panel/contenido" data-toggle="collapse" data-target="#navbar-contenido-plataforma">CONTENIDOS</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="buscador" data-toggle="collapse" data-target="#navbar-contenido-plataforma">INSTRUCTORES</a>
                </li>
                <li class="nav-item dropdown i-compressed">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <!-- {{ user.usuario['name'].toUpperCase() }} -->
                    </a>
                    <div class="dropdown-menu dropdown-menu-left rounded-0 d-compressed">
                        <a class="dropdown-item" routerLinkActive="active" routerLink="panel/datos-personales" data-toggle="collapse" data-target="#navbar-contenido-plataforma">DATOS PERSONALES</a>
                        <a class="dropdown-item" routerLinkActive="active" routerLink="panel/home" data-toggle="collapse" data-target="#navbar-contenido-plataforma">MI ESTADO</a>
                        <a class="dropdown-item" routerLinkActive="active" routerLink="panel/mis-compras" data-toggle="collapse" data-target="#navbar-contenido-plataforma">MIS COMPRAS</a>
                        <a class="dropdown-item" (click)="logout()" data-toggle="collapse" data-target="#navbar-contenido-plataforma">CERRAR SESIÓN</a>
                        <!-- <a class="dropdown-item" href="">Cambiar Contraseña</a> -->
                        <!-- <a class="dropdown-item" href="">Medios de Pago</a> -->
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>
<div *ngIf="!this.token.checkToken()" class="nav-mini">
    <nav class="navbar navbar-expand-lg navbar-lm">
        <ul class="navbar-nav ">
            <li class="nav-item">
                <a class="nav-link" href="login">LOGIN</a>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">
            <!-- <li class="nav-item bg-amarillo">
                <a class="nav-link" href="#">BUSCAR UNA CLASE</a>
            </li> -->
        </ul>
    </nav>

</div>
<div *ngIf="!this.token.checkToken()" class="nav-primary">
    <nav class="navbar navbar-expand-lg navbar-light navbar-lm">
        <a class="navbar-brand" routerLink="inicio">
            <img src="/assets/images/logos/LMBlack.svg" height="20" class="d-inline-block align-top mt-1" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-contenido" aria-controls="navbar-contenido" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbar-contenido">
            <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="gimnasios" data-toggle="collapse" data-target="#navbar-contenido">GIMNASIOS</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="programas" data-toggle="collapse" data-target="#navbar-contenido">PROGRAMAS</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="#">GIMNASIOS</a>
                </li> -->
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="licencias" data-toggle="collapse" data-target="#navbar-contenido">LICENCIAS</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="certificaciones" data-toggle="collapse" data-target="#navbar-contenido">CERTIFICACIONES</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="#">EQUIPAMIENTO</a>
                </li> -->
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="buscador" data-toggle="collapse" data-target="#navbar-contenido">INSTRUCTORES</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="login">LOGIN</a>
                </li> -->
            </ul>
        </div>
    </nav>
</div>