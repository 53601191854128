<div class="logo-repeat-bg">
    <div id="check_mail" class="container main">
        <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div class="card my-2 mx-2">
                    <div class="card-body">
                        <h3 class="card-title text-center">Compra de Certificación</h3>
                        <hr>
                        <p class="text-center w-75 mx-auto">Ingresa tu correo para iniciar sesión o crear tu nueva cuenta Les Mills.</p>

                        <div *ngIf="mensajeError" class="text-center pb-2">
                            <mat-error>
                                {{ mensajeError }}
                            </mat-error>
                        </div>

                        <form name="loginForm" [formGroup]="checkForm" (ngSubmit)="onSubmit()">
                            <mat-form-field class="w-100 pb-4" appearance="outline" color="accent">
                                <mat-label>Correo</mat-label>
                                <input matInput formControlName="email">
                                <mat-icon matSuffix>mail</mat-icon>
                                <mat-error *ngIf="checkForm.get('email').hasError('required')">
                                    El Correo es Obligatorio
                                </mat-error>
                                <mat-error *ngIf="!checkForm.get('email').hasError('required') &&
                                            checkForm.get('email').hasError('email')">
                                    Por favor ingresa un correo válido
                                </mat-error>
                            </mat-form-field>
                            <button *ngIf="!cargando" class="btn btn-lg btn-primary btn-block " type="submit">CONTINUAR</button>
                            <button *ngIf="cargando" class="btn btn-lg btn-primary btn-block" type="submit" disabled>
                                CARGANDO 
                                <mat-icon>
                                    <mat-spinner class="mt-1 ml-3" color="primary" diameter="25"></mat-spinner>
                                </mat-icon>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>