<div id="metodos-pago" class="logo-repeat-bg">
    <div class="container main-registro mb-5 pb-3">
        <div class="bg-light shadow-lg rounded-3 overflow-hidden">
            <div class="row">
                <!-- Sidebar-->
                <aside class="col-lg-4 pe-xl-5">
                    <app-panel-menu-left></app-panel-menu-left>
                </aside>
                <!-- Content-->
                <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
                    <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
                        <h2 class="h3 py-2 text-center text-sm-start">Métodos de Pago</h2>
                        <div class="table-responsive fs-md mb-4">
                            <table class="table table-hover mb-0 text-center">
                                <thead>
                                    <tr>
                                        <th class="text-left">Tarjetas de Crédito</th>
                                        <th>Nombre</th>
                                        <th>Fecha de Expiración</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="py-3 ">
                                            <div class="d-flex align-items-center">
                                                <i class="fab fa-cc-visa pr-3"></i> Visa finalizada en 4999
                                                <span class="badge badge-success ml-2">Pago Principal</span>
                                            </div>
                                        </td>
                                        <td class="py-3">John doe</td>
                                        <td class="py-3">
                                            <a>
                                                <i class="fas fa-edit mr-2"></i>
                                            </a>
                                            <a>
                                                <i class="fas fa-trash text-danger"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <button (click)="agregarTarjeta()" class="btn btn-primary mt-3 float-right">Agregar Tarjeta de Crédito</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>