<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-cr.jpg">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-cx">¿QUÉ ES CORE?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">Ejercita la musculatura del centro de tu cuerpo. CORE proporciona el ingrediente vital para un núcleo más fuerte, poderoso, que te permitira un mejor desempeño en todas las cosas que haces, desde la vida cotidiana hasta tus deportes favoritos:
                    es el pegamento que mantiene todo unido. Tonifica abdominales, espalda, hombros, piernas y glúteos. </p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-cx">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">MEJORA LA</h3>
                        <h1 class="p-0 m-0 title-color-cx"><strong>POSTURA</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">TONIFICA</h3>
                        <h1 class="p-0 m-0 title-color-cx"><strong>ABS, GLÚTEOS Y PIERNAS</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">MEJORA LA</h3>
                        <h1 class="p-0 m-0 title-color-cx"><strong>FUERZA DEL CORE</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON CORE?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify">Presta atención a las opciones que te ofrece el instructor para que puedas ajustar el entrenamiento a tu nivel de fitness.</p>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-cx">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Define y tonifica abdominales, espalda y glúteos</li>
                            <li>Mejora la fuerza y resistencia muscular</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <ul class="desc-programs">
                            <li>Banda plana o tubular</li>
                            <li>Discos</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>210</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            45 o 30 minutos
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-cx.mp4" type="video/mp4">
     </video>
    </div>

</section>