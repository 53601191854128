<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-bc.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-bc">¿QUÉ ES BODYCOMBAT?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">BODYCOMBAT es un programa de entrenamiento cardiovascular inspirado en diferentes tipos de artes marciales, coreografiado con música. Los participantes realizan golpes de puños, de codos, patadas y katas, logrando conseguir una mayor resistencia
                    cardiovascular y una alta quema de calorías.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-bc">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">FITNESS CARDIO</h3>
                        <h1 class="p-0 m-0 title-color-bc"><strong>FLEXIBILIDAD</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">SIÉNTETE</h3>
                        <h1 class="p-0 m-0 title-color-bc"><strong>PODEROSO</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">ENTRENA</h3>
                        <h1 class="p-0 m-0 title-color-bc"><strong>TODO TU CUERPO</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON BODYCOMBAT?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify">Pudes adaptar el entrenamiento con BODYCOMBAT a tu nivel actual de fitness</p>
                <ul class="desc-programs">
                    <li>Sigue las opciones que te ofrece el instructor.</li>
                    <li>El primer día realiza cuatro o cinco tracks y progresa hasta realizar una clase completa.</li>
                    <li>Te garantizamos que terminarás la clase con la mente despejada y con ganas de “tirar la puerta abajo” para entrar en tu próxima clase de BODYCOMBAT.</li>
                </ul>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-bc">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Alto nivel de quema de calorías</li>
                            <li>Mejora la fuerza y resistencia cardiovascular</li>
                            <li>Libera el estrés</li>
                            <li>Mejora tu coordinación</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <p class="desc-programs text-center">
                            Sin material
                        </p>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>737</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            55, 45 ó 30 minutos
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-bc.mp4" type="video/mp4">
     </video>
    </div>

</section>