<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-pj.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-pj">¿QUÉ ES POWERJUMP?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">POWERJUMP es un programa intervalado que utiliza el mini-trampolín como equipamiento para realizar el trabajo cardiovascular, combinando alto gasto calórico y mucha diversión.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-pj">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">ENTRENA</h3>
                        <h1 class="p-0 m-0 title-color-pj"><strong>DIVERTIDO</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">TONIFICA</h3>
                        <h1 class="p-0 m-0 title-color-pj"><strong>MÚSCULOS</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">UN SALTO DE</h3>
                        <h1 class="p-0 m-0 title-color-pj"><strong>CALIDAD DE VIDA</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON POWERJUMP?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify">Presta atención a las opciones y niveles de intensidad que ofrece el instructor para que puedas ajustar el entrenamiento a tu nivel de Fitness.</p>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-pj">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Clase con alto gasto calórico</li>
                            <li>Trabaja la fuerza del tren inferior y estabilizadores</li>
                            <li>Entrenamiento divertido</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <p class="desc-programs text-center">
                            Mini-trampolín o trampolín
                        </p>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>650</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            55, 45 ó 30 minutos
                        </p>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-pj.mp4" type="video/mp4">
     </video>
    </div>

</section>