import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../services/token-storage.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public user: UserService,
    private route: Router,
    public token: TokenStorageService
  ) { }

  ngOnInit(): void {
  }

  
  logout(): void {
    this.user.logout().subscribe(
        data => {
            window.sessionStorage.clear();
            this.route.navigate(['/login']); 
        },
        err => {
            window.sessionStorage.clear();
            this.route.navigate(['/login']); 
        }
    );
  }

}
