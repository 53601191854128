<div class="logo-repeat-bg">
    <div id="certificaciones" class="container mb-5 pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4 class="my-0 font-weight-normal">
                            MIS CERTIFICACIONES
                        </h4>
                        <hr>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="loading">
                            <div class="col-12">
                                <mat-spinner class="mx-auto mt-5"></mat-spinner>
                            </div>
                        </div>
                        <div *ngIf="certificacionNueva" class="alert alert-success">
                            Te inscribiste exitosamente en la certificacion {{ certificacionNueva.programa_nombre }} {{certificacionNueva.nombre_rango}} {{certificacionNueva.nombre_tipo}}.
                        </div>
                        <div class="desktop">
                            <table *ngIf="!loading" datatable [dtOptions]="dtOpcionesDesktop" class="table bg-white table-striped w-100 text-center">
                                <thead>
                                    <tr>
                                        <th>Programa</th>
                                        <th>Estado</th>
                                        <th>Fechas</th>
                                        <th>N° Track</th>
                                        <th>Evaluación</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let c of certificaciones; let i = index">
                                        <tr>
                                            <td [matTooltip]="c.nombre_rango" matTooltipPosition="above">{{c['programa_nombre']}}</td>
                                            <td>{{c['nombre_estado']}}</td>
                                            <td>
                                                <span *ngFor="let f of c.fechas; let x = index"> 
                                            <span [matTooltip]="f.nombre" matTooltipPosition="above">
                                                {{ f.fecha_inicio | date: 'dd-MM-y'}}
                                                <span *ngIf="x < 2">
                                                    -
                                                </span>
                                                </span>
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="c['track_a']"> {{c['track_a']}}</span>
                                                <span *ngIf="!c['track_a']"> N/A</span>
                                            </td>
                                            <td>
                                                <span *ngIf="!c.video && !c.estado_usuario_p"> N/A</span>
                                                <span *ngIf="!c.video" matTooltip="Fecha Límite: {{ c.fecha_limite | date: 'dd-MM-y HH:mm' }}" matTooltipPosition="above">{{ c.estado_usuario_p }}</span>
                                                <span *ngIf="c.video">{{ c.estado_usuario_e }}</span>
                                            </td>
                                            <td>
                                                <div *ngIf="!habilitarVideo[c.id_certificacion]" class="btn-group" role="group" aria-label="Basic example">
                                                    <a *ngIf="c.evaluacion" target="_blank" matTooltip="Ver Assesment" matTooltipPosition="above" class="btn btn-sm btn-warning text-white" [href]="s3Url+c.evaluacion">
                                                        <i class="fas fa-paperclip"></i>
                                                    </a>

                                                    <button *ngIf="(c.dias_limite > 0 && !c.video) && (c.paso_fecha === false)" class="btn btn-sm btn-primary text-white" matTooltip="Enviar Vídeo" matTooltipPosition="above" (click)="habilitarVideo[c.id_certificacion] = true">
                                                <i class="fas fa-video"></i>
                                            </button>
                                                </div>
                                                <div *ngIf="habilitarVideo[c.id_certificacion]">
                                                    <mat-form-field *ngIf="!linkExito[c.id_certificacion]" class="w-100" appearance="outline" color="accent">
                                                        <mat-label>Vídeo</mat-label>
                                                        <input matInput [(ngModel)]="linkVideo[c.id_certificacion]" [disabled]="loadingVideo">
                                                        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="enviarVideo(c.id_certificacion, i)" [disabled]="loadingVideo">
                                                    <mat-icon>send</mat-icon>
                                                </button>
                                                    </mat-form-field>
                                                    <div *ngIf="linkExito[c.id_certificacion]" class="text-success text-center mt-2">
                                                        {{ linkExito[c.id_certificacion] }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="mobile">
                            <table *ngIf="!loading" datatable [dtOptions]="dtOpcionesMobile" class="table bg-white table-striped w-100 text-center">
                                <thead>
                                    <tr>
                                        <th>Programa</th>
                                        <th>Estado</th>
                                        <th>Fechas</th>
                                        <th>N° Track</th>
                                        <th>Evaluación</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let c of certificaciones; let i = index">
                                        <tr>
                                            <td [matTooltip]="c.nombre_rango" matTooltipPosition="above">{{c['programa_nombre']}}</td>
                                            <td>{{c['nombre_estado']}}</td>
                                            <td>
                                                <a [matTooltip]="c['fechasTexto']" matTooltipClass="salto-linea" matTooltipPosition="above" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" (click)="tooltip.toggle()" class="text-primary">Ver</a>
                                            </td>
                                            <td>
                                                <span *ngIf="c['track_a']"> {{c['track_a']}}</span>
                                                <span *ngIf="!c['track_a']"> N/A</span>
                                            </td>
                                            <td>
                                                <span *ngIf="!c['track_a']"> N/A</span>
                                                <span *ngIf="!c.video" matTooltip="Fecha Límite: {{ c.fecha_limite | date: 'dd-MM-y HH:mm' }}" matTooltipPosition="above">{{ c.estado_usuario_p }}</span>
                                                <span *ngIf="c.video">{{ c.estado_usuario_e }}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="!c['track_a']"> N/A</span>
                                                <div *ngIf="!habilitarVideo[c.id_certificacion]" class="btn-group" role="group" aria-label="Basic example">
                                                    <a *ngIf="c.evaluacion" target="_blank" matTooltip="Ver Assesment" matTooltipPosition="above" class="btn btn-sm btn-warning text-white" [href]="s3Url+c.evaluacion">
                                                        <i class="fas fa-paperclip"></i>
                                                    </a>

                                                    <button *ngIf="(c.dias_limite > 0 && !c.video) && (c.paso_fecha === false)" class="btn btn-sm btn-primary text-white" matTooltip="Enviar Vídeo" matTooltipPosition="above" (click)="habilitarVideo[c.id_certificacion] = true">
                                                <i class="fas fa-video"></i>
                                            </button>
                                                </div>
                                                <div *ngIf="habilitarVideo[c.id_certificacion]">
                                                    <mat-form-field *ngIf="!linkExito[c.id_certificacion]" class="w-100" appearance="outline" color="accent">
                                                        <mat-label>Vídeo</mat-label>
                                                        <input matInput [(ngModel)]="linkVideo[c.id_certificacion]" [disabled]="loadingVideo">
                                                        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="enviarVideo(c.id_certificacion, i)" [disabled]="loadingVideo">
                                                    <mat-icon>send</mat-icon>
                                                </button>
                                                    </mat-form-field>
                                                    <div *ngIf="linkExito[c.id_certificacion]" class="text-success text-center mt-2">
                                                        {{ linkExito[c.id_certificacion] }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 mt-5">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4 class="my-0 font-weight-normal">
                            HISTORIAL
                        </h4>
                        <hr>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="loading">
                            <div class="col-12">
                                <mat-spinner class="mx-auto mt-5"></mat-spinner>
                            </div>
                        </div>
                        <table *ngIf="!loading" datatable [dtOptions]="dtOpciones" class="table bg-white table-striped w-100 text-center">
                            <thead>
                                <tr>
                                    <th>Programa</th>
                                    <th>Estado</th>
                                    <th>Fechas</th>
                                    <th>N° Track</th>
                                    <th>Evaluación</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let c of certificaciones; let i = index">
                                    <tr *ngIf="c['nombre_estado'] === 'Terminada'">
                                        <td [matTooltip]="c.nombre_rango" matTooltipPosition="above">{{c['programa_nombre']}}</td>
                                        <td>{{c['nombre_estado']}}</td>
                                        <td>
                                            <span *ngFor="let f of c.fechas; let x = index"> 
                                        <span [matTooltip]="f.nombre" matTooltipPosition="above">
                                            {{ f.fecha_inicio | date: 'dd-MM-y'}}
                                            <span *ngIf="x < 2">
                                                -
                                            </span>
                                            </span>
                                            </span>
                                        </td>
                                        <td>{{c['track_a']}}</td>
                                        <td>
                                            <span *ngIf="!c.video" matTooltip="Fecha Límite: {{ c.fecha_limite | date: 'dd-MM-y HH:mm' }}" matTooltipPosition="above">{{ c.estado_usuario_p }}</span>
                                            <span *ngIf="c.video">{{ c.estado_usuario_e }}</span>
                                        </td>
                                        <td>
                                            <div *ngIf="!habilitarVideo[c.id_certificacion]" class="btn-group" role="group" aria-label="Basic example">
                                                <a *ngIf="c.evaluacion" target="_blank" matTooltip="Ver Assesment" matTooltipPosition="above" class="btn btn-sm btn-warning text-white" [href]="s3Url+c.evaluacion">
                                                    <i class="fas fa-paperclip"></i>
                                                </a>

                                                <button *ngIf="(c.dias_limite > 0 && !c.video) && (c.paso_fecha === false)" class="btn btn-sm btn-primary text-white" matTooltip="Enviar Vídeo" matTooltipPosition="above" (click)="habilitarVideo[c.id_certificacion] = true">
                                            <i class="fas fa-video"></i>
                                        </button>
                                            </div>
                                            <div *ngIf="habilitarVideo[c.id_certificacion]">
                                                <mat-form-field *ngIf="!linkExito[c.id_certificacion]" class="w-100" appearance="outline" color="accent">
                                                    <mat-label>Vídeo</mat-label>
                                                    <input matInput [(ngModel)]="linkVideo[c.id_certificacion]" [disabled]="loadingVideo">
                                                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="enviarVideo(c.id_certificacion, i)" [disabled]="loadingVideo">
                                                <mat-icon>send</mat-icon>
                                            </button>
                                                </mat-form-field>
                                                <div *ngIf="linkExito[c.id_certificacion]" class="text-success text-center mt-2">
                                                    {{ linkExito[c.id_certificacion] }}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- <div class="col-12 mt-4 text-center">
            <button class="btn btn-dark  rounded-0" type="submit" [routerLink]="['/certificaciones']">COMPRAR CERTIFICACIONES</button>
        </div> -->
    </div>
</div>