<div class="bg-foto h-100">
    <div id="olvido_clave" class="container main">
        <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div class="card my-2 mx-2">
                    <div class="card-body">
                        <h3 class="card-title text-center">RECUPERAR CONTRASEÑA</h3>
                        <hr>
                        <div *ngIf="!claveRecuperada">
                            <div *ngIf="!error" class="text-center pb-3 w-75 mx-auto">
                                Ingresa tu correo para recuperar tu contraseña.
                            </div>
                            <div *ngIf="error" class="text-center pb-2">
                                <mat-error>
                                    {{ mensajeError }}
                                </mat-error>
                            </div>
                            <form name="recuperarForm" [formGroup]="recuperarForm" (ngSubmit)="onSubmit()">
                                <mat-form-field class="w-100 mb-3" appearance="outline" color="accent">
                                    <mat-label>Correo</mat-label>
                                    <input matInput formControlName="email">
                                    <mat-icon matSuffix>mail</mat-icon>
                                    <mat-error *ngIf="recuperarForm.get('email').hasError('required')">
                                        El Correo es Obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="!recuperarForm.get('email').hasError('required') &&
                                                recuperarForm.get('email').hasError('email')">
                                        Por favor ingresa un correo válido
                                    </mat-error>
                                </mat-form-field>
                                <button *ngIf="!cargando" class="btn btn-lg btn-primary btn-block " type="submit">ENTRAR</button>
                                <button *ngIf="cargando" class="btn btn-lg btn-primary btn-block" type="submit" disabled>
                                    CARGANDO 
                                    <mat-icon>
                                        <mat-spinner class="mt-1 ml-3" color="primary" diameter="25"></mat-spinner>
                                    </mat-icon>
                                </button>
                            </form>
                        </div>
                        <div *ngIf="claveRecuperada">
                            <div [innerHTML]="mensajeRecuperacion" class="text-center pb-3 w-75 mx-auto">
                            </div>
                            <a class="btn btn-lg btn-primary btn-block" [routerLink]="'/login'">Inicia Sesión</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>