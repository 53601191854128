<div class="logo-repeat-bg">
    <div id="mis-compras" class="container mt-5 mb-5">
        <div class="row" *ngIf="loading">
            <div class="col-lg-10 col-11 mx-auto">
                <mat-spinner class="mx-auto mt-5"></mat-spinner>
            </div>
        </div>
        <div *ngIf="!loading" class="row">

            <div class="col-lg-10 col-11 mx-auto">
                <h3 class="card-title">LICENCIAS COMPRADAS</h3>
            </div>

            <div *ngIf="pagos" class="col-lg-10 col-12 mx-auto">
                <div class="desktop">
                    <table datatable [dtOptions]="opcionesLicenciasDesktop" class="table bg-white table-striped w-100 text-center">
                        <thead>
                            <tr>
                                <th>Tipo de Licencia</th>
                                <th>Fecha de Compra</th>
                                <th>Fecha de Vencimiento</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let p of pagos">
                                <tr>
                                    <td>{{p['tipoLicenciaNombre']}}</td>
                                    <td>{{p['fechaCompra'] | date: 'dd-MM-y HH:mm'}}</td>
                                    <td>{{p['fechaVencimiento'] | date: 'dd-MM-y HH:mm'}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="mobile ml-2 mr-2">
                    <table datatable [dtOptions]="opcionesLicenciasMobile" class="table bg-white table-striped w-100 text-center">
                        <thead>
                            <tr>
                                <th>Licencia</th>
                                <th>Compra</th>
                                <th>Vencimiento</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let p of pagos">
                                <tr>
                                    <td>{{p['tipoLicenciaNombre']}}</td>
                                    <td>{{p['fechaCompra'] | date: 'dd-MM-y'}}</td>
                                    <td>{{p['fechaVencimiento'] | date: 'dd-MM-y'}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-10 col-11 mx-auto">
                <br>
                <hr>
                <br>
            </div>
            <div class="col-lg-10 col-11 mx-auto">
                <h3 class="card-title">ACTUALIZACIONES COMPRADAS</h3>
            </div>
            <div *ngIf="programasTodos" class="col-lg-10 col-12 mx-auto">
                <div class="desktop">
                    <table datatable [dtOptions]="opcionesProgramasDesktop" class="table bg-white table-striped w-100 text-center">
                        <thead>
                            <tr>
                                <th>Programa</th>
                                <th>Mix</th>
                                <th>Periodo</th>
                                <th>Estado</th>
                                <!-- <th>Fecha de Entrega</th> -->
                                <th>Boleta</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let p of programasTodos; let i = index">
                                <tr *ngIf="p['estado'] == 1">
                                    <td>{{p['nombre']}}</td>
                                    <td>{{p['mix']}}</td>
                                    <td>{{p['periodo']}} - {{ p['ano'] }}</td>
                                    <td>Pagado</td>
                                    <!-- <td>NN</td> -->
                                    <td *ngIf="p['datos_pago']['n_documento']">{{p['datos_pago']['n_documento']}}</td>
                                    <td *ngIf="!p['datos_pago']['n_documento']">Sin Documento</td>
                                    <td>${{p['datos_pago']['monto_pagado'] | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="mobile mr-2 ml-2">
                    <table datatable [dtOptions]="opcionesProgramasMobile" class="table bg-white table-striped w-100 text-center">
                        <thead>
                            <tr>
                                <th>Programa</th>
                                <th>Mix</th>
                                <th>Periodo</th>
                                <!-- <th>Estado</th> -->
                                <!-- <th>Fecha de Entrega</th> -->
                                <th>Boleta</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let p of programasTodos; let i = index">
                                <tr *ngIf="p['estado'] == 1">
                                    <td>{{p['nombre']}}</td>
                                    <td>{{p['mix']}}</td>
                                    <td>{{p['periodo']}} - {{ p['ano'] }}</td>
                                    <!-- <td>Pagado</td> -->
                                    <!-- <td>NN</td> -->
                                    <td *ngIf="p['datos_pago']['n_documento']">{{p['datos_pago']['n_documento']}}</td>
                                    <td *ngIf="!p['datos_pago']['n_documento']">S/D</td>
                                    <td>${{p['datos_pago']['monto_pagado'] | currency:' ': 'symbol-narrow': '0.0-5': 'es'}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-lg-10 col-11 mx-auto">
                <br>
                <hr>
                <br>
            </div>
            <div class="col-lg-10 col-11 mx-auto">
                <h3 class="card-title">CERTIFICACIONES COMPRADAS</h3>
            </div>
            <div *ngIf="certificaciones" class="col-lg-10 col-12 mx-auto">
                <div class="desktop">
                    <table datatable [dtOptions]="opcionesCertificacionesDesktop" class="table bg-white table-striped w-100 text-center">
                        <thead>
                            <tr>
                                <th>Programa</th>
                                <th>Estado</th>
                                <th>Fechas</th>
                                <th>N° Track</th>
                                <th>Evaluación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let c of certificaciones; let i = index">
                                <tr>
                                    <td [matTooltip]="c.nombre_rango" matTooltipPosition="above">{{c['programa_nombre']}}</td>
                                    <td>{{c['nombre_estado']}}</td>
                                    <td>
                                        <span *ngFor="let f of c.fechas; let x = index"> 
                                <span [matTooltip]="f.nombre" matTooltipPosition="above">
                                    {{ f.fecha_inicio | date: 'dd-MM-y'}}
                                    <span *ngIf="x < 2">
                                        -
                                    </span>
                                        </span>
                                        </span>
                                    </td>
                                    <td>{{c['track_a']}}</td>
                                    <td>
                                        <span *ngIf="!c.video" matTooltip="Fecha Límite: {{ c.fecha_limite | date: 'dd-MM-y HH:mm' }}" matTooltipPosition="above">{{ c.estado_usuario_p }}</span>
                                        <span *ngIf="c.video">{{ c.estado_usuario_e }}</span>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="mobile mr-2 ml-2">
                    <table datatable [dtOptions]="opcionesCertificacionesMobile" class="table bg-white table-striped w-100 text-center">
                        <thead>
                            <tr>
                                <th>Programa</th>
                                <th>Fechas</th>
                                <th>Boleta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let c of certificaciones; let i = index">
                                <tr>
                                    <td [matTooltip]="c.nombre_rango" matTooltipPosition="above">{{c['programa_nombre']}}</td>
                                    <td>
                                        <span *ngFor="let f of c.fechas; let x = index"> 
                                            <span [matTooltip]="f.nombre" matTooltipPosition="above">
                                                {{ f.fecha_inicio | date: 'dd-MM-y'}}
                                            </span>
                                        <br>
                                        </span>
                                    </td>
                                    <td>
                                        S/D
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>