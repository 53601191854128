<section class="main-home">
    <header>
        <div class="overlay"></div>
        <video playsinline="playsinline" autoplay="autoplay" [muted]="'muted'" loop="loop">
            <source src="/assets/images/videoHeader_Trim.mp4" type="video/mp4">
        </video>
        <div class="container h-100">
            <div class="d-flex h-100 text-center align-items-center">
                <div class="w-100 text-white">
                    <h1 class="display-3">LICENCIA DE INSTRUCTOR </h1>
                    <!--<p class="lead mb-0">Desc</p>-->
                    <a href="/licencias" class="btn btn-warning mt-3 p-2 pl-4 pr-4">MÁS INFORMACIÓN</a>
                </div>
            </div>
        </div>
    </header>
    <!--<img class="img-fluid img-home w-100" src="/assets/images/home/main.png">-->
</section>

<section class="main-mobile">
    <header class="masthead">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12 text-center">
                    <h1 class="display-3">LICENCIA DE INSTRUCTOR</h1>
                    <a href="/licencias" class="btn btn-warning mt-3 p-2 pl-4 pr-4">MÁS INFORMACIÓN</a>
                </div>
            </div>
        </div>
    </header>
</section>

<section class="wrd-block">
    <div class="container-xl">
        <img class="img-fluid" src="/assets/images/home/title-home.png">
        <div class="row justify-content-center align-items-center">
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/home/col-1-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/home/col-2-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/home/col-3-2.png">
            </div>
            <div class="col-6 col-md-3 m-0 p-0">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/home/col-4-2.png">
            </div>
        </div>
    </div>
</section>

<section class="main wrd-block p-0 main-gallery">
    <div class="mb-5 row justify-content-center align-items-center">

        <owl-carousel-o class="owl-carousel owl-theme owl-loaded" [options]="customOptions">
            <ng-container>
                <ng-template carouselSlide id="ba">
                    <a href="/programas/bodyattack"><img alt="image" src="/assets/images/home/col-1-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="bp">
                    <a href="/programas/bodypump"><img alt="image" src="/assets/images/home/col-2-1v2-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="bc">
                    <a href="/programas/bodycombat"><img alt="image" src="/assets/images/home/col-3-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="bb">
                    <a href="/programas/bodybalance"><img alt="image" src="/assets/images/home/col-4-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="bj">
                    <a href="/programas/bodyjam"><img alt="image" src="/assets/images/home/col-5-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="gr">
                    <a href="/programas/grit"><img alt="image" src="/assets/images/home/col-6-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="rpm">
                    <a href="/programas/rpm"><img alt="image" src="/assets/images/home/col-7-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="sp">
                    <a href="/programas/sprint"><img alt="image" src="/assets/images/home/col-8-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="cx">
                    <a href="/programas/cxworx"><img alt="image" src="/assets/images/home/col-9-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="pj">
                    <a href="/programas/powerjump"><img alt="image" src="/assets/images/home/col-10-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="sb">
                    <a href="/programas/shbam"><img alt="image" src="/assets/images/home/col-11-1-min.png"></a>
                </ng-template>
            </ng-container>
            <ng-container>
                <ng-template carouselSlide id="bs">
                    <a href="/programas/bodystep"><img alt="image" src="/assets/images/home/col-12-1-min.png"></a>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>

<section class="main-nopain set-bg wrd-block">
    <img class="img-fluid w-100" src="/assets/images/home/content-homev2.png">
    <div class="container">
        <div class="row justify-content-center align-items-center text-center text-body">
            <div class="col-6 col-md-4">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/home/col-1-3.png">
                <p>Unirse a Les Mills, es crear experiencias que cambian vidas, una clase a la vez. Es más que un trabajo, es un viaje conducido a través de una pasión.</p>
            </div>
            <div class="col-6 col-md-4">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/home/col-2-3.png">
                <p>Las actualizaciones son la última herramienta que tienen los instructores para equiparse con música nueva, los últimos ejercicios y los mejores estudios en la industria </p>
            </div>
            <div class="col-6 col-md-4">
                <img alt="image" class="img-fluid w-100 h-100" src="/assets/images/home/col-3-3.png">
                <p>Las personas son el corazón de tu negocio. Un equipo de instructores altamente capacitado en las mejores clases grupales, puede atraer y retener cientos de afiliaciones.</p>
            </div>
        </div>
    </div>
</section>