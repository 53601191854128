<div class="logo-repeat-bg">
    <div id="comprar" class="container pt-5 pb-5 mt-5 mb-5">
        <form name="loginForm" [formGroup]="compraForm" (ngSubmit)="abrirTerminosCondiciones()">

            <div class="row">
                <div class="col-lg-12" *ngIf="!licenciaActiva && !usuarioNuevo && !cargandoLicencia">
                    <div class="alert alert-warning">
                        Para inscribirte en una certificación necesitas estar asociado a un <a class="text-primary" routerLink="/gimnasios">Gimnasio Licenciado.</a> o tener una <a class="text-primary" routerLink="/panel/licencias">Licencia de Instructor</a>                        Activa
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="yaTienePrograma">
                    <div class="alert alert-danger">
                        No puedes comprar ésta certificación porque ya estás certificado en <b>{{ cert.programa_nombre }}</b>.
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="yaTieneCert">
                    <div class="alert alert-danger">
                        No puedes comprar ésta certificación porque ya estás inscrito en éste módulo.
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="muchasCertPendientes">
                    <div class="alert alert-danger">
                        No puedes comprar ésta certificación porque tienes dos certificaciones sin finalizar.
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="certMismoDia">
                    <div class="alert alert-danger">
                        No puedes comprar ésta certificación porque las fechas coinciden con otra certificación comprada.
                    </div>
                </div>
                <div *ngIf="!yaTienePrograma && !yaTieneCert && !muchasCertPendientes && !certMismoDia" class="col-md-12 col-lg-8">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="card-title text-center">Información de Facturación</h3>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Correo</mat-label>
                                        <input matInput formControlName="email">
                                        <mat-icon matSuffix>mail</mat-icon>
                                        <mat-error *ngIf="compraForm.get('email').hasError('required')">
                                            El Correo es Obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="!compraForm.get('email').hasError('required') && compraForm.get('email').hasError('email')">
                                            Por favor ingresa un correo válido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Nombres</mat-label>
                                        <input matInput formControlName="nombres">
                                        <mat-error *ngIf="compraForm.get('nombres').hasError('required')">
                                            El Nombre es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Apellidos</mat-label>
                                        <input matInput formControlName="apellidos">
                                        <mat-error *ngIf="compraForm.get('apellidos').hasError('required')">
                                            El Apellido es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Rut</mat-label>
                                        <input matInput formControlName="rut" formatRut>
                                        <mat-icon matSuffix>perm_identity</mat-icon>
                                        <mat-error *ngIf="compraForm.get('rut').hasError('required')">
                                            El Rut es Obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="compraForm.get('rut').hasError('invalidRut')">
                                            El Rut es inválido
                                        </mat-error>
                                        <mat-error *ngIf="compraForm.get('rut').hasError('rutExiste')">
                                            El Rut ya esta registrado
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Teléfono</mat-label>
                                        <mat-icon matSuffix>local_phone</mat-icon>
                                        <input mask="(0) 0000-0000" matInput formControlName="telefono">
                                        <mat-error *ngIf="compraForm.get('telefono').hasError('required')">
                                            El Teléfono es Obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>
                                            Región
                                            <mat-spinner class="progress-spinner-inline" *ngIf="loadingR" class="float-right" diameter="20"></mat-spinner>
                                        </mat-label>
                                        <mat-select [disabled]="loadingR" formControlName="region" (selectionChange)="obtenerComunas($event.value)">
                                            <mat-option *ngFor="let r of region" [value]="r.id">{{ r.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="compraForm.get('region').hasError('required')">
                                            La Región es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>
                                            Comuna
                                            <mat-spinner class="progress-spinner-inline" *ngIf="loadingCS" class="float-right" diameter="20"></mat-spinner>
                                        </mat-label>
                                        <mat-select [disabled]="loadingC" formControlName="comuna">
                                            <mat-option *ngFor="let r of comuna" [value]="r.id">{{ r.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="compraForm.get('comuna').hasError('required')">
                                            La Comuna es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <mat-form-field class="w-100" appearance="outline" color="accent">
                                        <mat-label>Dirección</mat-label>
                                        <input matInput formControlName="direccion">
                                        <mat-icon matSuffix>place</mat-icon>
                                        <mat-error *ngIf="compraForm.get('direccion').hasError('required')">
                                            La Dirección es Obligatoria
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">

                                    <mat-checkbox (change)="terminosPress($event)" color="accent" formControlName="terminos">
                                        He leído y acepto los
                                        <a (click)="abrirPolitica()">Política de Privacidad</a>
                                    </mat-checkbox>
                                    <mat-error *ngIf="errorCheckbox">
                                        Debes aceptar la Política de Privacidad
                                    </mat-error>
                                    <!-- <mat-error *ngIf="terminosAceptados">
                                        Debes aceptar los términos y condiciones
                                    </mat-error> -->
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div *ngIf="!yaTienePrograma && !yaTieneCert && !muchasCertPendientes && !certMismoDia" class="col-md-12 col-lg-4 mt-md-5 mt-sm-5 mt-lg-0">
                    <div class="card">
                        <div class="card-body p-4">
                            <mat-spinner class="mx-auto" diameter="50" *ngIf="!cert" color="primary"></mat-spinner>
                            <div *ngIf="cert">
                                <h4 class="text-center">{{ cert.programa_nombre }} {{ cert.nombre_rango |uppercase }} {{ cert.nombre_tipo | uppercase}}</h4>
                                <hr>
                                <div class="row">
                                    <div class="col-6 text-left">
                                        <span *ngIf="cert.entrenadores.length === 0">Entrenador:</span>
                                        <span *ngIf="cert.entrenadores.length > 0">Entrenadores:</span>
                                    </div>
                                    <div class="col-6 text-right">
                                        {{ cert.entrenadores | entrenadores }}
                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngFor="let fecha of cert.fechas">
                                    <div class="col-6 text-left">
                                        {{ fecha.nombre }}
                                    </div>
                                    <div class="col-6 text-right">
                                        {{ fecha.fecha_inicio | date: 'EEEE':'':'es' | titlecase}} {{ fecha.fecha_inicio | date: 'd \'de\' MMMM':'':'es'}}
                                    </div>
                                    <div class="col-6 text-left">

                                    </div>
                                    <div class="col-6 text-right">
                                        {{ fecha.fecha_inicio | date: 'HH:mm \'a\'':'':'es'}} {{ fecha.fecha_termino | date: 'HH:mm' }}
                                    </div>
                                    <hr>
                                </div>
                                <hr>
                                <h5>Requisitos:</h5>
                                <ul>
                                    <li>Computador o tablet</li>
                                    <li>Smartphone</li>
                                    <li>Conexión a internet estable</li>
                                    <li *ngIf="cert.programa_nombre == 'RPM'">Bicicleta estática / indoor.</li>
                                    <li *ngIf="cert.programa_nombre == 'SPRINT'">Bicicleta estática / indoor.</li>
                                    <li *ngIf="cert.programa_nombre == 'POWER JUMP'">Minitramp.</li>
                                    <li *ngIf="cert.programa_nombre == 'BODYPUMP'">Barra y discos (Kit de BODYPUMP).</li>
                                    <li *ngIf="cert.programa_nombre == 'GRIT'">Barra y discos (Kit de BODYPUMP).</li>
                                    <li *ngIf="cert.programa_nombre == 'BODYSTEP'">Step.</li>
                                    <li *ngIf="cert.programa_nombre == 'CORE'">Banda elástica y discos.</li>
                                </ul>
                                <hr>
                                <h5>
                                    <a class="toggle" data-toggle="collapse" href="#cupon" role="button" aria-expanded="false" aria-controls="cupon">
                                    Cupón de Descuento <i class="fas fa-angle-down text-right"></i>
                                </a>
                                </h5>

                                <div class="collapse multi-collapse" id="cupon">
                                    <mat-form-field class="w-100 mt-3" appearance="outline" color="accent">
                                        <mat-label>Cupón</mat-label>
                                        <input matInput formControlName="cupon" [disabled]="loadingCupon">
                                        <button mat-button *ngIf="compraForm.get('cupon').value" matSuffix mat-icon-button aria-label="Clear" (click)="checkCupon()" [disabled]="loadingCupon">
                                        <mat-icon>send</mat-icon>
                                    </button>
                                    </mat-form-field>
                                    <mat-error class="text-center" *ngIf="errorCupon">
                                        {{ errorCupon }}
                                    </mat-error>
                                    <div *ngIf="cuponExito" class="text-success text-center">
                                        {{ cuponExito }}
                                    </div>
                                </div>

                                <hr>

                                <div *ngIf="descuento > 0" class="row">
                                    <div class="col-6">
                                        <h5>Descuento</h5>
                                    </div>
                                    <div class="col-6 text-right">
                                        <h5 class="text-danger">- {{ descuento | currency:' ': 'symbol-narrow': '0.0-5': 'es' }}</h5>
                                    </div>
                                </div>
                                <div *ngIf="usuarioNuevo" class="row">
                                    <div class="col-6">
                                        <h5>Certificación</h5>
                                    </div>
                                    <div class="col-6 text-right">
                                        <h5>{{ cert.precio | currency:' ': 'symbol-narrow': '0.0-5': 'es' }}</h5>
                                    </div>
                                </div>
                                <div *ngIf="usuarioNuevo" class="row">
                                    <div class="col-6">
                                        <h5>Licencia</h5>
                                    </div>
                                    <div class="col-6 text-right">
                                        <h5>{{ cert.costoLicenciaNuevoUsuario | currency:' ': 'symbol-narrow': '0.0-5': 'es' }}</h5>
                                    </div>
                                </div>
                                <hr *ngIf="usuarioNuevo">
                                <div class="row">
                                    <div class="col-6">
                                        <h4>Total</h4>
                                    </div>
                                    <div class="col-6 text-right">
                                        <h4>{{ cert.precio - descuento | currency:' ': 'symbol-narrow': '0.0-5': 'es' }}</h4>
                                    </div>
                                </div>
                                <div *ngIf="licenciaFull" class="row">
                                    <div class="col-12 text-center">
                                        <h5>Descuento por Licencia Pro</h5>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <button *ngIf="licenciaActiva || usuarioNuevo" class="btn btn-lg btn-primary mt-3" type="submit">CONTINUAR</button>
                                    <a *ngIf="!licenciaActiva && !usuarioNuevo" class="btn btn-lg btn-dark mt-3" routerLink="/panel/licencias">CONTINUAR</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>