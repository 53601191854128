import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GimnasioService {

  constructor(
    private http: HttpClient,
    private token: TokenStorageService
  ) { }

  getGimnasiosLicenciados(): Observable<any> {
    return this.http.get(environment.api_url + 'getGimnasiosLicenciados', { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }
  
  getUsuarioGimnasioLicenciado(): Observable<any> {
    return this.http.get(environment.api_url + 'getUsuarioGimnasioLicenciado', { 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token.getToken()
      }), 
      responseType: 'json' 
    });
  }


}
