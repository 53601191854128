import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CertificacionesService } from '../../../services/certificaciones.service';
import { Certificaciones } from '../../../models/certificaciones.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-certificaciones',
  templateUrl: './certificaciones.component.html',
  styleUrls: ['./certificaciones.component.scss']
})
export class CertificacionesPanelComponent implements OnInit {

  tokenWs: string;
  dtOpcionesDesktop: DataTables.Settings = {};
  dtOpcionesMobile: DataTables.Settings = {};
  certificacionNueva: Certificaciones;
  certificaciones: Certificaciones[];
  loading = true;
  linkExito =  Array();
  linkVideo = Array();
  loadingVideo = false;
  habilitarVideo = Array();
  s3Url = environment.s3_public_url + environment.evaluaciones;

  constructor(
    private routerA: ActivatedRoute,
    private cert: CertificacionesService
  ) {
    

    this.tokenWs = this.routerA.snapshot.paramMap.get('tokenWs');
    this.cert.getCertificacionesByUser().subscribe(
      data => {
        this.certificaciones = data.data;
        console.log(this.certificaciones);
        if(!this.tokenWs){
          this.loading = false;
        }
      }
    );
    if(this.tokenWs){
      this.cert.getCertificacionByToken(this.tokenWs).subscribe(
        data => {
          this.certificacionNueva = data.data;
          this.loading = false;
        }
      );
    }
  }

  ngOnInit(): void {
    
    this.dtOpcionesDesktop = {
      language: environment.lenguajeDatatables,
      // order: [[3, "desc"]],
      dom: "t",
      // scrollX: true
      ordering: false,
    };
    this.dtOpcionesMobile = {
      language: environment.lenguajeDatatables,
      // order: [[3, "desc"]],
      dom: "t",
      // scrollX: true
      ordering: false,
    };
  }

  enviarVideo(idCertificacion: number, index: number): void{
    if(this.linkVideo[idCertificacion]){
      this.loadingVideo = true;
      console.log(this.linkVideo[idCertificacion]);
      this.cert.enviarVideo(this.linkVideo[idCertificacion], idCertificacion).subscribe(
        data => {
          this.linkExito[idCertificacion] = data.message;
          this.certificaciones[index].video = this.linkVideo[idCertificacion];
          this.loadingVideo = false;
        }
      );
    }
    
  }

}
