import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from '../../../services/token-storage.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-olvido_clave',
  templateUrl: './olvido_clave.component.html',
  styleUrls: ['./olvido_clave.component.scss']
})
export class OlvidoClaveComponent implements OnInit {

  recuperarForm: FormGroup;
  Logeado = false;
  error = false;
  mensajeError: string;
  cargando = false;
  claveRecuperada = false;
  mensajeRecuperacion: string;

  constructor(
    private tokenStorage: TokenStorageService,
    private user: UserService, 
    private route: Router,
    private routerA: ActivatedRoute,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    if (this.tokenStorage.checkToken()) {
        this.Logeado = true;
        this.route.navigate(['/inicio']); 
    }
    this.recuperarForm = this.fb.group({
        email   : ['', [Validators.required, Validators.email]],
    });
  }

  
  onSubmit(): void {
    this.error = false;
    if(this.recuperarForm.valid){
      this.cargando = true;
      this.user.olvido_clave(this.recuperarForm).subscribe(
        data => {
          console.log(data);
          if(data.data){
            this.claveRecuperada = true;
            this.mensajeRecuperacion = data.message
          }else{
            this.cargando = false;
            this.error = true;
            this.mensajeError = data.message
          }
        }
      );
    }
}

}
