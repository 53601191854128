<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-bb.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-bb">¿QUÉ ES BODYBALANCE?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">BODYBALANCE es un programa de entrenamiento inspirado en el Yoga, que mejora la flexibilidad, la fuerza y te aporta una sensación de calma, armonía y bienestar.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-bb">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">MEJORA TU</h3>
                        <h1 class="p-0 m-0 title-color-bb"><strong>FLEXIBILIDAD</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">SIÉNTETE EN</h3>
                        <h1 class="p-0 m-0 title-color-bb"><strong>CALMA</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">MEJORA TU</h3>
                        <h1 class="p-0 m-0 title-color-bb"><strong>FUERZA</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON BODYBALANCE?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify"></p>
                <ul class="desc-programs">
                    <li>Los instructores te mostrarán las mejores opciones para adaptar la clase de BODYBALANCE a tu nivel actual.</li>
                    <li>El primer día realiza cuatro o cinco tracks y progresa hasta realizar una clase completa.</li>
                </ul>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-bb">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Aumenta la fuerza, flexibilidad y la amplitud de movimientos</li>
                            <li>Reduce los niveles de estrés</li>
                            <li>Aporta sensación de calma y bienestar</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <p class="desc-programs text-center">
                            Colchoneta de yoga o mat
                        </p>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>390</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            55, 45 ó 30 minutos
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-bb.mp4" type="video/mp4">
     </video>
    </div>

</section>