import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shbam',
  templateUrl: './shbam.component.html',
  styleUrls: ['./shbam.component.scss']
})
export class ShbamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
