import { Component, OnInit } from '@angular/core';
import { LicenciasService } from '../../services/licencias.service';
import { Licencias } from '../../models/licencias.model';

@Component({
  selector: 'app-licencias',
  templateUrl: './licencias.component.html',
  styleUrls: ['./licencias.component.scss']
})
export class LicenciasComponent implements OnInit {

  licencias: Licencias[];

  constructor(
    private licencia: LicenciasService,
  ) { }

  ngOnInit(): void {
    this.licencia.getLicencias().subscribe(
      data =>{
        this.licencias = data.data;
      }
    );
    console.log(this.licencias);
  }

}
