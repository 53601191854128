import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agregarDiasFecha'
})
export class AgregarDiasFechaPipe implements PipeTransform {

  transform(value: Date, dias: number): unknown {

    // console.log(dias);
    value = new Date(value);
    value.setDate( value.getDate() + dias);
    // console.log(value);
    
    let retornar = ("0" + value.getDate()).slice(-2) + "-" + ("0"+(value.getMonth()+1)).slice(-2) + "-" + value.getFullYear();

    return retornar;
  }

}
