<style>
    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }
    
    a {
        color: #000 !important;
        font-weight: bold !important
    }
    
    tr,
    td {
        border: 1px solid #000;
        padding: 0rem 0.3rem;
    }
    
    @media only screen and (min-width: 768px) {
        .margenes {
            margin: 15px !important;
        }
    }
</style>
<h1 mat-dialog-title>
    Anexo Acuerdo de Instructor y/o Contrato de Acreditación de Instructores - LICENCIA INSTRUCTOR <span *ngIf="idLicencia == 1">BÁSICA</span><span *ngIf="idLicencia == 2">PRO</span>
</h1>
<div mat-dialog-content class="margenes">
    <div class="text-justify">
        <p>TÉRMINOS CLAVE:</p>
        <table>
            <tr>
                <td>Programas de Licencia (Programas Autorizados)</td>
                <td>Todos los programas en los que se encuentre certificado El Instructor</td>
            </tr>
            <tr>
                <td>Período mínimo de compromiso</td>
                <td>12 meses</td>
            </tr>
            <tr>
                <td>Cuota {{ tipoPago | titlecase}} de Licencia</td>
                <td>{{precio | currency:' ': 'symbol-narrow': '0.0-0': 'es'}} pesos chilenos</td>
            </tr>
            <tr>
                <td>Fecha de inicio de la facturación</td>
                <td>{{ fechaHoy | date: 'MMMM yyyy':'':'es' | titlecase}}</td>
            </tr>
            <tr>
                <td>Territorio</td>
                <td>Chile</td>
            </tr>
        </table>
        <br>
        <p>ANTECEDENTES</p>

        <p><b>Usted {{ usuario['name'] | uppercase }} {{ usuario['apellidos'] | uppercase }} RUT {{ usuario['rut'] | rut }},</b> tiene un contrato de instructor con Body Systems Chile S.A. <b>(&ldquo;<b>Nosotros</b> &rdquo;)</b> en virtud del cual le hemos
            concedido el derecho a dirigir los Programas LES MILLS&reg; en instalaciones autorizadas <b>(&quot;Acuerdo de Instructor y/o Contrato de Acreditación de Instructores&quot;)</b>.</p>

        <p>Deseamos conceder la licencia de ciertos Programas (como los identificados anteriormente) directamente a trav&eacute;s de los instructores de Les Mills en Chile, en virtud de la cual concederemos a ciertos Instructores el derecho a dirigir dichos
            Programas en cualquier lugar. <b>Usted</b> y <b>Nosotros</b> hemos acordado modificar el Acuerdo de Instructor y/o Contrato de Acreditación de Instructores a&ntilde;adiendo esta Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span>
            <span *ngIf="idLicencia == 2">Pro</span> como Anexo a su Acuerdo de Instructor y/o Contrato de Acreditación de Instructores.</p>

        <p><b>ACUERDO.</b></p>

        <p><b>1. Derechos concedidos al Instructor:</b> Le concedemos una licencia no exclusiva, intransferible, no sublicenciable y revocable de forma limitada para utilizar los Programas de Les Mills y la Propiedad Intelectual de Les Mills para impartir
            clases en cualquier ubicaci&oacute;n dentro de Chile en los t&eacute;rminos establecidos en este Anexo <b>(&quot;Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span>&quot;)</b>.</p>

        <p><b>2. Acuerdo de Instructor y/o Contrato de Acreditación de Instructores:</b> Para evitar dudas, los t&eacute;rminos y condiciones establecidos en su Acuerdo de Instructor y/o Contrato de Acreditación de Instructores se aplicar&aacute;n a esta
            Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span>, a menos que el contexto requiera lo contrario. En caso de inconsistencia entre los t&eacute;rminos establecidos en el Acuerdo de Instructor
            y/o Contrato de Acreditación de Instructores y los t&eacute;rminos establecidos en este Anexo, los t&eacute;rminos establecidos en este Anexo prevalecer&aacute;n.</p>

        <p><b>3. Certificaci&oacute;n:</b> Para ser elegible para la Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span>, Usted debe estar certificado en el o los Programas Licenciados. Todas las certificaciones
            de Programas Licenciados que tenga en curso, será agregadas a esta Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span> una vez que estén finalizadas y aprobadas bajo el estado de Certificado
            en el programa en cuestión.
        </p>

        <p>4. La presente Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span> podr&aacute; ser utilizada de forma presencial o en transmisi&oacute;n en vivo, de acuerdo a la siguientes condiciones:</p>

        <p><b>a) Ubicaciones Presenciales:</b> significa cualquier lugar presencial, ya sea en interior de un recinto o al exterior del mismo (parques, plazas u otros). Podemos notificarle de vez en cuando que ciertos lugares est&aacute;n excluidos de esta
            Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span>.
        </p>

        <p><b>b) Transmisi&oacute;n en vivo:</b> Durante la vigencia de este Anexo <b>Usted</b> tendr&aacute; la facultad de realizar transmisiones en vivo de Los Programas Autorizados, &uacute;nica y exclusivamente en plataformas de videotelefon&iacute;a/videconferencia
            privadas tales como como <b>Zoom, Google Meet</b> o <b>Teams</b>. Queda estrictamente prohibido: <br> I) Publicar clases pregrabadas. S&oacute;lo se permite transmitir clases en vivo <br> II) Utilizar redes sociales y plataformas tales como
            Facebook, Instagram y Youtube, entre otras, que son abiertas. Si no cumple con esta cl&aacute;usula, nos reservamos el derecho de suspender su Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span>            para un programa concreto de Les Mills o para todos los programas de Les Mills. Si suspendemos su certificaci&oacute;n y/o esta Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span>, se aplicar&aacute;
            la cl&aacute;usula 13.</p>

        <p><b>5. Derechos reservados: </b> <b>Nosotros</b> y/o Les Mills International, a su entera discreci&oacute;n, podemos cambiar la lista y el contenido de los Programas de Les Mills, los Comunicados de Les Mills, los Recursos de Marketing, los Materiales
            y/o la Propiedad Intelectual de Les Mills, en cualquier momento y sin previo aviso.</p>

        <p><b>6. Vigencia:</b> El presente anexo comenzar&aacute; en la fecha en que <b>Usted</b> y <b>Nosotros</b> firmemos este acuerdo y continuar&aacute; durante el Per&iacute;odo de Compromiso M&iacute;nimo. Una vez finalizado el Periodo de Compromiso
            M&iacute;nimo, este Ap&eacute;ndice continuar&aacute; hasta que se rescinda de acuerdo con este Ap&eacute;ndice.</p>

        <p><b>7. Cuota de Licencia: </b> <b>Usted</b> nos pagar&aacute; la Cuota de Licencia {{ tipoPago | titlecase}} establecida en los T&eacute;rminos Clave para los Programas Autorizados. La Cuota {{ tipoPago | titlecase}} de Licencia incluir&aacute;
            su licencia para ense&ntilde;ar en cualquier lugar y sus Desarrollos Continuos para el Programa Autorizado. <b>Nosotros</b> le emitiremos una factura o boleta y <b>Usted</b> pagar&aacute; la Cuota {{ tipoPago | titlecase}} de Licencia en la
            Fecha de Inicio de la Facturaci&oacute;n (y cada
            <span *ngIf="tipoPago == 'trimestral'">trimestre</span><span *ngIf="tipoPago == 'anual'">año</span><span *ngIf="tipoPago == 'semestral'">semestre</span> subsiguiente despu&eacute;s de la Fecha de Inicio de la Facturaci&oacute;n) y todos los
            dem&aacute;s importes en la fecha de vencimiento indicada en la factura o boleta correspondiente mediante el m&eacute;todo de pago que establezcamos en cada momento (dicho m&eacute;todo de pago puede incluir que cobremos las cuotas a trav&eacute;s
            de una plataforma de pago de terceros). Podremos modificar la Cuota {{ tipoPago | titlecase}} de Licencia notific&aacute;ndole a <b>Usted</b> con 30 d&iacute;as de antelaci&oacute;n, siempre y cuando la fecha m&aacute;s temprana en que entre
            en vigor cualquier modificaci&oacute;n de la Cuota {{ tipoPago | titlecase}} de Licencia sea despu&eacute;s de la expiraci&oacute;n de cualquier Per&iacute;odo M&iacute;nimo de Compromiso. Si le notificamos un cambio de precio y <b>Usted</b>            no desea continuar con su Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span> al nuevo precio, puede terminar este Anexo antes de que el cambio de precio entre en vigor, notific&aacute;ndonos
            por escrito al mail instructor@bodysystems.cl.</p>

        <p><b>8. Mantenimiento de su Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span> en el o los Programas Autorizados:</b> Para mantener su Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span>
            <span *ngIf="idLicencia == 2">Pro</span> en el o los Programas Autorizados, deber&aacute; mantener su certificaci&oacute;n en el o los Programas Autorizados y:<br> (a) pagar la Cuota {{ tipoPago | titlecase}} de Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span>
            <span *ngIf="idLicencia == 2">Pro</span>;<br> (b) completar el n&uacute;mero de sesiones de Desarrollo Continuo requeridas en el momento correspondiente, Si no cumple con los requisitos copulativos se&ntilde;alados en esta cl&aacute;usula,
            nos reservamos el derecho de suspender su Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span> para un programa concreto de Les Mills o para todos los programas de Les Mills. Si suspendemos
            su certificaci&oacute;n y/o esta Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span>, se aplicar&aacute; la cl&aacute;usula 13.
        </p>

        <p><b>9. Licencias de m&uacute;sica: </b> <b>Usted</b> es responsable de asegurarse de que las organizaciones de derechos musicales pertinentes le concedan las licencias de ejecuci&oacute;n/comunicaci&oacute;n p&uacute;blica necesarias para el uso
            de la m&uacute;sica en el o los Programa Autorizados.</p>

        <p><b>10. Marketing y promoci&oacute;n:</b> Le proporcionaremos recursos de marketing. <b>Usted</b> podr&aacute; publicar, distribuir y utilizar los Recursos de Marketing y utilizar las Marcas Registradas &uacute;nicamente con el fin de llevar a
            cabo o promocionar el o los Programas Autorizados y de acuerdo con las Directrices de la Marca Les Mills (o cualquier otra indicaci&oacute;n que le demos en cada momento). El uso de estos materiales de marketing de Les Mills est&aacute;n autorizados
            durante la vigencia de este Anexo y son exclusivamente para su uso propio (mailing, redes sociales y paginas web personales). Para evitar cualquier duda, los centros en los que imparta las clases de &eacute;l o los Programas Autorizados no
            est&aacute;n autorizados para publicar dichos contenidos. Ser&aacute; de su responsabilidad contactarnos con los centros en los que <b>Usted</b> imparta clases para que <b>Nosotros</b> les proporcionemos los Recursos de Marketing especificos
            que hayamos designado para ellos (incluidas las Marcas y los logotipos para sus horarios). Si no cumple con esta cl&aacute;usula, nos reservamos el derecho de suspender su Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span>
            <span *ngIf="idLicencia == 2">Pro</span> para un programa concreto de Les Mills o para todos los programas de Les Mills. Si suspendemos su certificaci&oacute;n y/o esta Licencia Instructor <span *ngIf="idLicencia == 1">Básica</span><span *ngIf="idLicencia == 2">Pro</span>,
            se aplicar&aacute; la cl&aacute;usula 13.</p>
        <p>Se deja constancia en este anexo de contrato que esta Licencia Instructor <b>NO INCLUYE LA PLATAFORMA DE CONTENIDOS DIGITALES LES MILLS MARKETING STUDIO</b></p>

        <p><b>11. Presentaci&oacute;n de informes: </b> <b>Usted</b> mantendr&aacute; un registro de clases en un formulario proporcionado por <b>Nosotros</b> en el que se anotar&aacute;n <br> (a) todas las ubicaciones en las que imparte las clases de los
            Programas Autorizados; <br> (b) n&uacute;mero y tipo de clases;<br> (c) n&uacute;meros de asistencia a las clases; <br> (d) los ingresos y gastos; <br> (e) cualquier otra informaci&oacute;n que le solicitemos razonablemente de vez en cuando,
            y <b>Usted</b> nos proporcionar&aacute; dicho registro de forma regular seg&uacute;n lo solicitemos razonablemente;</p>

        <p><b>12. Inspecci&oacute;n:</b> Sujeto a los requisitos de seguridad razonables de un lugar, <b>Usted</b> se asegurar&aacute; de que podamos visitar en cualquier momento cualquier lugar donde <b>Usted</b> ense&ntilde;e un Programa con Licencia para
            evaluar su cumplimiento con este Anexo y el Acuerdo de Instructor y/o Contrato de Acreditación de Instructores, siempre y cuando dicha inspecci&oacute;n debe ser durante el horario comercial normal, no interferir&aacute; con sus operaciones
            comerciales normales y est&aacute; sujeta a los requisitos de seguridad razonables de un lugar.</p>

        <p><b>13. P&eacute;rdida de la Licencia de Instructor:</b> Si <b>Usted</b> incumple este Anexo y/o el Acuerdo de Instructor y/o Contrato de Acreditación de Instructores, podemos retirarle inmediatamente la licencia mediante una notificaci&oacute;n.
            Esto puede ser para un Programa Licenciado en particular o (si es aplicable) para todos los Programas. Si le retiramos la licencia, las cl&aacute;usulas 19(a) a (c) de este Addendum le ser&aacute;n de aplicaci&oacute;n para el/los Programa(s)
            en cuesti&oacute;n. Podremos, a nuestra discreci&oacute;n, restablecer su licencia si <b>Usted</b> acepta cumplir ciertas condiciones que le comunicaremos de vez en cuando. Estas condiciones pueden incluir el pago de las cuotas de licencia
            atrasadas o no pagadas, la asistencia a las sesiones de Desarrollo Continuo, volver a asistir a la formaci&oacute;n, y/o compensarnos por compartir un lanzamiento de Les Mills o ense&ntilde;ar sin una licencia activa.</p>

        <p><b>14. Descargo de responsabilidad:</b> EXCEPTO POR LO QUE SE ESTABLECE ESPEC&Iacute;FICAMENTE EN ESTE ACUERDO, CADA PARTE RECHAZA EXPRESAMENTE TODA GARANT&Iacute;A DE CUALQUIER TIPO, EXPRESA O IMPL&Iacute;CITA, HASTA EL L&Iacute;MITE PERMITIDO
            POR LA LEY, INCLUYENDO PERO NO LIMITADO A LAS GARANT&Iacute;AS IMPL&Iacute;CITAS DE COMERCIALIZACI&Oacute;N, ADECUACI&Oacute;N A UN PROP&Oacute;SITO PARTICULAR Y NO VIOLACI&Oacute;N. NO SE HAR&Aacute;N REPRESENTACIONES O GARANT&Iacute;AS EN
            NUESTRO NOMBRE O EN EL DE LES MILLS INTERNATIONAL SIN NUESTRO CONSENTIMIENTO EXPRESO POR ESCRITO, INCLUYENDO, SIN LIMITACI&Oacute;N, LA CALIDAD DE LOS PROGRAMAS DE LES MILLS, LOS RESULTADOS O LOS RESULTADOS ANTICIPADOS DE LAS RUTINAS DE EJERCICIOS,
            LOS RESULTADOS DE FITNESS O LA P&Eacute;RDIDA DE PESO.</p>

        <p><b>15. Indemnizaci&oacute;n: </b> <b>Usted</b> se compromete a indemnizarnos a <b>Nosotros</b> y/o a Les Mills International por cualquier da&ntilde;o, p&eacute;rdida o coste f&iacute;sico, directo e indirecto/consecuente (incluidos los honorarios
            y costes razonables de los abogados) a <b>Nosotros</b> , a Les Mills International y/o a cualquier otra persona, y nos proteger&aacute; a <b>Nosotros</b> y a Les Mills International de cualquier reclamaci&oacute;n contra <b>Nosotros</b> y
            Les Mills International, en la medida en que sea causada o contribuida por <b>Usted</b> con respecto a este Anexo, el Acuerdo del Instructor, la Propiedad Intelectual o los Programas de Les Mills, incluyendo, sin limitaci&oacute;n, cualquier
            reclamaci&oacute;n por da&ntilde;os f&iacute;sicos causados por su uso de los Programas de Les Mills.</p>

        <p><b>16. Limitaci&oacute;n de la responsabilidad:</b> No seremos responsables de ninguna p&eacute;rdida de beneficios, ni de ninguna p&eacute;rdida indirecta o consecuente relacionada con este Anexo o con el Acuerdo de Instructor y/o Contrato de
            Acreditación de Instructores o con su funci&oacute;n como instructor de Les Mills. Nuestra responsabilidad total hacia <b>Usted</b> por cualquier otra p&eacute;rdida relacionada con el presente Anexo, el Acuerdo de Instructor y/o Contrato
            de Acreditación de Instructores o su funci&oacute;n como instructor de Les Mills se limita a las cantidades pagadas por <b>Usted</b> a <b>Nosotros</b> durante los 6 meses anteriores a la reclamaci&oacute;n.</p>

        <p><b>17. Terminaci&oacute;n: </b> <b>Nosotros</b> o <b>Usted</b> podemos rescindir el presente Anexo por cualquier motivo notific&aacute;ndolo por escrito con 30 d&iacute;as de antelaci&oacute;n, siempre y cuando la rescisi&oacute;n mediante notificaci&oacute;n
            no surta efecto hasta despu&eacute;s del Per&iacute;odo M&iacute;nimo de Compromiso. Tambi&eacute;n podremos rescindir inmediatamente el presente Anexo mediante notificaci&oacute;n escrita si<br> (a) <b>Usted</b> incumple el presente Anexo
            y/o el Acuerdo de Instructor y/o Contrato de Acreditación de Instructores y dicho incumplimiento no es susceptible de ser subsanado o si <b>Usted</b> no rectifica cualquier incumplimiento susceptible de ser subsanado en el plazo de 7 d&iacute;as
            a partir de la notificaci&oacute;n escrita por nuestra parte de dicho incumplimiento; o <br> (b) dejamos de tener derecho a conceder los derechos establecidos en el presente Anexo (sin penalizaci&oacute;n, financiera o de otro tipo).</p>

        <p><b>18. Obligaciones a la terminaci&oacute;n:</b> A la terminaci&oacute;n de este Anexo <b>Usted</b> deber&aacute;: <br> (a) dejar de impartir clases de los Programas Autorizados (total o parcialmente); <br> (b) dejar de referirse a s&iacute; mismo
            como instructor de Les Mills del Programa Autorizado; y <br> (c) dejar de utilizar los Comunicados de Les Mills, la Propiedad Intelectual de Les Mills, los Recursos de Marketing, los Materiales y cualquier otro material proporcionado por <b>Nosotros</b>            en virtud de este Anexo o de otro modo, as&iacute; como cualquier copia de estos elementos, que est&eacute;n en su posesi&oacute;n o control.</p>

        <p><b>19. Impuestos y cargos gubernamentales:</b> Todas las cantidades pagaderas en virtud de este Anexo excluyen todos los impuestos y tasas sobre las ventas, el uso, el valor a&ntilde;adido, las retenciones y otros impuestos.</p>

        <p><b>20. Fuerza Mayor:</b> Ninguna de las partes es responsable de cualquier incumplimiento o retraso en el cumplimiento de una obligaci&oacute;n en este Anexo o en el Acuerdo de Instrucci&oacute;n si se debe a una causa razonablemente m&aacute;s
            all&aacute; del control de esa parte y esa parte ha utilizado sus mejores esfuerzos para cumplir a tiempo a pesar de la causa, siempre y cuando, sin embargo, <b>Usted</b> no ser&aacute; excusado del pago de cualquier suma de dinero que nos
            deba.
        </p>

        <p><b>21. Actualizaci&oacute;n del presente Anexo:</b> Podremos modificar o actualizar el presente Anexo mediante notificaci&oacute;n a <b>Usted</b> . El hecho de que siga actuando como instructor de Les Mills despu&eacute;s de dicha notificaci&oacute;n
            supone su aceptaci&oacute;n de los t&eacute;rminos actualizados. Si no est&aacute; de acuerdo con alg&uacute;n cambio, su &uacute;nico recurso es poner fin a este Anexo o al Acuerdo de Instructor y/o Contrato de Acreditación de Instructores
            (lo que puede hacer mediante una notificaci&oacute;n inmediata por escrito a <b>Nosotros</b> en un plazo de 30 d&iacute;as a partir de la recepci&oacute;n de dicha notificaci&oacute;n del Anexo o Acuerdo actualizado) al mail instructor@bodysystems.cl</p>

        <p><b>22. Contrapartes:</b> Este Anexo puede ser firmado en contrapartes y/o puede ser ejecutado electr&oacute;nicamente.</p>

        <p><b>23. Definiciones:</b> En este Anexo, los siguientes t&eacute;rminos se definir&aacute;n de la siguiente manera y, por lo dem&aacute;s, todos los t&eacute;rminos en may&uacute;sculas tienen el mismo significado que las definiciones establecidas
            de otro modo en este Anexo o en el Acuerdo de Instructor y/o Contrato de Acreditación de Instructores: Directrices de la marca Les Mills se refiere a las directrices de la marca proporcionadas por Les Mills International o por <b>Nosotros</b>            de vez en cuando; <b><i>Les Mills International</i></b> significa Les Mills International Limited; <b><i>Recursos de marketing</i></b> hace referencia a cualquier material de marketing (incluidas las im&aacute;genes,videos y los logotipos
            para uso de marketing) proporcionado en l&iacute;nea o entregado digitalmente a
            <b>Usted</b> por <b>Nosotros</b> de vez en cuando; <b><i>Desarrollo continuo</i></b> significa cualquier formaci&oacute;n y educaci&oacute;n que forme parte de nuestra formaci&oacute;n continua; <b><i>Marcas comerciales</i></b> significa cualquier
            marca registrada o no registrada, marcas de servicio o nombres comerciales de Les Mills International y/o nuestros utilizados en, dentro o en relaci&oacute;n con el Programa Autorizado.</p>

    </div>
</div>
<div mat-dialog-actions align="start">
    <mat-checkbox (change)="cargando = !$event.checked" color="accent">
        He leído y acepto el Contrato de Licencia de Instructor
    </mat-checkbox>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-raised-button (click)="aceptarLicenciaInstructor()" color="primary" [disabled]="cargando">Aceptar Licencia de Instructor</button>
</div>