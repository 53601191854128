<footer class="wrd-block footer-dark">
    <div class="container">

        <!-- <div class="row align-items-top text-left">
            <div class="col-12 col-md-9 text-white">
                <img src="/assets/images/logo-footer.png" height="45" alt="image">
                <p class="mt-5">Av. Bernardo O'higgins Poniente 77, Concepción, Concepción</p>
                <p>+56 9 4027 7480 - +56 9 4027 7481 - +56 2 2980 2163</p>
                <p>Horario atención: 9:00 a 14:00 y 14:30 a 18:30</p>
                <p>© 2020 Body Systems Chile S.A. ALL RIGHTS RESERVED</p>
            </div>
            <div class="col-12 col-md-3 mt-2">
                <div class="row pb-4">
                    <div class="col-2">
                        <a href="https://www.instagram.com/lesmills.cl/" target="_blank"><img style="max-width: 30px" src="/assets/images/footer/INSTA.png" alt="image"></a>
                    </div>
                    <div class="col-2">
                        <a href="https://www.facebook.com/LesMillsChileOficial/" target="_blank"><img style="max-width: 30px;" src="/assets/images/footer/FACE.png" alt="image"></a>
                    </div>
                </div>
                <a class="nav-link pl-0 pr-0" href="/contacto">Contacto</a>
                <a class="nav-link pl-0 pr-0" href="https://www.lesmills.cl/localizador">Localizador de Gimnasios</a>
                <a class="nav-link pl-0 pr-0" href="https://brandcentral.lesmills.com/">Brand Central</a>
                <a class="nav-link pl-0 pr-0" href="https://lesmills.secure.force.com/digital/es_default_login">Portal de Kits Digitales</a>
                <a class="nav-link pl-0 pr-0" href="/instructores/consulta_boleta">Consulta tu Boleta</a>
            </div>
        </div> -->

        <div class="row text-center mx-auto">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <p><img class="logo-footer mt-2" src="/assets/images/logos/LMWhite.svg" height="20" alt="image"></p>
                <p>© 2023 Body Systems Chile S.A. ALL RIGHTS RESERVED</p>
            </div>
            <div class="col-lg-2 my-auto">
                <a href="https://www.instagram.com/lesmillschile/" target="_blank">
                    <!-- <img style="max-width: 30px" src="/assets/images/footer/INSTA.png" alt="image"> -->
                    <i class="fab fa-instagram"></i>
                </a>
                <a class="ml-3" href="https://www.facebook.com/LesMillsChileOficial/" target="_blank">
                    <i class="fab fa-facebook"></i>
                    <!-- <img style="max-width: 30px;" src="/assets/images/footer/FACE.png" alt="image"> -->
                </a>
                <a class="ml-3" href="https://www.youtube.com/c/lesmills" target="_blank">
                    <i class="fab fa-youtube"></i>
                    <!-- <img style="max-width: 30px;" src="/assets/images/footer/FACE.png" alt="image"> -->
                </a>
            </div>
        </div>
    </div>

</footer>