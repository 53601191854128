<div class="logo-repeat-bg">
    <div id="contenido" class="container  mt-5 mb-5 pb-5">
        <div class="row">
            <div class="col-md-3">
                <h1 class="ml-3">Contenidos</h1>
            </div>
            <div class="col-md-9">
                <div class="row ">
                    <div class="col-sm-3">
                        <mat-form-field appearance="fill" class="float-md-right ml-3 mr-3 w-100">
                            <mat-label>
                                Programas
                                <mat-spinner class="progress-spinner-inline" *ngIf="loading" class="float-right" diameter="20"></mat-spinner>
                            </mat-label>
                            <mat-select [disabled]="loading" (selectionChange)="filtrar($event.value, 'programa')">
                                <mat-option value="TODOS">TODOS</mat-option>
                                <mat-option *ngFor="let p of programasFiltros" [value]="p.id">{{p.nombre}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-3">
                        <mat-form-field appearance="fill" class="float-md-right ml-3 mr-3 w-100">
                            <mat-label>
                                Categorias
                                <mat-spinner class="progress-spinner-inline" *ngIf="loading" class="float-right" diameter="20"></mat-spinner>
                            </mat-label>
                            <mat-select [disabled]="loading" (selectionChange)="filtrar($event.value, 'categoria')">
                                <mat-option value="TODOS">TODOS</mat-option>
                                <mat-option *ngFor="let c of categoriasFiltros" [value]="c.id">{{c.nombre.toUpperCase()}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-3">
                        <mat-form-field appearance="fill" class="float-md-right ml-3 mr-3 w-100">
                            <mat-label>
                                Periodos
                                <mat-spinner class="progress-spinner-inline" *ngIf="loading" class="float-right" diameter="20"></mat-spinner>
                            </mat-label>
                            <mat-select [disabled]="loading" (selectionChange)="filtrar($event.value, 'periodo')">
                                <mat-option value="TODOS">TODOS</mat-option>
                                <mat-option value="Q1">Q1</mat-option>
                                <mat-option value="Q2">Q2</mat-option>
                                <mat-option value="Q3">Q3</mat-option>
                                <mat-option value="Q4">Q4</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-3">
                        <mat-form-field appearance="fill" class="float-md-right ml-3 mr-3 w-100">
                            <mat-label>
                                Año
                                <mat-spinner class="progress-spinner-inline" *ngIf="loading" class="float-right" diameter="20"></mat-spinner>
                            </mat-label>
                            <mat-select [disabled]="loading" (selectionChange)="filtrar($event.value, 'ano')">
                                <mat-option value="TODOS">TODOS</mat-option>
                                <mat-option *ngFor="let a of anosFiltros" [value]="a">{{a}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <mat-spinner class="mx-auto mt-5"></mat-spinner>
            </div>
        </div>
        <div *ngIf="!loading && !licenciaActiva" class="row mt-5">
            <div class="col-lg-12">
                <div class="alert alert-danger ">
                    Para ver los contenidos necesitas tener una <a class="text-primary" routerLink="/panel/licencias">Licencia de Instructor</a> Activa.
                </div>
            </div>
        </div>
        <div *ngIf="!loading && licenciaActiva " class="row mt-5 ">
            <div class="col-lg-12 ">
                <div class="row ">
                    <div class="col-lg-12 ">
                        <div class="row ">
                            <ng-container *ngFor="let c of contenidosFiltrados ">
                                <div *ngIf="c['mostrar']" class="col-lg-4 col-md-6 pb-4 ">
                                    <!-- <div class="card ">
                                    <img class="card-img-top " [src]="s3Url+c.img_previsualizacion " alt="Card image ">
                                    <div class="card-body ">
                                        <h4 class="card-title ">
                                            {{ c.nombre }}
                                        </h4>
                                    </div>
                                </div> -->
                                    <img class="img-fluid " [src]="s3Url+c.img_previsualizacion " [alt]="c.nombre ">
                                    <h5 class="mt-2 m-2 ">
                                        {{ c.nombre }}
                                        <a (click)="verContenido(c.contenido) ">
                                            <i class="fa fa-download float-right " aria-hidden="true "></i>
                                        </a>
                                    </h5>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>