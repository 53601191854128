import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FechasCertPipe } from './fechas-cert.pipe';
import { EntrenadoresPipe } from './entrenadores.pipe';
import { AgregarDiasFechaPipe } from './agregar-dias-fecha.pipe';



@NgModule({
  declarations: [
    FechasCertPipe,
    EntrenadoresPipe,
    AgregarDiasFechaPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FechasCertPipe,
    EntrenadoresPipe,
    AgregarDiasFechaPipe
  ]
})
export class PipesModule { }
