import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from '../../../services/token-storage.service';
import { UserService } from '../../../services/user.service';
import { RegionesService } from '../../../services/regiones.service';
import { RutValidator } from 'ng9-rut';
import { ValidadoresService } from '../../../services/validadores.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  loginForm: FormGroup;
  Logeado = false;
  errorRegistro = false;
  MensajeError = '';
  cargando = false;
  
  loadingR = true;
  loadingC = true;
  loadingCS = false;
  registroForm: FormGroup;
  region: any[];
  comuna: any[];
  errorCheckbox = false;
  terminos = false;

  constructor(
    private tokenStorage: TokenStorageService,
    private user: UserService, 
    private route: Router,
    private routerA: ActivatedRoute,
    private fb: FormBuilder,
    private regiones: RegionesService,
    private rutValidator: RutValidator,
    private validadores: ValidadoresService,
    public dialog: MatDialog,
  ) {

    this.regiones.getRegiones().subscribe(
      data => {
        this.region = data.data;
        this.loadingR = false;
      }
    );
  }

  ngOnInit(): void {
    if (this.tokenStorage.checkToken()) {
        this.Logeado = true;
        this.route.navigate(['/inicio']); 
    }

    this.registroForm = this.fb.group({
      email   : ['', [Validators.required, Validators.email], this.validadores.correoExiste()],
      nombres   : ['', [Validators.required]],
      apellidos   : ['', [Validators.required]],
      region   : ['', [Validators.required]],
      comuna   : ['', [Validators.required]],
      direccion   : ['', [Validators.required]],
      terminos   : ['', [Validators.required]],
      rut   : ['', [Validators.required, this.rutValidator], this.validadores.rutExiste()],
      telefono   : ['', [Validators.required]],
      password: ['', [Validators.required]],
      c_password: ['', [Validators.required]],
  });
  }

  
  onSubmit(): void {
    if(!this.terminos){
      this.errorCheckbox = true;
    }else{
      this.errorCheckbox = false;
    }

    if(this.registroForm.valid){
      if(this.registroForm.get('password').value !== this.registroForm.get('c_password').value){
        this.errorRegistro = true;
        this.MensajeError = 'Las Contraseñas no coinciden';
      }else{
        this.cargando = true;
        const datos = {
          email: this.registroForm.get('email').value,
          nombres: this.registroForm.get('nombres').value,
          apellidos: this.registroForm.get('apellidos').value,
          comuna: this.registroForm.get('comuna').value,
          region: this.registroForm.get('region').value,
          direccion: this.registroForm.get('direccion').value,
          rut: this.registroForm.get('rut').value,
          telefono: this.registroForm.get('telefono').value,
          password: this.registroForm.get('password').value,
        }

        this.user.registro(datos).subscribe(
          data => {
            if(data.message.user){
              this.user.almacenarUsuario(data.message.user.user);
              this.tokenStorage.saveToken(data.message.user.access_token);
              this.tokenStorage.saveUser(data.message.user);
              this.user.getUsuario();

              this.route.navigate(['/panel/datos-personales']); 
            }
          }
        );
      }
    }
  }

  obtenerComunas(value): void{
    this.registroForm.controls['comuna'].setValue('', {onlySelf: true});
    this.loadingCS = true;
    this.loadingC = true;
    this.regiones.getComunasByRegion(value).subscribe(
      data => {
        this.comuna = data.data;
        this.loadingC = false;
        this.loadingCS = false;
      }
    );
  }


  terminosPress(event): void{
    this.terminos = event.checked;
  }

  abrirPolitica(): void{
    this.dialog.open(TyCRegistroPrincipalDialog, {
      width: '800px',
    });
  }
}

@Component({
  selector: 'terminos-condiciones',  
  templateUrl: '../../terminos_condiciones/registro.html',

})
export class TyCRegistroPrincipalDialog {

  constructor(
    public dialogRef: MatDialogRef<TyCRegistroPrincipalDialog>,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  

}

