<div class="bg-foto">
    <div id="login" class="container main">
        <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div class="card my-2 mx-2">
                    <div class="card-body">
                        <div class="logo text-center">
                            <img src="/assets/images/logos/LMBlack.svg" height="35" alt="" class="mt-1">
                        </div>
                        <h3 class="card-title text-center mt-4">INICIO DE SESIÓN</h3>
                        <hr>
                        <div *ngIf="ErrorLogeo" class="text-center pb-2">
                            <mat-error>
                                {{ MensajeError }}
                            </mat-error>
                        </div>
                        <div *ngIf="correo && idCertificacion" class="text-center pb-2 w-75 mx-auto">
                            Ingresa tu contraseña para continuar la inscripción a la certificación
                        </div>
                        <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <mat-form-field class="w-100" appearance="outline" color="accent">
                                <mat-label>Correo</mat-label>
                                <input matInput formControlName="email">
                                <mat-icon matSuffix>mail</mat-icon>
                                <mat-error *ngIf="loginForm.get('email').hasError('required')">
                                    El Correo es Obligatorio
                                </mat-error>
                                <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                                            loginForm.get('email').hasError('email')">
                                    Por favor ingresa un correo válido
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="w-100" appearance="outline" color="accent">
                                <mat-label>Clave</mat-label>
                                <input matInput type="password" formControlName="password">
                                <mat-icon matSuffix>password</mat-icon>
                                <mat-error>
                                    La Contraseña es Obligatoria
                                </mat-error>
                            </mat-form-field>
                            <button *ngIf="!cargando" class="btn btn-lg btn-primary btn-block " type="submit">CONTINUAR</button>
                            <button *ngIf="cargando" class="btn btn-lg btn-primary btn-block" type="submit" disabled>
                                CARGANDO 
                                <mat-icon>
                                    <mat-spinner class="mt-1 ml-3" color="primary" diameter="25"></mat-spinner>
                                </mat-icon>
                            </button>
                        </form>
                        <hr>
                        <div class="text-left mt-3 text-black">
                            <a [routerLink]="'/registro'">¿Nuevo Instructor?</a>
                        </div>
                        <div class="text-left mt-3 text-black">
                            <a [routerLink]="'/olvido_clave'">Recuperar Clave</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>