import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidadoresService } from '../../../../services/validadores.service';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-cambio-clave',
  templateUrl: './cambio-clave.component.html',
  styleUrls: ['./cambio-clave.component.scss']
})
export class CambioClaveComponent implements OnInit {

  cambioClaveForm: FormGroup;

  usuario: any[];
  cargandoCC = false;
  resultadoCC = false;
  tipoAlertCC: string;
  msjAlertCC: string;

  constructor(
      private fb: FormBuilder,
      private validadores: ValidadoresService,
      private user: UserService, 
    ) { 
      this.usuario = this.user.usuario;
    }

    ngOnInit(): void {
      this.cambioClaveForm = this.fb.group({
        password_old: ['', [Validators.required]],
        password: ['', [Validators.required]],
        c_password: ['', [Validators.required]],
    });
    }

  onSubmitCC(): void{
    if(this.cambioClaveForm.valid ){
      this.cargandoCC = true;
      this.resultadoCC = false;
      const datos = {
        password_old: this.cambioClaveForm.get('password_old').value,
        password: this.cambioClaveForm.get('password').value,
        c_password: this.cambioClaveForm.get('c_password').value,
      }

      this.user.actualizarClaveUsuario(datos).subscribe(
        data => {
          if(data.data){
            this.tipoAlertCC = "alert-success"
            this.msjAlertCC = data.message;
            
            //ACTUALIZARLO LOCALMENTE
            this.user.get_user().subscribe( data => {
              this.user.almacenarUsuario(data);
            });
          }else{
            this.tipoAlertCC = "alert-danger"
            this.msjAlertCC = data.message;
          }
          this.resultadoCC = true;
          this.cargandoCC = false;
        }
      );
    }
  }
}
