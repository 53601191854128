import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { GimnasioService } from '../../services/gimnasios.service';


@Component({
  selector: 'my-app',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  clients = Array();
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};


  constructor(
    private gim: GimnasioService
  ) {
  }

  ngOnInit(): void {

    this.gim.getGimnasiosLicenciados().subscribe(
      data => {
        this.clients = data.data;
        console.log();

        this.dtOptions = {
          order: [[0, "asc"]],
          autoWidth: true,
          columns: [
            { title: 'Code', data: 'id' },
            { title: 'Name', data: 'nombre' },
          ]
        };
    
        this.rerender();
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  addRow() {
    // this.clients.push(new Test("ac", "ac"));
    this.rerender();
  }

  removeRow() {
    this.clients = this.clients.slice(1);
    this.rerender();
  }
}
