<section class="main-home">
    <img class="img-fluid img-home w-100" src="/assets/images/programs/v2-bg-sb.png">
</section>

<section style="color: #333333;" class="main wrd-block p-0 main-gallery">
    <div class="container-sm mb-4 ancho-70">
        <div class="row">
            <div class="col-lg-12 mt-4 text-center">
                <h1 class="display-4 mt-5 title-programs title-color-sb">¿QUÉ ES SH’BAM?</h1>
            </div>

            <div class="col-12">
                <p class="mt-2 desc-programs text-justify">SH’BAM es un programa divertido que combina movimientos de baile sencillos y muy entretenidos, perfecto para ponerte en forma y dejar salir al artista que llevas dentro en total y completa libertad.</p>
            </div>

            <div class="col-12 text-center mt-3 mb-3 programs-bt-sb">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">APRENDE NUEVOS</h3>
                        <h1 class="p-0 m-0 title-color-sb"><strong>PASOS DE BAILE</strong></h1>
                    </div>
                    <div class="col-lg-4 programs-br">
                        <h3 class="p-0 m-0 mt-2">ENCUENTRA TU</h3>
                        <h1 class="p-0 m-0 title-color-sb"><strong>ESTRELLA INTERIOR</strong></h1>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="p-0 m-0 mt-2">ÚNETE A LA</h3>
                        <h1 class="p-0 m-0 title-color-sb"><strong>FIESTA</strong></h1>
                    </div>
                </div>
            </div>

            <div class="col-12 text-center">
                <h2 class="mt-5">¿COMO EMPEZAR CON SH’BAM?</h2>
            </div>
            <div class="col-12 mb-5">
                <p class="mt-2 desc-programs text-justify"></p>
                <ul class="desc-programs">
                    <li>Utiliza ropa cómoda y suelta.</li>
                    <li>El primer día realiza cuatro o cinco tracks y progresa hasta realizar una clase completa.</li>
                </ul>
            </div>

            <div class="col-12 mt-3 mb-3 programs-bt-sb">
                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>BENEFICIOS</b></h3>
                        <ul class="desc-programs">
                            <li>Mejora la coordinación y la expresión corporal</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>MATERIALES</b></h3>
                        <p class="desc-programs text-center">
                            Sin material
                        </p>
                    </div>
                    <div class="col-lg-3 programs-br">
                        <h3 class="text-center"><b>CALORIAS</b></h3>
                        <p class="desc-programs text-center">
                            Aprox. <b>506</b>
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-center"><b>DURACIÓN</b></h3>
                        <p class="desc-programs text-center">
                            45 o 30 minutos
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100" style="position: absolute;z-index: 1;">
        <img class="d-block mx-auto" style="max-width: 10%" src="/assets/images/programs/arrow-down.png">
    </div>
    <div class="embed-responsive embed-responsive-16by9 hoverable">
        <video class="embed-responsive-item" controls>
       <source src="/assets/videos/video-sb.mp4" type="video/mp4">
     </video>
    </div>

</section>