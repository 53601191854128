<style>
    a {
        cursor: pointer;
    }
    
    p {
        font-size: 20px;
    }
    
    b {
        font-size: 22px;
    }
    
    i {
        font-size: 16px;
    }
    
    .margenes {
        padding: 15px;
    }
</style>
<div mat-dialog-content>
    <div class="w-100 text-right">
        <a (click)="onNoClick()"><i class="fa fa-times"></i></a>
    </div>
    <p class="margenes">
        Para acceder a una certificación debes tener una <a class="text-primary" (click)="redirigirLic()">Licencia de Instructor</a> Activa o estar asociado a un <a class="text-primary" (click)="redirigirGim()">Gimnasio Licenciado.</a><br><br> Si eres
        un Nuevo Instructor, regístrate para obtener un <b>50% de descuento</b> en tu primera certificación.<br>
        <i>*Descuento exclusivo para quienes  se inscriben en una certificación por primera vez</i>
    </p>
</div>
<div mat-dialog-actions align="center">
    <button class="btn btn-primary" (click)="redirigir()">Continuar</button>
</div>